<template>
    <nav :class="antlerClass()">
        <slot />
    </nav>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppNavBar",
    setup() {
        const { antlerClass } = antlerComponent("nav-bar");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.nav-bar
    display: flex
    flex: 1

.nav-bar__list
    display: flex
    flex: 1

    list-style: none

.nav-bar__item
    display: flex

    &:not(:last-child)
        margin-right: $spacing-small

        +mq-tablet-portrait
            margin-right: $spacing-regular

.nav-bar__link
    display: flex
    align-items: center

.nav-main
    font:
        weight: $strong-weight
        family: $primary-font-family

    +mq-lte-tablet-portrait
        @include font-size(14px)

.nav-main__link
    position: relative

    &::before
        position: absolute
        top: 50%
        left: 0

        display: block
        width: 100%
        height: 1px

        content: ''
        border-bottom:
            width: 1px
            style: dashed
            color: transparent
        transform: translateY(.8em)

.nav-main__link:hover,
.nav-main__link--active
    color: $color-secondary

.nav-main__link--active::before
    border-color: currentColor
</style>
