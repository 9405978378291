<template>
    <label :class="`${antlerClass()} label`">
        <slot />
    </label>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppFormFieldLabel",
    setup() {
        const { antlerClass } = antlerComponent("form-field__label");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
label.form-field__label
    position: absolute
    top: 0
    right: auto
    left: $spacing-small
    z-index: $zindex-content

    margin-right: 8px //this is needed to stop overflowing the ::after bg

    line-height: 48px
    color: $color-blue-light

    pointer-events: none

    transition: all 120ms ease-in

    &::after
        position: absolute
        top: 0
        left: -6px
        z-index: -1

        display: block
        width: calc(100% + 12px)
        height: 100%

        background: $color-input-label
        content: ''

        transform: scaleX(0)
        transition: transform 120ms ease-in-out
</style>
