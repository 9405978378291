import { camelCase, cloneDeep } from "lodash";
import Vue from "vue";

import { consoleError } from "@/console";
import { mapActions } from "vuex";

export default {
    props: {
        formInstance: {
            type: Object,
            default: undefined,
            validator: value => value instanceof Vue
        }
    },
    data() {
        return {
            loading: 0,
            formValues: {},
            emptyFormErrors: {},
            formErrors: {}
        };
    },
    created() {
        this.formErrors = cloneDeep(this.emptyFormErrors);
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        setFormValues(formValues) {
            formValues = cloneDeep(formValues);
            Object.keys(formValues)
                .filter(key => key in this.formValues)
                .forEach(key => {
                    this.formValues[key] = formValues[key];
                });
        },
        getMutation() {
            throw new Error("getMutation is not implemented.");
        },
        formSuccess() {},
        formFailed(errors) {
            this.showMessage({
                message: this.$gettext(
                    "Niet alle velden zijn (correct) ingevuld."
                ),
                type: "error"
            });

            errors = errors || [];
            for (const err of errors) {
                let formError = this.formErrors[camelCase(err.field)];
                if (!formError) {
                    continue;
                }
                if (Object.prototype.hasOwnProperty.call(formError, "forms")) {
                    if (err.__typename === "ErrorTypeFormset") {
                        if (formError.forms.indexOf(err.formIndex) === -1) {
                            formError.forms[err.formIndex] = {};
                        }
                        for (const inlineErr of err.errors) {
                            formError.forms[err.formIndex][
                                camelCase(inlineErr.field)
                            ] = inlineErr.messages;
                        }
                    } else {
                        formError.nonFormErrors = err.messages;
                    }
                    // trigger reactivity by reassigning the object to the same key
                    this.formErrors[camelCase(err.field)] = cloneDeep(
                        formError
                    );
                } else {
                    this.formErrors[camelCase(err.field)] = err.messages;
                }
            }

            setTimeout(() => {
                this.formFocusInvalidInput();
            }, 0);
        },
        formError() {
            this.showMessage({
                message: this.$gettext(
                    "Er is een fout opgetreden bij het versturen, probeer het a.u.b. opnieuw."
                ),
                type: "error"
            });
        },
        formIsValid() {
            return (
                this.formInstance ||
                this.$refs.form ||
                this.$refs.modal.$refs.form
            ).validate();
        },
        formSubmit(raiseExceptions = false) {
            return new Promise((resolve, reject) => {
                if (this.formIsValid()) {
                    this.loading++;
                    this.formErrors = cloneDeep(this.emptyFormErrors);

                    this.$apollo
                        .mutate(this.getMutation())
                        .then(data => {
                            let errors = [];
                            for (const k in data.data) {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        data.data,
                                        k
                                    )
                                ) {
                                    errors = errors.concat(
                                        data.data[k].errors || []
                                    );
                                }
                            }

                            if (errors.length === 0) {
                                const el = document.querySelector(":focus");
                                if (el) el.blur();

                                this.formSuccess(data);
                                resolve(data);
                            } else {
                                this.formFailed(errors, data);
                                reject();
                            }
                        })
                        .catch(err => {
                            this.formError(err);
                            reject();
                        })
                        .finally(() => {
                            this.loading--;
                        });
                } else {
                    this.formFailed();
                    reject();
                }
            }).catch(e => {
                if (e) consoleError(e, this);
                if (raiseExceptions === true) throw e;
            });
        },
        formFocusInvalidInput() {
            const formInstance =
                this.formInstance ||
                this.$refs.form ||
                this.$refs.modal.$refs.form;
            if (formInstance) {
                const firstInvalidInput = formInstance.inputs.find(
                    input => input.hasError
                );
                firstInvalidInput && firstInvalidInput.focus();
            }
        }
    }
};
