<template>
    <AppLayout :as="as">
        <slot />
    </AppLayout>
</template>
<script>
import AppLayout from "@/components/AppLayout.vue";
import { mapGetters } from "vuex";

export default {
    name: "TheRoot",
    components: { AppLayout },
    props: {
        as: {
            type: String,
            default: "div",
            validator: value => {
                return ["div", "main"].includes(value);
            }
        }
    },
    computed: {
        ...mapGetters("modals", ["modalsOpen"])
    },
    watch: {
        modalsOpen(value) {
            if (value > 0) {
                document.documentElement.classList.add("stop-scrolling");
            } else {
                document.documentElement.classList.remove("stop-scrolling");
            }
        }
    }
};
</script>

<style lang="sass">
.nowrap
    white-space: nowrap
</style>
