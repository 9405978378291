export default {
    namespaced: true,
    state: {
        show: false,
        insightId: null,
        insightCopyId: null,
        insightType: null,
        groupId: null,
        createdInsights: [],
        updatedInsights: [],
        expiredInsights: [],
        deletedInsights: []
    },
    getters: {
        show: state => state.show,
        insightId: state => state.insightId,
        insightCopyId: state => state.insightCopyId,
        insightType: state => state.insightType,
        groupId: state => state.groupId,
        createdInsights: state => state.createdInsights,
        updatedInsights: state => state.updatedInsights,
        expiredInsights: state => state.expiredInsights,
        deletedInsights: state => state.deletedInsights
    },
    mutations: {
        openModal(state, payload) {
            payload = payload || {};
            state.show = true;
            state.insightId = payload.insightId || null;
            state.insightCopyId = payload.insightCopyId || null;
            state.insightType = payload.insightType || null;
            state.groupId = payload.groupId || null;
        },
        closeModal(state) {
            state.show = false;
            state.insightId = null;
            state.insightCopyId = null;
            state.insightType = null;
            state.groupId = null;
        },
        createdInsight(state, id) {
            state.createdInsights.push(id);
        },
        updatedInsight(state, id) {
            state.updatedInsights.push(id);
        },
        expiredInsight(state, id) {
            state.expiredInsights.push(id);
        },
        deletedInsight(state, id) {
            state.deletedInsights.push(id);
        }
    }
};
