<template>
    <AppFormField
        :class="[
            'form-field--file',
            'form-field--with-icon',
            {
                'form-field--error': hasMessages,
                'form-field--file-hidden-input':
                    hideInputOnValue && lazyValue && lazyValue.url
            }
        ]"
    >
        <div
            :class="[
                'input',
                'form-field__input',
                {
                    'input--disabled': isDisabled,
                    'form-field__input--active': lazyValue && lazyValue.name
                }
            ]"
            tabindex="1"
        >
            <input
                ref="input"
                :accept="accept"
                class="input input--file"
                :disabled="disabled"
                :readonly="readonly"
                :required="required"
                type="file"
                @blur="onBlur"
                @change="onInput"
                @focus="onFocus"
            />

            <!-- prettier-ignore -->
            <span v-if="lazyValue && lazyValue.name" class="label-value t-truncate">{{ lazyValue.name }}</span>
        </div>

        <!-- prettier-ignore -->
        <AppFormFieldLabel v-if="label" data-testid="label">
            {{ label }} <span v-if="required">*</span>
        </AppFormFieldLabel>

        <div class="form-field__icon">
            <AppIcon name="attachment" />
        </div>

        <div class="form-field-attachment">
            <a
                v-if="lazyValue && lazyValue.filename"
                download
                :href="lazyValue.url"
                target="_blank"
            >
                <AppIcon class="u-margin-right-tiny" name="download" />
                <!-- prettier-ignore -->
                <span
                    :class="[
                        't-decoration-underline',
                        { 't-decoration-line-through': lazyValue.clear }
                    ]"
                >{{ lazyValue.filename }}</span>
            </a>
        </div>

        <AppInputCheckbox
            v-if="clearable && lazyValue && lazyValue.url"
            v-model="lazyValue.clear"
            :label="$gettext('Bestand verwijderen')"
            :disabled="isDisabled"
        />

        <div v-if="!hideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormField>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppInputCheckbox from "@/components/AppInputCheckbox";
import AppInputText from "@/components/AppInputText";
import AppMessageList from "@/components/AppMessageList";

export default {
    name: "AppInputFile",
    components: {
        AppIcon,
        AppInputCheckbox,
        AppMessageList
    },
    extends: AppInputText,
    props: {
        accept: {
            type: String,
            default: ""
        },
        clearable: Boolean,
        hideInputOnValue: Boolean,
        value: {
            type: [Object, File],
            default: undefined
        }
    },
    data() {
        return {
            originalValue: null
        };
    },
    computed: {
        internalRules() {
            return (
                (this.rules.length && this.rules) ||
                (this.required
                    ? this.label
                        ? [
                              v =>
                                  (v && !!(v.url || v.name)) ||
                                  this.$gettextInterpolate(
                                      "%{label} is verplicht",
                                      { label: this.label }
                                  )
                          ]
                        : [
                              v =>
                                  (v && !!(v.url || v.name)) ||
                                  this.$gettext("Dit veld is verplicht")
                          ]
                    : [])
            );
        },
        internalValue: {
            get() {
                return this.lazyValue;
            },
            set(val) {
                if (!val && this.originalValue) {
                    val = this.originalValue;
                }
                this.lazyValue = val;
                this.$emit(this.$_modelEvent, val);
            }
        }
    },
    watch: {
        value(val) {
            if (val && val.url) {
                this.originalValue = { ...val, clear: false };
            }
            this.lazyValue = val;
        }
    },
    methods: {
        onInput(event) {
            this.internalValue = event.target.files[0];
            this.badInput =
                event.target.validity && event.target.validity.badInput;
        },
        reset() {
            this.isResetting = true;
            this.internalValue = undefined;

            const input = this.$refs.input;
            input.type = "text";
            input.type = "file";
        }
    }
};
</script>
