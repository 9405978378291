<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppColumn",
    setup() {
        const { antlerClass } = antlerComponent("column");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.column
    position: relative

    flex: 1
    min-height: 1px
    max-width: 100%
    overflow-x: clip
    padding:
        right: $spacing-regular
        left: $spacing-regular

.column-2
    flex-basis: calc(100% / 2)
    max-width: calc(100% / 2)

.column-3
    flex-basis: calc(100% / 3)
    max-width: calc(100% / 3)

.column-4
    flex-basis: calc(100% / 4)
    max-width: calc(100% / 4)

.column-5
    flex-basis: calc(100% / 5)
    max-width: calc(100% / 5)

.column-6
    flex-basis: calc(100% / 6)
    max-width: calc(100% / 6)

/* lte desktop */

.column-full--lte-desktop
    +mq-lte-desktop
        flex: 0 0 100%
        max-width: 100%

        & + &
            margin-top: $spacing-regular

.column-4--lte-desktop-large
    +mq-lte-desktop-large
        flex-basis: 25%
        max-width: 25%

/* lte tablet landscape */

.column-3--lte-tablet-landscape
    +mq-lte-tablet-landscape
        flex-basis: calc(100% / 3)
        max-width: calc(100% / 3)

.column-4--lte-tablet-landscape
    +mq-lte-tablet-landscape
        flex-basis: 25%
        max-width: 25%

/* lte tablet portrait */

.column-4--lte-tablet-portrait
    +mq-lte-tablet-portrait
        flex-basis: 25%
        max-width: 25%

.column-full--lte-tablet-portrait
    +mq-lte-tablet-portrait
        flex: 0 0 100%
        max-width: 100%

        & + &
            margin-top: $spacing-regular

.column-full--lte-tablet-landscape
    +mq-lte-tablet-landscape
        flex: 0 0 100%
        max-width: 100%

        & + &
            margin-top: $spacing-regular

/* lte tablet */

.column-2--lte-tablet
    +mq-lte-tablet
        flex-basis: 50%
        max-width: 50%

.column-4--lte-tablet
    +mq-lte-tablet
        flex-basis: 25%
        max-width: 25%

.column-full--lte-tablet
    +mq-lte-tablet
        flex: 0 0 100%

        & + &
            margin-top: $spacing-regular

.column-full--lte-mobile
    +mq-lte-mobile
        flex: 0 0 100%
        max-width: 100%
</style>
