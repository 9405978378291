<script>
import Validatable from "@/mixins/Validatable";

export default {
    name: "AppInput",
    mixins: [Validatable],
    props: {
        hideDetails: {
            type: Boolean,
            default: undefined
        },
        hint: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        persistentHint: Boolean,
        required: Boolean,
        value: {
            type: [String, Number],
            default: ""
        }
    },
    data(vm) {
        return {
            initialValue: null,
            lazyValue: vm.value
        };
    },
    computed: {
        hasHint() {
            return (
                !this.hasMessages &&
                this.hint &&
                (this.persistentHint || this.isFocused)
            );
        },
        internalValue: {
            get() {
                return this.lazyValue;
            },
            set(val) {
                this.lazyValue = val;
                this.$emit(this.$_modelEvent, val);
            }
        },
        internalRules() {
            return (
                (this.rules.length && this.rules) ||
                (this.required
                    ? this.label
                        ? [
                              v =>
                                  (Array.isArray(v) ? v.length > 0 : !!v) ||
                                  this.$gettextInterpolate(
                                      "%{label} is verplicht",
                                      { label: this.label }
                                  )
                          ]
                        : [
                              v =>
                                  (Array.isArray(v) ? v.length > 0 : !!v) ||
                                  this.$gettext("Dit veld is verplicht")
                          ]
                    : [])
            );
        },
        isDirty() {
            return Array.isArray(this.lazyValue)
                ? this.lazyValue.length > 0
                : !!this.lazyValue;
        },
        isDisabled() {
            return Boolean(this.disabled || this.readonly);
        },
        messages() {
            return this.hasHint ? [this.hint] : this.validations;
        }
    },
    watch: {
        value(val) {
            this.lazyValue = val;
        }
    },
    beforeCreate() {
        // eslint-disable-next-line camelcase
        this.$_modelEvent =
            (this.$options.model && this.$options.model.event) || "input";
    }
};
</script>

<style lang="sass">
.input
    position: relative

    display: flex
    align-items: center
    width: 100%
    height: 48px
    padding: $spacing-xsmall $spacing-small

    @include font-size($input-font-size, $base-line-height)
    color: $color-base-font
    text-rendering: optimizeLegibility

    background-color: #fff
    border:
        width: 2px
        color: $color-input-border
        style: solid
        radius: $input-border-radius

    transition: $global-transition-fast
    background-clip: padding-box
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    outline: 0

    &:focus,
    &:focus ~ .input-control
        border-color: $color-primary

    @include placeholder
        color: $color-input-placeholder

/* Control Indicators */
/*---------------------------------------------------------------------------*/

.input-control
    position: absolute
    top: 0
    left: 0

    display: inline-block
    width: 28px
    height: 28px

    text-align: center
    vertical-align: top

    background-color: #fff
    border:
        width: 2px
        color: $color-input-border
        style: solid
        radius: $global-border-radius

    user-select: none
    transition: $global-transition-fast

    .form-field--checkbox:hover &,
    .form-field--radio:hover &
        border-color: $color-primary

    .input:checked ~ &
        background-color: $color-primary
        border:
            color: $color-primary

    .input:disabled ~ &,
    .form-field--checkbox:hover .input:disabled ~ &,
    .form-field--radio:hover .input:disabled ~ &
        background-color: $color-blue-lighter
        border-color: $color-blue-light

        cursor: default

    &::after
        position: absolute
        top: 50%
        left: 50%

        content: ""
        display: block

/* Special Inputs */
/*---------------------------------------------------------------------------*/

.vdp-datepicker input
    @extend .input

/* Form Input modifiers */
/*---------------------------------------------------------------------------*/

.input--hidden
    position: absolute
    // Put the input behind the label so it doesn't overlay text
    z-index: -$zindex-content

    width: .1px // needed to be tabbable
    height: .1px // needed to be tabbable
    border: 0
    padding: 0

    @include font-size(12px)

    opacity: 0
    overflow: hidden
    clip-path: inset(50%)

.input--file
    position: absolute
    top: 0
    left: 0
    z-index: $zindex-content

    width: 100%
    height: 100%

    opacity: 0

/* Input States */
/*---------------------------------------------------------------------------*/

.input:disabled,
.input[readonly],
.input--disabled,
.input--readonly
    color: $color-input-disabled

    border-color: $color-border-disabled
    background-color: $color-input-disabled-background

    & + .label,
    & + .label + .form__icon
        color: $color-blue-light

    & + .label::after
        background: $color-input-disabled-label

    // remove background-gradient when parent has background-color
    .content--inline-form & + .label::after
        background: $color-input-disabled-background
</style>
