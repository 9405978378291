<template>
    <AppModal
        ref="modal"
        v-model="show"
        persistent
        has-form
        @modalSubmit="formSubmit"
    >
        <div slot="title">
            <span v-if="!!insightId" v-translate>Bewerken</span>
            <span v-else v-translate>Toevoegen</span>
        </div>

        <AppFormGroup variant="inline">
            <AppInputSelect
                v-if="!isDiagnosis && !isAbsenceEdit"
                v-model="formValues.type"
                :disabled="loading > 0"
                data-testid="field-type"
                :error-messages="formErrors.type"
                :label="$gettext('Type inzicht')"
                :options="typeChoices"
                options-label="text"
                options-track-by="value"
                required
                :rules="typeRules"
            />

            <AppInputDate
                v-model="formValues.date"
                :disabled="loading > 0 || !formValues.type"
                :disabled-dates="!isAbsence ? { from: new Date() } : undefined"
                :error-messages="formErrors.date"
                :label="$gettext('Datum')"
                required
                :rules="dateRules"
            />
        </AppFormGroup>
        <AppFormGroup spacing="none">
            <AppInputSelect
                v-model="formValues.students"
                data-testid="field-students"
                :disabled="
                    loading > 0 || !!insightId || isDiagnosis || isAbsenceEdit
                "
                :error-messages="formErrors.students"
                icon="user"
                :label="$gettext('Leerling')"
                :options="allStudents"
                options-label="fullName"
                options-track-by="id"
                :multiple="!insightId"
                required
            >
                <!-- prettier-ignore -->
                <span slot="noOptions" v-translate>Geen leerling(en) gevonden.</span>
                <!-- prettier-ignore -->
                <span slot="noResult" v-translate>Geen leerling(en) gevonden.</span>
            </AppInputSelect>

            <AppInputSelect
                v-if="isAbsence"
                v-model="absenceType"
                :disabled="loading > 0"
                data-testid="field-type"
                :label="$gettext('Type absentie')"
                :options="absenceGroupTypeChoices"
                required
            />

            <AppInputSelect
                v-if="isAbsence"
                v-model="formValues.absenceType"
                :disabled="
                    loading > 0 || !absenceType || absenceType === 'Ziek'
                "
                data-testid="field-type"
                :error-messages="formErrors.absenceType"
                :label="$gettext('Absentiereden')"
                :options="absenceTypeChoices"
                options-label="text"
                options-track-by="value"
                required
                :rules="absenceTypeRules"
            />

            <AppInputSelect
                v-if="isAbsence"
                v-model="formValues.absenceDuration"
                :disabled="loading > 0"
                data-testid="field-type"
                :error-messages="formErrors.absenceDuration"
                :label="$gettext('Duratie')"
                :options="absenceDurationChoices"
                options-label="text"
                options-track-by="value"
                required
                :rules="absenceDurationRules"
            />

            <!-- prettier-ignore -->
            <div
                v-show="
                    formValues.type &&
                        (formValues.type.value === 'appointment' ||
                            formValues.type.value === 'contact_moment' ||
                            isDiagnosis)
                "
            >
                <AppInputText
                    v-model="formValues.title"
                    data-testid="field-title"
                    :disabled="loading > 0"
                    :error-messages="formErrors.title"
                    :label="
                        isDiagnosis
                            ? $gettext('Diagnose')
                            : $gettext('Met wie?')
                    "
                    :required="isDiagnosis"
                />
            </div>
            <div v-show="isEducationPlan">
                <AppInputSelect
                    v-model="formValues.educationPlanSubject"
                    data-testid="field-subject"
                    :disabled="loading > 0"
                    :error-messages="
                        allSubSubjects.length === 0
                            ? formErrors.educationPlanSubject
                            : []
                    "
                    :label="$gettext('Vak')"
                    :options="allSubjects"
                    options-label="name"
                    options-track-by="id"
                    :required="isEducationPlan"
                    @input="formValues.educationPlanSubSubject = null"
                >
                    <!-- prettier-ignore -->
                    <span slot="noOptions" v-translate>Geen vak(ken) gevonden.</span>
                    <!-- prettier-ignore -->
                    <span slot="noResult" v-translate>Geen vak(ken) gevonden.</span>
                </AppInputSelect>
                <AppInputSelect
                    v-model="formValues.educationPlanSubSubject"
                    data-testid="field-subsubject"
                    :disabled="loading > 0 || allSubSubjects.length === 0"
                    :error-messages="
                        allSubSubjects.length > 0
                            ? formErrors.educationPlanSubject
                            : []
                    "
                    :label="$gettext('Subvak')"
                    :options="allSubSubjects"
                    options-label="name"
                    options-track-by="id"
                >
                    <!-- prettier-ignore -->
                    <span slot="noOptions" v-translate>Geen subvak(ken) gevonden.</span>
                    <!-- prettier-ignore -->
                    <span slot="noResult" v-translate>Geen subvak(ken) gevonden.</span>
                </AppInputSelect>
                <AppInputTextarea
                    v-model="formValues.goal"
                    data-testid="field-goal"
                    :disabled="loading > 0"
                    :error-messages="formErrors.goal"
                    hint="Specifiek, meetbaar, acceptabel, realistisch, tijdgebonden (Over 6 weken beheerst leerling A. de tafel van 5 en 7.)"
                    :label="$gettext('Doel')"
                    persistent-hint
                    :required="isEducationPlan"
                />
                <AppInputTextarea
                    v-model="formValues.method"
                    data-testid="field-method"
                    :disabled="loading > 0"
                    :error-messages="formErrors.method"
                    hint="Denk aan: Wie (leerkracht, ouders, onderwijsassistent) , wat ( aanpak), waar ( in de klas, gang, buiten, speelzaal), wanneer (hoelang, welk moment, hoe vaak), hoe (materialen, methode)"
                    :label="$gettext('Werkwijze')"
                    persistent-hint
                    :required="isEducationPlan"
                />
            </div>
            <div v-show="!isEducationPlan">
                <AppInputTextarea
                    v-model="formValues.insight"
                    :counter="160"
                    data-testid="field-insight"
                    :disabled="loading > 0"
                    :error-messages="formErrors.insight"
                    :label="insightLabel"
                    :required="!isDiagnosis && !isEducationPlan && !isAbsence"
                />
            </div>

            <AppRow v-show="!isAbsence" variant="gutters-tiny no-wrap">
                <AppColumn class="column-full--lte-mobile">
                    <AppInputCheckbox
                        v-model="formValues.important"
                        :disabled="loading > 0"
                        :error-messages="formErrors.important"
                        :label="$gettext('Belangrijk')"
                    />
                </AppColumn>
                <AppColumn class="column-full--lte-mobile">
                    <AppInputFile
                        v-model="formValues.attachment"
                        :disabled="loading > 0"
                        :error-messages="formErrors.attachment"
                        :label="$gettext('Bijlage')"
                    />
                </AppColumn>
            </AppRow>

            <hr v-show="!isAbsence" />

            <AppInputRadioGroup
                v-show="!isAbsence"
                v-model="formValues.encouraging"
                class="form-group--horizontal form-group--bordered"
                :disabled="loading > 0"
                :error-messages="formErrors.encouraging"
            >
                <AppInputRadio :label="$gettext('Neutraal')" :value="null" />
                <AppInputRadio
                    :label="$gettext('Belemmerend')"
                    :value="false"
                />
                <AppInputRadio :label="$gettext('Bevorderend')" :value="true" />
            </AppInputRadioGroup>

            <hr v-show="!isAbsence" />

            <AppInputRadioGroup
                v-show="!isAbsence"
                v-model="insightDetails"
                class="form-group--horizontal form-group--bordered"
                data-testid="field-details"
                :disabled="loading > 0"
                :error-messages="formErrors.insightDetails"
            >
                <AppInputRadio :label="$gettext('Algemeen')" value="" />
                <AppInputRadio
                    v-if="!isEducationPlan"
                    :label="$gettext('Vak')"
                    value="subjects"
                />
                <AppInputRadio :label="$gettext('Dag')" value="date" />
            </AppInputRadioGroup>

            <div
                v-if="
                    insightDetails === 'subjects' &&
                        !isEducationPlan &&
                        !isAbsence
                "
            >
                <AppInputSelect
                    v-model="formValues.subjects"
                    data-testid="field-subjects"
                    :disabled="loading > 0"
                    :error-messages="formErrors.subjects"
                    :label="$gettext('Vakken')"
                    multiple
                    :options="allSubjects"
                    options-label="name"
                    options-track-by="id"
                    :required="insightDetails === 'subjects'"
                >
                    <!-- prettier-ignore -->
                    <span slot="noOptions" v-translate>Geen vak(ken) gevonden.</span>
                    <!-- prettier-ignore -->
                    <span slot="noResult" v-translate>Geen vak(ken) gevonden.</span>
                </AppInputSelect>
            </div>
            <template v-else-if="insightDetails === 'date'">
                <AppInputRadioGroup
                    v-model="insightDetailsDate"
                    class="form-group--horizontal form-group--bordered"
                    data-testid="field-details-date"
                    :disabled="loading > 0"
                >
                    <AppInputRadio
                        :label="$gettext('Eenmalig')"
                        value="specific"
                    />
                    <AppInputRadio
                        :label="$gettext('Wekelijks')"
                        value="weekdays"
                    />
                </AppInputRadioGroup>
            </template>

            <template v-if="insightDetails === 'date'">
                <template v-if="insightDetailsDate === 'specific'">
                    <AppInputDate
                        v-model="formValues.dateSpecific"
                        data-testid="field-date-specific"
                        :disabled="loading > 0"
                        :error-messages="formErrors.dateSpecific"
                        :label="$gettext('Specifieke datum')"
                        :required="
                            insightDetails === 'date' &&
                                insightDetailsDate === 'specific'
                        "
                    />
                </template>

                <AppInputCheckboxGroup
                    v-else-if="insightDetailsDate === 'weekdays'"
                    class="form-group--horizontal form-group--bordered"
                >
                    <AppInputCheckbox
                        v-for="weekday in weekdayChoices"
                        :key="weekday.value"
                        v-model="formValues.dateWeekdays"
                        :disabled="loading > 0"
                        :error-messages="formErrors.dateWeekdays"
                        :label="weekday.text"
                        :rules="weekdayRules"
                        :value="weekday.value"
                    />
                </AppInputCheckboxGroup>
            </template>

            <hr
                v-if="
                    formValues.evaluations.length ||
                        (insightId && insight.expired)
                "
            />

            <AppInputTextarea
                v-for="evaluation in formValues.evaluations"
                :key="evaluation.id"
                v-model="evaluation.evaluation"
                :disabled="loading > 0"
                :error-messages="formErrors.evaluations"
                :label="$gettext('Tussentijdse evaluatie')"
                required
            />

            <AppInputTextarea
                v-if="insightId && insight.expired"
                v-model="formValues.evaluation"
                data-testid="field-evaluation"
                :disabled="loading > 0"
                :error-messages="formErrors.evaluation"
                :label="$gettext('Evaluatie')"
                required
            />
        </AppFormGroup>

        <template #footer>
            <AppButtonGroup variant="between">
                <AppLink
                    v-translate
                    as="button"
                    type="button"
                    variant="bold font-primary"
                    @click.native="show = false"
                >
                    Annuleren
                </AppLink>

                <div
                    v-if="insight && insight.id && insight.expired"
                    v-translate="{
                        expired: $moment(insight.expired).format('LL')
                    }"
                    class="d-flex--with-center"
                >
                    Vervallen op %{expired}
                </div>

                <AppButton
                    variant="secondary huge"
                    :disabled="!onLine"
                    :loading="loading > 0"
                    type="submit"
                >
                    <translate>Opslaan</translate>
                </AppButton>
            </AppButtonGroup>
        </template>
    </AppModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { omit, pick } from "lodash";

import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppColumn from "@/components/AppColumn.vue";
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppInputCheckbox from "@/components/AppInputCheckbox";
import AppInputCheckboxGroup from "@/components/AppInputCheckboxGroup";
import AppInputDate from "@/components/AppInputDate";
import AppInputFile from "@/components/AppInputFile";
import AppInputRadio from "@/components/AppInputRadio";
import AppInputRadioGroup from "@/components/AppInputRadioGroup";
import AppInputSelect from "@/components/AppInputSelect";
import AppInputText from "@/components/AppInputText";
import AppInputTextarea from "@/components/AppInputTextarea";
import AppLink from "@/components/AppLink.vue";
import AppModal from "@/components/AppModal";
import AppRow from "@/components/AppRow.vue";
import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";

const ABSENCE_TYPES = {
    ZI: "Ziek",
    GL: "Geoorloofd afwezig",
    TB: "Geoorloofd afwezig",
    AR: "Geoorloofd afwezig",
    TA: "Geoorloofd afwezig",
    VE: "Geoorloofd afwezig",
    GO: "Geoorloofd afwezig",
    LV: "Ongeoorloofd afwezig",
    TL: "Ongeoorloofd afwezig",
    ON: "Ongeoorloofd afwezig",
    OO: "Ongeoorloofd afwezig"
};

const insightFields = `
    id
    type
    typeDisplay
    absenceType
    absenceTypeDisplay
    absenceDuration
    absenceDurationDisplay
    title
    insight
    goal
    method
    date
    insightId
    important
    attachment {
        filename
        url
    }
    encouraging
    subjects {
        edges {
            node {
                id
                name
            }
        }
    }
    educationPlanSubject {
        id
        name
        parent {
            id
            name
        }
    }
    dateSpecific
    dateWeekdays
    student {
        id
        fullName
    }
    created
    createdTeacher {
        id
        fullName
    }
    modified
    modifiedTeacher {
        id
        fullName
    }
    expired
    evaluation
    evaluations {
        edges {
            node {
                id
                evaluation
                created
                createdTeacher {
                    id
                    fullName
                }
            }
        }
    }
`;

export default {
    name: "TheInsightModal",
    components: {
        AppButtonGroup,
        AppLink,
        AppColumn,
        AppRow,
        AppFormGroup,
        AppButton,
        AppInputCheckboxGroup,
        AppInputRadio,
        AppInputRadioGroup,
        AppInputFile,
        AppInputCheckbox,
        AppInputTextarea,
        AppInputText,
        AppInputDate,
        AppInputSelect,
        AppModal
    },
    mixins: [FormSave],
    data() {
        return {
            allStudents: [],
            allSubjects: [],
            allSubSubjects: [],
            insight: {},
            absenceType: "",
            insightDetails: "",
            insightDetailsDate: "specific",
            dateWeekdaysError: false,
            formValues: {
                type: "",
                absenceType: "",
                absenceDuration: "",
                title: "",
                insight: "",
                goal: "",
                method: "",
                date: new Date(),
                important: "",
                attachment: null,
                encouraging: null,
                subjects: [],
                dateSpecific: "",
                dateWeekdays: [],
                students: [],
                educationPlanSubject: null,
                educationPlanSubSubject: null,
                procedure: "",
                evaluation: "",
                evaluations: []
            },
            emptyFormErrors: {
                type: [],
                absenceType: [],
                absenceDuration: [],
                title: [],
                insight: [],
                goal: [],
                method: [],
                subject: [],
                date: [],
                important: [],
                attachment: [],
                encouraging: [],
                subjects: [],
                dateSpecific: [],
                dateWeekdays: [],
                students: [],
                educationPlanSubject: [],
                evaluation: [],
                evaluations: []
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["permissions"]),
        ...mapGetters("insightmodal", {
            insightId: "insightId",
            insightCopyId: "insightCopyId",
            insightType: "insightType",
            groupId: "groupId",
            stateShow: "show"
        }),
        ...mapGetters("online", ["onLine"]),
        show: {
            get() {
                return this.stateShow;
            },
            set(value) {
                if (value === false) {
                    this.closeModal(value);
                    Object.assign(
                        this.$data,
                        omit(this.$options.data.apply(this), "loading")
                    );
                }
            }
        },
        numericId() {
            return this.insightId ? atob(this.insightId).split(":")[1] : "";
        },
        isEducationPlan() {
            return this.formValues.type
                ? this.formValues.type.value === "education_plan"
                : false;
        },
        isDiagnosis() {
            return this.formValues.type
                ? this.formValues.type.value === "diagnosis"
                : false;
        },
        isAbsence() {
            return this.formValues.type
                ? this.formValues.type.value === "absence"
                : false;
        },
        isAbsenceEdit() {
            return this.isAbsence && !!this.insightId;
        },
        dateRules() {
            return !this.isAbsence
                ? [
                      v =>
                          !v ||
                          new Date(v) <= new Date() ||
                          this.$gettext("Datum kan niet in de toekomst zijn.")
                  ]
                : [];
        },
        typeChoices() {
            const choices = [
                { value: "appointment", text: this.$gettext("Afspraak") },
                { value: "concern", text: this.$gettext("Aandachtspunt") },
                {
                    value: "contact_moment",
                    text: this.$gettext("Contactmoment")
                },
                { value: "event", text: this.$gettext("Gebeurtenis") },
                {
                    value: "education_plan",
                    text: this.$gettext("Ontwikkeling")
                }
            ];
            if (!this.insightId) {
                choices.push({
                    value: "absence",
                    text: this.$gettext("Absentie")
                });
            }
            return choices;
        },
        typeRules() {
            return [
                v =>
                    this.typeChoices.map(x => x.value).includes(v && v.value) ||
                    this.$gettext("Type inzicht is verplicht")
            ];
        },
        absenceGroupTypeChoices() {
            return [...new Set(Object.values(ABSENCE_TYPES))];
        },
        absenceTypeChoices() {
            const absenceGroupKeys = Object.entries(ABSENCE_TYPES).reduce(
                (acc, [key, val]) => {
                    if (val === this.absenceType) {
                        acc.push(key);
                    }
                    return acc;
                },
                []
            );
            return [
                { value: "ZI", text: this.$gettext("Ziek (ZI)") },
                { value: "GL", text: this.$gettext("Te laat (GL)") },
                {
                    value: "TB",
                    text: this.$gettext("Therapie / begeleiding (TB)")
                },
                { value: "AR", text: this.$gettext("Arts (AR)") },
                { value: "TA", text: this.$gettext("Tandarts (TA)") },
                { value: "VE", text: this.$gettext("Verlof (VE)") },
                { value: "GO", text: this.$gettext("Overig (GO)") },
                { value: "LV", text: this.$gettext("Luxe verzuim (LV)") },
                { value: "TL", text: this.$gettext("Te laat (TL)") },
                { value: "ON", text: this.$gettext("Onbekend (ON)") },
                { value: "OO", text: this.$gettext("Overig (OO)") }
            ].filter(x => absenceGroupKeys.includes(x.value));
        },
        absenceTypeRules() {
            return this.isAbsence
                ? [
                      v =>
                          this.absenceTypeChoices
                              .map(x => x.value)
                              .includes(v && v.value) ||
                          this.$gettext("Absentiereden is verplicht")
                  ]
                : [];
        },
        absenceDurationChoices() {
            return [
                { value: "0:05:00", text: this.$gettext("5 minuten") },
                { value: "0:10:00", text: this.$gettext("10 minuten") },
                { value: "0:15:00", text: this.$gettext("15 minuten") },
                { value: "0:30:00", text: this.$gettext("30 minuten") },
                { value: "1:00:00", text: this.$gettext("1 uur") },
                { value: "2:30:00", text: this.$gettext("Halve dag") },
                { value: "5:00:00", text: this.$gettext("Hele dag") }
            ];
        },
        absenceDurationRules() {
            return this.isAbsence
                ? [
                      v =>
                          this.absenceDurationChoices
                              .map(x => x.value)
                              .includes(v && v.value) ||
                          this.$gettext("Duratie is verplicht")
                  ]
                : [];
        },
        weekdayChoices() {
            return [
                { value: 0, text: this.$gettext("Ma") },
                { value: 1, text: this.$gettext("Di") },
                { value: 2, text: this.$gettext("Wo") },
                { value: 3, text: this.$gettext("Do") },
                { value: 4, text: this.$gettext("Vr") }
            ];
        },
        weekdayRules() {
            if (
                this.insightDetails === "date" &&
                this.insightDetailsDate === "weekdays"
            ) {
                return [
                    v => {
                        return (
                            v.length > 0 ||
                            this.$gettext("Minimaal één weekdag is verplicht")
                        );
                    }
                ];
            } else {
                return [];
            }
        },
        insightLabel() {
            if (this.isDiagnosis) {
                return this.$gettext("Diagnose");
            } else if (this.isAbsence) {
                return this.$gettext("Optionele opmerking");
            }

            return this.$gettext("Inzicht");
        }
    },
    apollo: {
        allStudents: {
            query: gql`
                query($groupId: ID) {
                    allStudents(groupId: $groupId) {
                        edges {
                            node {
                                id
                                fullName
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId
                };
            },
            update(data) {
                if (data.allStudents) {
                    const allStudents = data.allStudents.edges.map(x => x.node);
                    if (!this.insightId && this.$route.params.id) {
                        const id = atob(this.$route.params.id).split(":");
                        if (id.length && id[0] === "Student") {
                            this.formValues.students = allStudents.filter(
                                x => x.id === this.$route.params.id
                            );
                        }
                    }
                    return allStudents;
                } else {
                    return this.allStudents;
                }
            },
            skip() {
                return !this.show;
            }
        },
        allSubjects: {
            query: gql`
                query {
                    allSubjects(isSubSubject: false) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-first",
            update(data) {
                if (data.allSubjects) {
                    return data.allSubjects.edges.map(x => x.node);
                } else {
                    return this.allSubjects;
                }
            },
            skip() {
                return !this.show;
            }
        },
        allSubSubjects: {
            query: gql`
                query($parentId: ID!) {
                    allSubjects(parentId: $parentId) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-first",
            variables() {
                return {
                    parentId: this.formValues.educationPlanSubject.id
                };
            },
            update(data) {
                if (data.allSubjects) {
                    return data.allSubjects.edges.map(x => x.node);
                } else {
                    return this.allSubSubjects;
                }
            },
            skip() {
                return !this.formValues.educationPlanSubject;
            }
        },
        insight: {
            query: gql`
                query($id: ID!) {
                    insight(id: $id) {
                        ${insightFields}
                    }
                }
            `,
            variables() {
                return {
                    id: this.insightId || this.insightCopyId
                };
            },
            update(data) {
                if (data.insight) {
                    let insight;
                    if (this.insightCopyId) {
                        const keepInCopy = [
                            "date",
                            "dateSpecific",
                            "dateWeekdays",
                            "educationPlanSubject",
                            "encouraging",
                            "goal",
                            "important",
                            "insight",
                            "method",
                            "subjects",
                            "title",
                            "type",
                            "typeDisplay",
                            "absenceType",
                            "absenceTypeDisplay",
                            "absenceDuration",
                            "absenceDurationDisplay"
                        ];
                        insight = pick(data.insight, keepInCopy);
                    } else {
                        insight = { ...data.insight };
                    }

                    const insightType = insight.type.toLowerCase();

                    insight.type = this.typeChoices.find(
                        type => type.value === insightType
                    ) || { value: insightType, text: insight.typeDisplay };

                    if (insight.absenceType) {
                        insight.absenceType = this.absenceTypeChoices.find(
                            type => type.value === insight.absenceType
                        ) || {
                            value: insight.absenceType,
                            text: insight.absenceTypeDisplay
                        };
                    }

                    if (insight.absenceDuration) {
                        // e.g. change GraphQL enum A_0_05_00 into 0:05:00
                        const insightAbsenceDuration = insight.absenceDuration
                            .replace("A_", "")
                            .replaceAll("_", ":");
                        insight.absenceDuration = this.absenceDurationChoices.find(
                            type => type.value === insightAbsenceDuration
                        ) || {
                            value: insightAbsenceDuration,
                            text: insight.absenceDurationDisplay
                        };
                    }

                    // if educationPlanSubject parent is set, it is a SubSubject
                    if (
                        insight.educationPlanSubject &&
                        insight.educationPlanSubject.parent
                    ) {
                        insight.educationPlanSubSubject =
                            insight.educationPlanSubject;

                        // overwrite educationPlanSubject with parent
                        insight.educationPlanSubject =
                            insight.educationPlanSubject.parent;
                    }

                    insight.subjects = insight.subjects.edges.map(x => x.node);

                    if (this.insightId && !this.insightCopyId) {
                        // transform to student array, when not a copy
                        insight.students = [insight.student];
                        insight.evaluations = insight.evaluations.edges.map(
                            x => {
                                return {
                                    id: atob(x.node.id).split(":")[1],
                                    evaluation: x.node.evaluation
                                };
                            }
                        );
                    }

                    this.setFormValues(insight);

                    this.absenceType =
                        ABSENCE_TYPES[insight.absenceType.value] || "";

                    this.insightDetails =
                        insight.subjects.length > 0
                            ? "subjects"
                            : insight.dateSpecific ||
                              insight.dateWeekdays.length > 0
                            ? "date"
                            : "";
                    this.insightDetailsDate =
                        insight.dateWeekdays.length > 0
                            ? "weekdays"
                            : "specific";
                    return insight;
                } else {
                    this.show = false;
                    return this.insight;
                }
            },
            skip() {
                return !this.insightId && !this.insightCopyId;
            }
        }
    },
    watch: {
        insightType(value) {
            this.formValues.type = this.typeChoices.find(
                type => type.value === value
            ) || { value: value };
        },
        "formValues.type"() {
            if (this.isEducationPlan && this.insightDetails === "subjects") {
                this.insightDetails = "";
            }
            // reset formErrors
            this.$refs.modal.$refs.form.resetValidation();
        },
        absenceType() {
            if (this.absenceTypeChoices.length === 1) {
                this.formValues.absenceType = this.absenceTypeChoices[0];
            } else {
                this.formValues.absenceType =
                    this.absenceTypeChoices.find(
                        type => type.value === this.formValues.absenceType.value
                    ) || "";
            }
            this.$refs.modal.$refs.form.resetValidation();
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        ...mapMutations("insightmodal", [
            "closeModal",
            "createdInsight",
            "updatedInsight",
            "deletedInsight"
        ]),
        getMutation() {
            const input = {
                id: this.numericId,
                type: this.formValues.type.value,
                absenceType: "",
                title: "",
                insight: this.formValues.insight,
                date: this.$moment(this.formValues.date).format("YYYY-MM-DD"),
                important: this.formValues.important,
                attachment: { file: this.formValues.attachment },
                encouraging: this.formValues.encouraging,
                subjects: [],
                educationPlanSubject: null,
                dateSpecific: null,
                dateWeekdays: ""
            };

            // set evaluation for edits only
            if (this.insightId) {
                input.evaluation = this.formValues.evaluation;
                input.evaluations = this.formValues.evaluations;
                input.evaluationsTotalForms = this.formValues.evaluations.length;
                input.evaluationsInitialForms = this.insight.evaluations.length;
            }

            // set student input
            if (!this.insightId) {
                input.students = this.formValues.students.map(
                    s => atob(s.id).split(":")[1]
                );
            }

            if (
                input.type === "appointment" ||
                input.type === "contact_moment" ||
                input.type === "diagnosis"
            ) {
                input.title = this.formValues.title;
            }

            if (input.type === "education_plan") {
                input.goal = this.formValues.goal;
                input.method = this.formValues.method;
                input.insight = "";
                input.educationPlanSubject = atob(
                    (
                        this.formValues.educationPlanSubSubject ||
                        this.formValues.educationPlanSubject
                    ).id
                ).split(":")[1];
            }

            if (input.type === "absence") {
                input.absenceType = this.formValues.absenceType.value;
                input.absenceDuration = this.formValues.absenceDuration.value;
            }

            switch (this.insightDetails) {
                case "subjects":
                    input.subjects = this.formValues.subjects.map(
                        x => atob(x.id).split(":")[1]
                    );
                    break;
                case "date":
                    switch (this.insightDetailsDate) {
                        case "specific":
                            input.dateSpecific = this.$moment(
                                this.formValues.dateSpecific
                            ).format("YYYY-MM-DD");
                            break;
                        case "weekdays":
                            input.dateWeekdays = this.formValues.dateWeekdays.join();
                            break;
                    }
                    break;
            }

            // set mutation by type Create or Update

            let mutation;
            if (this.insightId) {
                mutation = gql`
                    mutation($input: InsightUpdateMutationInput!) {
                    updateInsight (input: $input) {
                            insight {
                                ${insightFields}
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors{
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `;
            } else {
                mutation = gql`
                    mutation($input: InsightCreateMutationInput!) {
                       createInsight (input: $input) {
                            insights {
                                ${insightFields}
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `;
            }

            return {
                mutation: mutation,
                variables: {
                    input: input
                }
            };
        },
        formSuccess(data) {
            // update store with insight Ids
            if (data.data.createInsight) {
                data.data.createInsight.insights.forEach(x =>
                    this.createdInsight(x.id)
                );
            } else if (data.data.updateInsight) {
                this.updatedInsight(data.data.updateInsight.insight.id);
            }

            this.show = false;
            this.showMessage({
                message: this.$gettext("Het inzicht is succesvol opgeslagen."),
                type: "success"
            });
        }
    }
};
</script>
