import RegistrableInject from "@/mixins/RegistrableInject";

import { consoleError } from "@/console";
import { isEqual } from "lodash";

export default {
    mixins: [RegistrableInject("form")],
    props: {
        disabled: Boolean,
        error: Boolean,
        errorCount: {
            type: [Number, String],
            default: 1
        },
        errorMessages: {
            type: [String, Array],
            default: () => []
        },
        readonly: Boolean,
        rules: {
            type: Array,
            default: () => []
        },
        validateOnBlur: Boolean
    },
    data: () => ({
        errorBucket: [],
        hasFocused: false,
        hasInput: false,
        isFocused: false,
        isResetting: false,
        valid: false
    }),
    computed: {
        hasError() {
            return (
                this.internalErrorMessages.length > 0 ||
                this.errorBucket.length > 0 ||
                this.error
            );
        },
        externalError() {
            return this.internalErrorMessages.length > 0 || this.error;
        },
        hasMessages() {
            return this.validations.length > 0;
        },
        internalErrorMessages() {
            return this.errorMessages || "";
        },
        internalRules() {
            return this.rules;
        },
        shouldValidate() {
            return (
                this.externalError ||
                (!this.isResetting &&
                    (this.validateOnBlur
                        ? this.hasFocused && !this.isFocused
                        : this.hasInput || this.hasFocused))
            );
        },
        validations() {
            return this.validationTarget.slice(0, this.errorCount);
        },
        validationState() {
            if (this.hasError && this.shouldValidate) return "error";
            return null;
        },
        validationTarget() {
            const target =
                this.internalErrorMessages.length > 0 ? this.errorMessages : [];

            // String
            if (!Array.isArray(target)) {
                return [target];
                // Array with items
            } else if (target.length > 0) {
                return target;
                // Currently has validation
            } else if (this.shouldValidate) {
                return this.errorBucket;
            } else {
                return [];
            }
        }
    },
    watch: {
        internalRules: {
            handler(newVal, oldVal) {
                if (isEqual(newVal, oldVal)) return;
                this.validate();
            },
            deep: true
        },
        internalValue() {
            // If it's the first time we're setting input,
            // mark it with hasInput
            this.hasInput = true;
            this.validateOnBlur || this.$nextTick(this.validate);
        },
        isFocused(val) {
            // Should not check validation
            // if disabled or readonly
            if (!val && !this.disabled && !this.readonly) {
                this.hasFocused = true;
                this.validateOnBlur && this.validate();
            }
        },
        isResetting() {
            setTimeout(() => {
                this.hasInput = false;
                this.hasFocused = false;
                this.isResetting = false;
            }, 0);
        },
        hasError(val) {
            if (this.shouldValidate) {
                this.$emit("update:error", val);
            }
        }
    },
    beforeMount() {
        this.validate();
    },
    methods: {
        reset() {
            this.isResetting = true;
            this.internalValue = Array.isArray(this.internalValue)
                ? []
                : undefined;
        },
        resetValidation() {
            this.isResetting = true;
        },
        validate(force = false, value = this.internalValue) {
            const errorBucket = [];

            if (force) this.hasInput = this.hasFocused = true;

            for (const rule of this.internalRules) {
                const valid = typeof rule === "function" ? rule(value) : rule;

                if (valid === false || typeof valid === "string") {
                    errorBucket.push(valid);
                } else if (valid !== true) {
                    consoleError(
                        `Rules should return a string or boolean, received '${typeof valid}' instead`,
                        this
                    );
                }
            }

            this.errorBucket = errorBucket;
            this.valid = errorBucket.length === 0;

            return this.valid;
        }
    }
};
