var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppFormField',{class:['form-field--textarea', { 'form-field--error': _vm.hasMessages }]},[_c('textarea',{ref:"input",class:[
            'input',
            'input--textarea',
            'form-field__input',
            { 'form-field__input--active': _vm.isDirty },
            _vm.size ? ("input--textarea-" + _vm.size) : '',
            _vm.inputClass
        ],attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"blur":_vm.onBlur,"focus":_vm.onFocus,"keydown":_vm.onKeydown}}),_vm._v(" "),(_vm.label)?_c('AppFormFieldLabel',{attrs:{"data-testid":"label"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.counter || _vm.submit)?_c('div',{staticClass:"form-field-options",on:{"click":function($event){return _vm.$refs.input.focus()}}},[(_vm.counter)?_c('div',{class:[
                'form-field-counter',
                {
                    'form-field-counter--error':
                        _vm.internalValue.length > _vm.counter
                }
            ]},[_vm._v(" "+_vm._s(_vm.internalValue.length)+" / "+_vm._s(_vm.counter)+" ")]):_vm._e(),(_vm.submit)?_c('AppButton',{staticClass:"u-margin-left-small",attrs:{"variant":"primary small","disabled":_vm.isDisabled,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1):_vm._e(),(!_vm.hideDetails)?_c('div',{staticClass:"form-field-messages"},[(_vm.hasMessages || _vm.hasHint)?_c('AppMessageList',{attrs:{"messages":_vm.messages,"type":_vm.validationState}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }