<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppArticle",
    props: {
        size: {
            type: String,
            default: "",
            validator: value => {
                return ["", "small"].includes(value);
            }
        },
        color: {
            type: String,
            default: "",
            validator: value => {
                return ["", "blue-shade-dark"].includes(value);
            }
        },
        spacing: {
            type: String,
            default: "",
            validator: value => {
                return ["", "small"].includes(value);
            }
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("article", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.article
    white-space: pre-line

    a:link,
    a:visited
        color: $color-secondary

        &:hover
            text-decoration: underline

    p:not(:last-of-type)
        margin-bottom: $spacing-regular

//sizes
.article--size-small
    @include font-size(14px)

// spacing
.article--spacing-small
    p:not(:last-of-type)
        margin-bottom: $spacing-xsmall

// colors
.article--color-blue-shade-dark
    color: $color-blue-shade-dark
</style>
