<template>
    <div
        :class="[
            'layout',
            'layout-root',
            'layout--vertical',
            { 'layout--offline': !onLine },
            $route.name ? ' layout--' + $route.name : '',
            $route.meta.layoutClass
        ]"
    >
        <div v-if="!onLine" v-translate class="offline">
            Geen internet verbinding.
        </div>

        <TheLoadingBar v-if="globalLoading > 0" />

        <router-view name="header" />
        <router-view name="subHeader" />

        <router-view />

        <TheInsightModal />
        <TheMessage />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import TheInsightModal from "@/components/TheInsightModal";
import TheLoadingBar from "@/components/TheLoadingBar";
import TheMessage from "@/components/TheMessage";

export default {
    components: {
        TheLoadingBar,
        TheInsightModal,
        TheMessage
    },
    computed: {
        ...mapGetters("auth", ["userLoggedIn"]),
        ...mapGetters("loading", { globalLoading: "loading" }),
        ...mapGetters("online", ["onLine"])
    },
    watch: {
        userLoggedIn(value) {
            if (!value && this.$route.meta.authRequired !== false) {
                this.$router.push({
                    name: "login",
                    query: { redirect: this.$route.fullPath }
                });
            }
        }
    },
    created() {
        window.addEventListener("online", this.updateOnline);
        window.addEventListener("offline", this.updateOnline);
    },
    destroyed() {
        window.removeEventListener("online", this.updateOnline);
        window.removeEventListener("offline", this.updateOnline);
    },
    methods: mapMutations("online", ["updateOnline"])
};
</script>

<style lang="sass">
@import "assets/styles/main"

.offline
    position: fixed
    top: 0
    left: 0
    z-index: $zindex-content

    width: 100%
    padding: $spacing-small $spacing-regular

    color: #fff
    text-align: center
    font-weight: $strong-weight

    background-color: $color-primary
</style>
