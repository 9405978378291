<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppDropdownContent",
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("dropdown-content", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.dropdown-content--nav
    position: relative
    margin-top: 6px
    padding: $spacing-medium

    background-color: #fff

    box-shadow: 0 0 9px rgba(#000, .14)
</style>
