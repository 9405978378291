<template>
    <button :class="antlerClass()">
        <slot />
    </button>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("dropdown-toggle", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.dropdown-toggle
    padding-right: $spacing-regular
    white-space: nowrap

    &::after
        position: absolute
        top: 50%
        right: $spacing-xsmall

        display: block
        height: 10px
        width: 10px
        margin-top: -$spacing-xsmall

        border:
            width: 3px
            style: solid
            color: currentColor
            radius: 2px
            right: 0
            top: 0
        content: ''

        pointer-events: none
        transform: rotate(-45deg)
        transform-origin: center

.dropdown-toggle--link
    position: relative

    display: flex
    align-items: center

    font-weight: bold
    color: $color-blue

    border: 0
    background-color: transparent

    &::before
        position: absolute
        top: 50%
        left: 0

        display: block
        width: 100%
        height: 1px

        content: ''
        border-bottom:
            width: 1px
            style: dashed
            color: transparent
        transform: translateY(.8em)
</style>
