export default {
    namespaced: true,
    state: {
        modalsOpen: 0
    },
    getters: {
        modalsOpen: state => state.modalsOpen
    },
    mutations: {
        openModal(state, countModifier) {
            state.modalsOpen += countModifier;
        }
    }
};
