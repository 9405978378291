<template>
    <form class="form" novalidate @submit="$emit('submit', $event)">
        <slot />
    </form>
</template>

<script>
import RegistrableProvider from "@/mixins/RegistrableProvider";

export default {
    name: "AppForm",
    mixins: [RegistrableProvider("form")],
    inheritAttrs: false,
    props: {
        value: Boolean,
        lazyValidation: Boolean
    },
    data() {
        return {
            inputs: [],
            watchers: [],
            errorBag: {}
        };
    },
    watch: {
        errorBag: {
            handler() {
                const errors = Object.values(this.errorBag).includes(true);
                this.$emit("input", !errors);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        watchInput(input) {
            const watcher = input => {
                return input.$watch(
                    "hasError",
                    val => {
                        this.$set(this.errorBag, input._uid, val);
                    },
                    { immediate: true }
                );
            };

            const watchers = {
                _uid: input._uid,
                valid: undefined,
                shouldValidate: undefined
            };

            if (this.lazyValidation) {
                // Only start watching inputs if we need to
                watchers.shouldValidate = input.$watch(
                    "shouldValidate",
                    val => {
                        if (!val) return;

                        // Only watch if we're not already doing it
                        if (
                            Object.prototype.hasOwnProperty.call(
                                this.errorBag,
                                input._uid
                            )
                        )
                            return;

                        watchers.valid = watcher(input);
                    }
                );
            } else {
                watchers.valid = watcher(input);
            }

            return watchers;
        },
        validate() {
            const errors = this.inputs.filter(input => !input.validate(true))
                .length;
            return !errors;
        },
        reset() {
            for (const input of this.inputs) {
                input.reset();
            }
            if (this.lazyValidation) {
                // Account for timeout in validatable
                setTimeout(() => {
                    this.errorBag = {};
                }, 0);
            }
        },
        resetValidation() {
            for (const input of this.inputs) {
                input.resetValidation();
            }
            if (this.lazyValidation) {
                // Account for timeout in validatable
                setTimeout(() => {
                    this.errorBag = {};
                }, 0);
            }
        },
        register(input) {
            const unwatch = this.watchInput(input);
            this.inputs.push(input);
            this.watchers.push(unwatch);
        },
        unregister(input) {
            const found = this.inputs.find(i => i._uid === input._uid);

            if (!found) return;

            const unwatch = this.watchers.find(i => i._uid === found._uid);
            unwatch.valid && unwatch.valid();
            unwatch.shouldValidate && unwatch.shouldValidate();

            this.watchers = this.watchers.filter(i => i._uid !== found._uid);
            this.inputs = this.inputs.filter(i => i._uid !== found._uid);
            this.$delete(this.errorBag, found._uid);
        }
    }
};
</script>

<style lang="sass">
label,
.label
    @include font-size(14px)

    transition: $global-transition

    +mq-desktop
        @include font-size(16px)

.form
    width: 100%

.form--horizontal
    +mq-tablet
        .form-field
            display: flex

        .form-field__label
            flex: 0 0 140px
            margin:
                top: 4px
                right: $spacing-regular

        .form-field__input
            flex: 1

.form--labels-inline
    .form-group
        display: flex
        align-items: center

    .label
        flex: 0 1 140px

        +mq-tablet-portrait
            flex: 0 1 200px

.form-footer
    display: flex
    align-items: flex-start

.form-footer--centered
    align-items: center

.form-footer--reverse
    flex-direction: row-reverse

/* Form Field active + Hover State */

.form-field__input
    &:focus ~ .form-field__label,
    &--active ~ .form-field__label,
    &.multiselect--active ~ .form-field__label
        top: -4px

        @include font-size(12px)
        line-height: 1

        &::after
            transform: scaleX(1)

    &:focus + .form-field__label,
    &.multiselect--active + .form-field__label
        color: $color-blue

    // change form__icon color on active
    &:focus ~ .form__icon
        color: $color-input-focus-border

/* Change field-colors when parent has a background */

.content--inline-form
    .form-field__input
        &:focus ~ .form-field__label::after,
        &--active ~ .form-field__label::after,
        &.multiselect--active ~ .form-field__label::after
            background: $color-input-disabled-label-reverse

        &.input--disabled ~ .form-field__label::after
            background: $color-blue-lighter

.form-field__icon
    position: absolute
    top: 12px
    right: 12px
    z-index: $zindex-content

    color: $color-blue-light

    pointer-events: none

.form-field__icon--button
    top: 6px
    right: 6px

.form-field__icon--clickable
    pointer-events: auto

.form-field-messages
    margin:
        top: 2px
        bottom: 5px
        left: $spacing-small
    min-height: 20px

    @include font-size(12px)

.form-field-attachment:not(:empty)
    margin:
        top: $spacing-xsmall
        right: $spacing-xsmall
        bottom: $spacing-xsmall

    word-break: break-all

.form-field-counter
    @include font-size(13px, 1)

/* Sizes */
/*---------------------------------------------------------------------------*/

.form-field--auto
    flex: 0 0 auto

.form-field--small
    flex: 0 0 84px
    width: 84px

.form-field--normal
    flex: 0 0 230px
    width: 230px

.form-field--large
    flex: 0 0 300px
    width: 300px

/* Types */
/*---------------------------------------------------------------------------*/

.form-field--star
    display: flex
    align-items: center

/* States */
/*---------------------------------------------------------------------------*/

.form-field--error
    .input,
    .input-control,
    .input:checked ~ .input-control,
    .input:disabled ~ .input-control,
    .input:disabled:hover ~ .input-control,
    &:hover .input-control
        border-color: $color-input-error-border

    .label,
    .label-value
        color: $color-input-error

    .input:focus
        border-color: $color-input-focus-border

.form-field--success
    .input,
    .input-control,
    .input:checked ~ .input-control,
    .input:disabled ~ .input-control,
    &:hover .input-control
        border-color: $color-input-success-border

    .label,
    .label-value
        color: $color-input-success

    .input:focus
        border-color: $color-input-focus-border

/* Form Field Options */
/*---------------------------------------------------------------------------*/

.form-field-options
    position: relative
    z-index: $zindex-content

    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: -4px
    padding: $spacing-small

    border:
        width: 0 2px 2px 2px
        color: $color-input-border
        style: solid
        radius: 0 0 $input-border-radius $input-border-radius

    background-color: #fff
    transition: $global-transition-fast

    cursor: text

.form-field-counter
    @include font-size(13px, 1)

// TODO revert when counter limit is validated
//.form-field-counter--error
//    color: $color-red

/* States */

.input:focus ~ .form-field-options
    border-color: $color-primary

.input:disabled ~ .form-field-options,
.input[readonly] ~ .form-field-options
    color: $color-input-disabled

    border-color: $color-border-disabled
    background-color: $color-input-disabled-background

    cursor: default

.form-field--error .form-field-options,
.form-field--error .input:disabled ~ .form-field-options,
.form-field--error .input[readonly] ~ .form-field-options
    border-color: $color-error

.form-field--success .form-field-options,
.form-field--success .input:disabled ~ .form-field-options,
.form-field--success .input[readonly] ~ .form-field-options
    border-color: $color-success

/* Types */
/*---------------------------------------------------------------------------*/

/* File */

.form-field--file-hidden-input
    display: flex
    align-items: center

    .form-field-attachment
        flex: 1

    .form-field--checkbox
        flex: 0 0 auto
        margin:
            bottom: 0
            left: auto

    .form-field-messages
        min-height: 0

    .form-field__input,
    .form-field__icon,
    .form-field__label
        display: none

/* Textarea */
+mq-lte-tablet-portrait
    .form-field--textarea-large--tablet-portrait
        .input--textarea
            height: 300px

.form-field--textarea-tiny
    .input--textarea
        height: 48px

/* Select */

.select
    position: relative

    /* Dropdown arrow */

    &::after
        position: absolute
        top: 50%
        right: 17px
        z-index: $zindex-dropdown

        display: block
        width: 12px
        height: 8px
        margin-top: -3px

        pointer-events: none
        background: no-repeat center center
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5pY24tLXNlbGVjdC1hcnJvdyB7CiAgICAgICAgZmlsbDogY3VycmVudENvbG9yOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBjbGFzcz0iaWNuLS1zZWxlY3QtYXJyb3ciIGQ9Ik0xMS41LDEuODk0TDcuMzYsNi4xMTNoMEw2LDcuNSw0LjY0LDYuMTEzaDBMMC41LDEuODk0bDEuMzYtMS4zODZMNiw0LjcyNmw0LjEzOS00LjIxOVoiLz4KPC9zdmc+')
        content: ''

    select
        display: inline-block
        width: 100%
        padding-right: 40px

        appearance: none
        -webkit-appearance: none
        -moz-appearance: none

    /* Undo the Firefox inner focus ring */

    select:focus:-moz-focusring
        color: transparent
        text-shadow: 0 0 0 #000

    /* Active/open */

    select:active
        color: #fff

        background-color: $color-input-border

    /* Hide the arrow in IE10 and up */

    select::-ms-expand
        display: none

.select--disabled
    &::after
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5pY24tLXNlbGVjdC1hcnJvdyB7CiAgICAgICAgZmlsbDogY3VycmVudENvbG9yOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBjbGFzcz0iaWNuLS1zZWxlY3QtYXJyb3ciIGQ9Ik0xMS41LDEuODk0TDcuMzYsNi4xMTNoMEw2LDcuNSw0LjY0LDYuMTEzaDBMMC41LDEuODk0bDEuMzYtMS4zODZMNiw0LjcyNmw0LjEzOS00LjIxOVoiLz4KPC9zdmc+')
</style>
