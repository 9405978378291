<template>
    <component :is="`icon-${name}`" :class="[antlerClass(), `icon--${name}`]" />
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppIcon",
    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: null
        },
        variant: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("icon", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.icon
    display: inline-flex
    flex: 0 0 24px
    width: 24px
    height: 24px

    vertical-align: middle
    transition: fill 200ms
    fill: currentColor

    & + &
        margin-left: $spacing-xsmall

/* Icon States */
/*---------------------------------------------------------------------------*/

.icon--disabled
    fill: $color-gray-light
    color: $color-gray-light

.icon--circle-error
    padding: $spacing-xsmall

    color: #fff

    border-radius: $global-border-radius-rounded
    background-color: $color-error

    &.icon--small
        padding: 5px

/* Icon Styles */
/*---------------------------------------------------------------------------*/

.icon--star
    fill: $color-blue-lighter,
    color: $color-blue-lighter

.icon--star--active
    fill: $color-yellow-light,
    color: $color-yellow-light

.icon--circle
    align-items: center
    justify-content: center
    flex: 0 0 auto
    width: 20px
    height: 20px
    min-width: 20px
    min-height: 20px
    padding: 4px

    fill: #fff

    border-radius: 50%
    background-color: $color-blue-lighter

.icon--circle.icon--minus-small
    padding: 6px

.icon--circle-blue
    background-color: $color-blue

.icon--circle-green-dark
    background-color: $color-green-dark

.icon--circle-purple
    background-color: $color-purple

/* Icon Colors */
/*---------------------------------------------------------------------------*/

.icon--primary,
.icon--color-primary
    fill: $color-primary
    color: $color-primary

.icon--secondary,
.icon--color-secondary
    fill: $color-secondary
    color: $color-secondary

.icon--white,
.icon--color-white
    fill: #fff
    color: #fff

.icon--red,
.icon--color-red
    fill: $color-red
    color: $color-red

.icon--blue-light,
.icon--color-blue-light
    fill: $color-blue-light
    color: $color-blue-light

/* Icon Sizes */
/*---------------------------------------------------------------------------*/
.icon--extra-tiny,
.icon--size-xxsmall
    flex: 0 0 12px
    width: 12px
    height: 12px

.icon--tiny,
.icon--size-xsmall
    flex: 0 0 16px
    width: 16px
    height: 16px

.icon--small,
.icon--size-small
    flex: 0 0 20px
    width: 20px
    height: 20px

.icon--regular,
.icon--size-regular
    flex: 0 0 24px
    width: 24px
    height: 24px

.icon--large,
.icon--size-large
    flex: 0 0 56px
    width: 56px
    height: 56px
</style>
