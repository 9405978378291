var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppFormField',{class:[
        _vm.icon ? 'form-field--checkbox-icon' : 'form-field--checkbox',
        { 'form-field--error': _vm.hasMessages }
    ]},[_c('label',{class:['label', { 'label--active': _vm.label || _vm.$slots.label }]},[_c('input',{ref:"input",class:[
                'input',
                'input--hidden',
                { 'form-field__input--active': _vm.isDirty }
            ],attrs:{"disabled":_vm.isDisabled,"type":"checkbox"},domProps:{"checked":_vm.isActive,"value":_vm.value},on:{"blur":_vm.onBlur,"change":_vm.onChange,"focus":_vm.onFocus,"keydown":_vm.onKeydown}}),_vm._t("label",function(){return [_c('span',{staticClass:"label-value"},[_vm._v(_vm._s(_vm.label))])]}),(_vm.icon)?_c('AppIcon',{class:[
                'input-control',
                'input-control--checkbox-icon',
                _vm.isDirty ? ("t-color-" + _vm.iconActiveColor) : ''
            ],attrs:{"name":_vm.icon}}):_c('div',{staticClass:"input-control input-control--checkbox"})],2),(!_vm.shouldHideDetails)?_c('div',{staticClass:"form-field-messages"},[(_vm.hasMessages || _vm.hasHint)?_c('AppMessageList',{attrs:{"messages":_vm.messages,"type":_vm.validationState}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }