<template>
    <div :class="antlerClass()">
        <div class="message-icon">
            <AppIcon :name="icon" class="message-icon__icon" />
        </div>
        <div class="message-text">
            <slot />
        </div>
        <div class="message-options">
            <slot name="options" />
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppMessage",
    components: { AppIcon },
    props: {
        icon: {
            type: String,
            default: "exclamation-mark"
        },
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("message", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.message
    display: flex
    align-items: center
    padding: $spacing-small $spacing-regular

    color: #fff
    font-weight: $strong-weight

    background-color: $color-primary

.message--flash
    position: fixed
    bottom: 0
    left: 50%
    z-index: $zindex-notifications

    min-width: 600px

    border-radius: 5px 5px 0 0

    transform: translateX(-50%)

    +mq-lte-tablet
        min-width: auto
        width: calc(100% - #{ $spacing-large })
        padding: $spacing-small

.message-icon
    display: flex
    align-items: center
    justify-content: center
    flex: 0 0 34px

    margin-right: $spacing-regular
    padding: $spacing-xsmall
    height: 34px
    width: 34px

    border-radius: $global-border-radius-rounded

    .icon
        height: auto
        max-height: 100%
        max-width: 100%

.message--info
    background-color: $color-info

    .message-icon
        background-color: $color-blue

.message--error
    background-color: $color-error

    .message-icon
        background-color: $color-red-dark

.message--success
    background-color: $color-success

    .message-icon
        background-color: $color-green-darker

.message-options
    margin-left: auto

    font-weight: $base-font-weight

    color: #fff

.message--rounded
    border-radius: $global-border-radius-big
</style>
