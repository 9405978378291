import { apolloClient } from "@/apollo";

export default {
    namespaced: true,
    state() {
        return {
            accessToken: null,
            tokenExpiry: null,
            passwordResetId: null,
            passwordResetToken: null
        };
    },
    getters: {
        accessToken: state => state.accessToken,
        tokenExpiry: state => state.tokenExpiry,
        userLoggedIn: state => !!state.tokenExpiry,
        permissions: state =>
            state.accessToken &&
            JSON.parse(atob(state.accessToken.split(".")[1])).permissions,
        passwordResetId: state => state.passwordResetId,
        passwordResetToken: state => state.passwordResetToken
    },
    mutations: {
        updateAuthTokens(state, tokenData) {
            if (tokenData) {
                state.accessToken = tokenData.token;
                state.tokenExpiry = tokenData.payload.exp;
            } else {
                // Reset apollo cache
                apolloClient.resetStore();
                state.accessToken = null;
                state.tokenExpiry = null;
            }
        },
        passwordResetTokens(state, tokenData) {
            if (tokenData) {
                state.passwordResetId = tokenData.uid;
                state.passwordResetToken = tokenData.token;
            } else {
                state.passwordResetId = null;
                state.passwordResetToken = null;
            }
        }
    },
    actions: {}
};
