<template>
    <component
        :is="as"
        :class="
            antlerClass('', {
                'with-icon': icon
            })
        "
    >
        <AppIcon v-if="icon" :name="icon" />
        <slot />
    </component>
</template>

<script>
import AppIcon from "@/components/AppIcon.vue";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppListItem",
    components: { AppIcon },
    props: {
        as: {
            type: String,
            default: "li",
            validator: value => {
                return ["li", "div"].includes(value);
            }
        },
        icon: {
            type: String,
            default: ""
        },
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("list-item", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
// variants
.list-item--centered
    display: inline-flex
    align-items: center

.list-item--bold
    font-weight: $strong-weight

.list-item--with-icon
    display: inline-flex
    align-items: center
    gap: 4px
</style>
