<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppFormField",
    setup() {
        const { antlerClass } = antlerComponent("form-field");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.form-field,
.form-field-holder
    position: relative

    flex: 1
    width: 100%

    & > .form-field
        width: auto

.form-field--with-icon
    .form-field__input
        padding-right: $spacing-large

.form-field--star .label
    display: inline-block
    width: 24px
    height: 24px
</style>
