var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'layout',
        'layout-root',
        'layout--vertical',
        { 'layout--offline': !_vm.onLine },
        _vm.$route.name ? ' layout--' + _vm.$route.name : '',
        _vm.$route.meta.layoutClass
    ]},[(!_vm.onLine)?_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"offline"},[_vm._v(" Geen internet verbinding. ")]):_vm._e(),(_vm.globalLoading > 0)?_c('TheLoadingBar'):_vm._e(),_c('router-view',{attrs:{"name":"header"}}),_c('router-view',{attrs:{"name":"subHeader"}}),_c('router-view'),_c('TheInsightModal'),_c('TheMessage')],1)}
var staticRenderFns = []

export { render, staticRenderFns }