import Vue from "vue";

export default {
    namespaced: true,
    state: {
        message: "",
        show: false,
        type: "info"
    },
    getters: {
        message: state => state.message,
        show: state => state.show,
        type: state => state.type
    },
    mutations: {
        setMessage(state, data) {
            data = data || {};
            state.message = data.message || "";
            state.show = data.show || !!data.message;
            state.type =
                (["error", "info", "success"].includes(data.type) &&
                    data.type) ||
                "info";
        }
    },
    actions: {
        showMessage({ commit }, data) {
            commit("setMessage");
            Vue.nextTick(function() {
                commit("setMessage", data);
            });
        }
    }
};
