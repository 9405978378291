export default name => {
    return {
        inject: {
            [name]: {
                default: {
                    register: () => () => {},
                    unregister: () => () => {}
                }
            }
        },
        mounted() {
            name in this && this[name].register(this);
        },
        beforeDestroy() {
            name in this && this[name].unregister(this);
        }
    };
};
