var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"masthead-sub masthead-student"},[_c('div',{staticClass:"masthead-student-profile"},[_c('AppAvatar',{staticClass:"u-margin-right",attrs:{"size":"small","image":_vm.student.profilePicture.url || undefined}}),_c('span',{staticClass:"masthead-student-profile__name"},[_vm._v(_vm._s(_vm.student.fullName))])],1),_c('AppNavBar',{staticClass:"nav-main masthead-sub-nav masthead-student-nav",attrs:{"aria-label":"student navigation"}},[_c('ul',{staticClass:"nav-bar__list nav-main__list"},[_c('li',{staticClass:"nav-bar__item nav-main__item masthead-student-nav__item"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],class:[
                        'nav-bar__link',
                        'nav-main__link',
                        {
                            'nav-main__link--active':
                                _vm.$route.name === 'student_insight'
                        }
                    ],attrs:{"to":{ name: 'student_insight' }}},[_vm._v(" Inzicht ")])],1),_c('li',{staticClass:"nav-bar__item nav-main__item masthead-student-nav__item"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-bar__link nav-main__link",attrs:{"to":{ name: 'student_information_base' },"active-class":"nav-main__link--active"}},[_vm._v(" Informatie ")])],1),_c('li',{staticClass:"nav-bar__item nav-main__item masthead-student-nav__item"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-bar__link nav-main__link",attrs:{"to":{ name: 'student_level' },"active-class":"nav-main__link--active"}},[_vm._v(" Onderwijsniveau ")])],1),_c('li',{staticClass:"nav-bar__item nav-main__item masthead-student-nav__item"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-bar__link nav-main__link",attrs:{"to":{ name: 'student_report' },"active-class":"nav-main__link--active"}},[_vm._v(" Rapporten ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }