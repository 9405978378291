<template>
    <TheRoot as="main">
        <AppLayoutInner variant="centered">
            <AppContent variant="shadow small">
                <slot />
            </AppContent>
        </AppLayoutInner>
    </TheRoot>
</template>
<script>
import AppContent from "@/components/AppContent";
import AppLayoutInner from "@/components/AppLayoutInner";
import TheRoot from "@/components/TheRoot.vue";
export default {
    components: { TheRoot, AppLayoutInner, AppContent }
};
</script>
