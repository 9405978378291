<template>
    <component
        :is="as"
        :class="
            antlerClass('', {
                loading: loading,
                'with-icon': icon
            })
        "
        :disabled="disabled || loading"
        :title="title"
        :type="type"
        :to="as === 'router-link' ? to : undefined"
        v-on="$listeners"
    >
        <span v-if="loading" :class="antlerClass('loader')" />
        <AppIcon
            v-else-if="icon"
            :name="icon"
            :class="iconClass"
            :size="iconSize"
        />
        <slot />
    </component>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppButton",
    components: {
        AppIcon
    },
    props: {
        disabled: Boolean,
        loading: Boolean,
        icon: {
            type: String,
            default: ""
        },
        as: {
            type: String,
            default: "button",
            validator: value => {
                return ["a", "div", "button", "router-link"].includes(value);
            }
        },
        iconClass: {
            type: String,
            default: "icon--tiny"
        },
        iconSize: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        to: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: "button",
            validator: value => {
                return ["button", "submit"].includes(value);
            }
        },
        variant: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("button", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.button
    display: inline-flex
    align-items: center
    gap: 8px
    padding: $spacing-xsmall $spacing-regular

    @include font-size(14px, 1)
    color: $color-button-font
    text-align: center
    text-decoration: none

    white-space: nowrap // force text in button to 1 line
    vertical-align: middle
    cursor: pointer
    background-color: $color-button-background
    border: none
    border-radius: $button-border-radius
    outline: 0

    user-select: none // remove button from selection

    &:hover,
    .has-button:hover &
        color: $color-button-font

        background-color: tint($color-button-background, 5%)

    &:disabled,
    &:disabled:hover,
    .has-button:hover &:disabled
        cursor: default
        background-color: tint($color-button-background, 20%)

        box-shadow: none
        transform: translateY(0)

.button:not(.button--link)
    font:
        family: $button-font-family
        weight: $button-font-weight

.button__icon
    margin-right: 8px

/* Button Styles */
/*---------------------------------------------------------------------------*/

.button--shadow
    @include buttonShadow()

    &:disabled
        background-color: tint($color-button-background, 20%)

.button--no-shadow
    box-shadow: none

.button--primary
    background-color: $color-primary
    @include buttonShadow(shade($color-primary, 20%))

    &:hover,
    .has-button:hover &
        background-color: tint($color-primary, 5%)

    &:disabled,
    &:disabled:hover,
    .has-button:hover &:disabled
        background-color: tint($color-primary, 20%)

.button--secondary
    background-color: $color-secondary
    @include buttonShadow(shade($color-secondary, 20%))

    &:hover,
    .has-button:hover &
        background-color: tint($color-secondary, 5%)

    &:disabled,
    &:disabled:hover,
    .has-button:hover &:disabled
        background-color: tint($color-secondary, 20%)

.button--tertiary
    background-color: $color-tertiary
    @include buttonShadow(shade($color-tertiary, 20%))

    &:hover,
    .has-button:hover &
        background-color: tint($color-tertiary, 5%)

    &:disabled,
    &:disabled:hover,
    .has-button:hover &:disabled
        background-color: tint($color-tertiary, 20%)

.button--ghost
    color: currentColor

    border-width: 0
    background-color: transparent
    fill: currentColor
    box-shadow: none
    outline: none

    &:hover,
    .has-button:hover &
        color: currentColor

        background-color: transparent
        box-shadow: none

        opacity: .8

    &:active
        box-shadow: none

    &:disabled,
    &:disabled:hover,
    .has-button:hover &:disabled
        background-color: transparent

.button--clean
    @extend .button--ghost
    padding: 0

.button--close
    display: flex
    align-items: center
    justify-content: center
    width: 30px
    height: 30px
    padding: 0

    @include font-size(30px, 1)

.button--icon
    justify-content: center
    align-items: center
    padding: 0
    width: 48px
    height: 48px

    @include font-size(30px)

    .button__icon
        margin-right: 0

.button--icon-large
    width: 54px
    height: 54px

.button--icon-clean
    @extend .button--clean
    width: auto
    height: auto

    .button__icon
        margin-right: 0

.button--icon-small
    justify-content: center
    padding: 4px 8px
    width: 30px
    height: 30px

    @include font-size(20px)

    .button__icon
        margin-right: 0

.button--icon-text
    position: relative
    top: -2px

.button--circle
    border-radius: 50%

.button--rounded
    border-radius: $global-border-radius-rounded

.button--link
    padding: 0

    color: currentColor
    font-weight: $base-font-weight

    background-color: transparent
    border:
        width: 0
        radius: 0

    box-shadow: none

    &:hover
        background-color: transparent
        box-shadow: none
        color: $color-link-hover

    &:disabled
        color: $color-blue-light

        background-color: transparent
        box-shadow: none

        &:hover
            background-color: transparent

    &:active
        box-shadow: none

.button--link-underline
    text-decoration: underline

.button--link-bold
    font-weight: $strong-weight

.button--link-large
    @include font-size($base-font-size)

.button--link-font-primary
    font-family: $primary-font-family

.button--flag
    color: $color-gray-light

    &:hover,
    &--active
        color: $color-red

.button--draggable
    cursor: move

.button--toggle
    color: currentColor

    background-color: $color-blue-lighter

    border-radius: 50%
    box-shadow: none

    &:hover
        color: #fff

.button--insight
    @extend .button--yellow
    justify-content: center
    padding: 0
    width: 54px
    height: 54px

    @include font-size(30px)

    border-radius: 50%

.button--status
    min-width: 70px

    justify-content: center

.button--with-icon
    display: inline-flex
    align-items: center

.button--evaluate
    padding:
        right: 40px
        left: 40px

    @include font-size(15px, 1)

    +mq-tablet-landscape
        @include font-size(16px, 1)

/* Button Sizes */
/*---------------------------------------------------------------------------*/

.button--xsmall,
.button--size-xsmall
    padding: 3px $spacing-xsmall

    @include font-size(12px, 1)

.button--small,
.button--size-small
    padding: $spacing-xsmall $spacing-small

    @include font-size(13px, 1)

.button--medium,
.button--size-medium
    padding: $spacing-small

    @include font-size(15px, 1)

    +mq-tablet-landscape
        padding: $spacing-small $spacing-regular

        @include font-size(16px, 1)

.button--medium-with-icon
    @extend .button--medium
    padding-left: $spacing-small

.button--large,
.button--size-large
    padding: $spacing-small $spacing-regular

    @include font-size(20px, 1)

.button--huge,
.button--size-huge
    padding: $spacing-small $spacing-regular

    @include font-size(16px, 1)

    +mq-tablet
        padding: $spacing-small $spacing-large

        @include font-size(20px, 1)

.button--huge-with-icon
    @extend .button--huge
    padding-left: $spacing-regular

    .button__icon
        margin-right: $spacing-small

.button--full-mobile
    +mq-lte-mobile
        display: block
        width: 100%

/* Button Colors */
/*---------------------------------------------------------------------------*/

.button--green
    background-color: $color-green

.button--yellow
    background-color: $color-yellow

    @include buttonShadow(shade($color-yellow, 20%))

    &:hover,
    .has-button:hover &
        background-color: $color-yellow

.button--light
    background-color: $color-blue-lighter

    color: inherit

    &:hover,
    .has-button:hover &
        background-color: $color-blue

        color: #fff

.button--color-white
    color: $color-primary

    background-color: #fff

    @include buttonShadow(#ccd3dd)

    &:hover,
    &:disabled,
    .has-button:hover &
        color: $color-primary

        background-color: $color-blue-lightest

/* Button Loader */
/*---------------------------------------------------------------------------*/

.button__loader
    position: relative

    display: inline-block
    width: 16px
    height: 16px

    &::before
        position: absolute
        top: 0
        left: 0

        width: 16px
        height: 16px

        border:
            color: currentColor transparent transparent transparent
            radius: $global-border-radius-rounded
            style: solid
            width: 2px
        content: ""

        animation: spinning 2000ms $global-transition-animation
        animation-iteration-count: infinite

.button--loading
    position: relative

    .button__icon,
    .button--icon-text
        display: none
</style>
