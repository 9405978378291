<template>
    <AppFormGroup
        :class="['form-group--radio', { 'form-field--error': hasMessages }]"
    >
        <!-- prettier-ignore -->
        <label v-if="label" class="label">{{ label }} <span v-if="required">*</span></label>

        <div class="form-group-wrapper">
            <slot />
        </div>

        <div v-if="!hideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormGroup>
</template>

<script>
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppInput from "@/components/AppInput";
import AppMessageList from "@/components/AppMessageList";

import RegistrableProvider from "@/mixins/RegistrableProvider";
import { isEqual } from "lodash";

export default {
    name: "AppInputRadioGroup",
    components: {
        AppFormGroup,
        AppMessageList
    },
    extends: AppInput,
    mixins: [RegistrableProvider("radio")],
    model: {
        prop: "value",
        event: "change"
    },
    provide() {
        return {
            radio: this
        };
    },
    props: {
        name: {
            type: String,
            default: undefined
        },
        value: {
            type: [String, Number, Boolean],
            default: null
        }
    },
    data() {
        return {
            radios: []
        };
    },
    watch: {
        internalValue: "setActiveRadio"
    },
    mounted() {
        this.setActiveRadio();
    },
    methods: {
        focus() {
            if (this.radios.length > 0) {
                this.radios[0].focus();
            }
        },
        blur() {
            if (this.radios.length > 0) {
                this.radios[0].blur();
            }
        },
        onRadioChange(value) {
            if (this.disabled) return;

            this.hasInput = true;
            this.internalValue = value;
            this.setActiveRadio();
            this.$nextTick(this.validate);
        },
        onRadioBlur(event) {
            if (
                !event.relatedTarget ||
                !event.relatedTarget.classList ||
                !event.relatedTarget.classList.contains("form-field--radio")
            ) {
                this.hasInput = true;
                this.isFocused = false;
                this.$emit("blur", event);
            }
        },
        onRadioFocus(event) {
            if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("focus", event);
            }
        },
        setActiveRadio() {
            for (const radio of this.radios) {
                radio.isActive = this.valueComparator(
                    this.internalValue,
                    radio.value
                );
            }
        },
        register(radio) {
            radio.isActive = this.valueComparator(
                this.internalValue,
                radio.value
            );
            radio.$on("blur", this.onRadioBlur);
            radio.$on("change", this.onRadioChange);
            radio.$on("focus", this.onRadioFocus);
            this.radios.push(radio);
        },
        unregister(radio) {
            radio.$off("blur", this.onRadioBlur);
            radio.$off("change", this.onRadioChange);
            radio.$off("focus", this.onRadioFocus);

            const index = this.radios.findIndex(r => r === radio);
            if (index > -1) this.radios.splice(index, 1);
        },
        valueComparator(a, b) {
            return isEqual(a, b);
        }
    }
};
</script>

<style lang="sass">
.form-group--radio:not(.form-group--horizontal)
    .form-group-wrapper
        display: flex
        flex-direction: column
        gap: 16px
</style>
