var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppFormField',{class:[
        { 'form-field--error': _vm.hasMessages },
        'datepicker--' + _vm.preferredOpenDirection,
        { 'datepicker--disabled': _vm.isDisabled }
    ]},[_c('Datepicker',{ref:"input",class:[
            'form-field__input',
            {
                'form-field__input--active': _vm.isDirty || _vm.isFocused
            }
        ],attrs:{"disabled":_vm.isDisabled,"disabled-dates":_vm.disabledDates,"format":"dd MMMM yyyy","full-month-name":"","highlighted":_vm.highlightedDates,"language":_vm.languageNl,"monday-first":"","required":_vm.required,"value":_vm.value},on:{"input":_vm.onInput}}),(_vm.label)?_c('AppFormFieldLabel',{attrs:{"data-testid":"label"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form__icon form-field__icon"},[_c('AppIcon',{attrs:{"name":"calendar"}})],1),(!_vm.hideDetails)?_c('div',{staticClass:"form-field-messages"},[(_vm.hasMessages || _vm.hasHint)?_c('AppMessageList',{attrs:{"messages":_vm.messages,"type":_vm.validationState}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }