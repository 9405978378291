<template>
    <TheRoot>
        <slot />
    </TheRoot>
</template>
<script>
import TheRoot from "@/components/TheRoot.vue";
export default {
    components: { TheRoot }
};
</script>
