<template>
    <component :is="as" :class="antlerClass()">
        <slot />
    </component>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppList",
    props: {
        as: {
            type: String,
            default: "ul",
            validator: value => {
                return ["ul", "div"].includes(value);
            }
        },
        variant: {
            type: String,
            default: ""
        },
        gap: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("list", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.list
    display: flex
    flex-direction: column
    gap: $spacing-small
    margin-left: 0

    list-style: none

.list--inline
    +mq-tablet-portrait
        flex-direction: row
        flex-wrap: wrap
        align-items: flex-start

.list--gap-small
    gap: 8px

.list--gap-y-xsmall
    row-gap: 4px

.list--gap-x-large
    column-gap: $spacing-regular
</style>
