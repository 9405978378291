<template>
    <AppFormField
        :class="[
            icon ? 'form-field--checkbox-icon' : 'form-field--checkbox',
            { 'form-field--error': hasMessages }
        ]"
    >
        <label :class="['label', { 'label--active': label || $slots.label }]">
            <input
                ref="input"
                :checked="isActive"
                :class="[
                    'input',
                    'input--hidden',
                    { 'form-field__input--active': isDirty }
                ]"
                :disabled="isDisabled"
                type="checkbox"
                :value="value"
                @blur="onBlur"
                @change="onChange"
                @focus="onFocus"
                @keydown="onKeydown"
            />

            <slot name="label">
                <span class="label-value">{{ label }}</span>
            </slot>

            <AppIcon
                v-if="icon"
                :name="icon"
                :class="[
                    'input-control',
                    'input-control--checkbox-icon',
                    isDirty ? `t-color-${iconActiveColor}` : ''
                ]"
            />
            <div v-else class="input-control input-control--checkbox" />
        </label>

        <div v-if="!shouldHideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormField>
</template>

<script>
import AppFormField from "@/components/AppFormField.vue";
import AppIcon from "@/components/AppIcon";
import AppInput from "@/components/AppInput";
import AppMessageList from "@/components/AppMessageList";

import RegistrableInject from "@/mixins/RegistrableInject";
import { isEqual } from "lodash";

export default {
    name: "AppInputCheckbox",
    components: {
        AppFormField,
        AppIcon,
        AppMessageList
    },
    extends: AppInput,
    mixins: [RegistrableInject("checkbox")],
    model: {
        prop: "inputValue",
        event: "change"
    },
    props: {
        icon: {
            type: String,
            default: undefined
        },
        iconActiveColor: {
            type: String,
            default: "green"
        },
        inputValue: {
            type: [String, Number, Boolean, Array],
            default: undefined
        },
        falseValue: {
            type: [String, Number, Boolean],
            default: undefined
        },
        trueValue: {
            type: [String, Number, Boolean],
            default: undefined
        },
        multiple: {
            type: Boolean,
            default: null
        },
        value: {
            type: [String, Number, Boolean],
            default: null
        }
    },
    data(vm) {
        return {
            lazyValue: vm.inputValue
        };
    },
    computed: {
        isMultiple() {
            return (
                this.multiple === true ||
                (this.multiple === null && Array.isArray(this.internalValue))
            );
        },
        isActive() {
            const value = this.value;
            const input = this.internalValue;

            if (this.isMultiple) {
                if (!Array.isArray(input)) return false;

                return input.some(item => this.valueComparator(item, value));
            }

            if (this.trueValue === undefined || this.falseValue === undefined) {
                return value
                    ? this.valueComparator(value, input)
                    : Boolean(input);
            }

            return this.valueComparator(input, this.trueValue);
        },
        isDirty() {
            return this.isActive;
        },
        shouldHideDetails() {
            return (
                this.hideDetails === true ||
                (this.hideDetails === undefined && !!this.checkbox._isVue)
            );
        }
    },
    watch: {
        inputValue(val) {
            this.lazyValue = val;
        }
    },
    methods: {
        focus() {
            this.onFocus();
        },
        blur() {
            this.$refs.input ? this.$refs.input.blur() : this.onBlur();
        },
        onBlur(event) {
            this.isFocused = false;
            this.$emit("blur", event);
        },
        onChange() {
            if (this.isDisabled) return;

            const value = this.value;
            let input = this.internalValue;
            if (this.isMultiple) {
                if (!Array.isArray(input)) {
                    input = [];
                }

                const length = input.length;

                input = input.filter(
                    item => !this.valueComparator(item, value)
                );

                if (input.length === length) {
                    input.push(value);
                }
            } else if (
                this.trueValue !== undefined &&
                this.falseValue !== undefined
            ) {
                input = this.valueComparator(input, this.trueValue)
                    ? this.falseValue
                    : this.trueValue;
            } else if (value) {
                input = this.valueComparator(input, value) ? null : value;
            } else {
                input = !input;
            }

            this.validate(true, input);
            this.internalValue = input;
        },
        onFocus(event) {
            if (!this.$refs.input) return;

            if (document.activeElement !== this.$refs.input) {
                return this.$refs.input.focus();
            }

            if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("focus", event);
            }
        },
        onKeydown() {},
        valueComparator(a, b) {
            return isEqual(a, b);
        }
    }
};
</script>

<style lang="sass">
.form-field--checkbox .label
    display: block
    min-height: 28px
    min-width: 28px

.form-field--checkbox .label--active
    padding:
        top: 4px
        left: 36px

.input-control--checkbox
    border-radius: 5px

.input-control--checkbox::after
    height: 9px
    width: 14px

    transform: translate3D(-50%, -70%, 0) rotate(-45deg) scale(0)
    transition: $global-transition-fast

.input:checked ~ .input-control--checkbox::after
    border:
        bottom: 2px solid #fff
        left: 2px solid #fff

    transform: translate3D(-50%, -70%, 0) rotate(-45deg) scale(1)

.input:checked:disabled ~ .input-control--checkbox::after
    border-color: $color-blue-light

// Checkbox icon
.form-field--checkbox-icon
    flex: 0 0 auto

.form-field--checkbox-icon .label
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

    cursor: pointer

    &:hover
        opacity: .8

.input-control--checkbox-icon
    position: relative
    top: auto
    left: auto

    border: 0
    background-color: transparent

    cursor: pointer

.input:not(:disabled) ~ .input-control--checkbox-icon:hover
    opacity: .8

.input:disabled ~ .input-control--checkbox-icon
    background-color: transparent
    opacity: .6

    cursor: default

.input:checked ~ .input-control--checkbox-icon
    background-color: transparent
</style>
