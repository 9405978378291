<template>
    <figure :class="antlerClass()">
        <img :src="image" :class="antlerClass('image')" />
    </figure>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppAvatar",
    props: {
        image: {
            type: String,
            default: "/img/avatar.svg"
        },
        size: {
            type: String,
            default: "normal",
            validator: value => {
                return ["tiny", "small", "normal", "large", "huge"].includes(
                    value
                );
            }
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("avatar", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.avatar
    display: flex
    align-items: center
    justify-content: center

    border-radius: 50%
    background-color: $color-blue-lightest

.avatar__image
    width: 100%
    height: 100%
    max-width: 100%
    border-radius: 50%
    object-fit: cover

.avatar--size-small
    width: 40px
    height: 40px
    flex: 0 0 40px

.avatar--size-normal
    width: 50px
    height: 50px
    flex: 0 0 50px

.avatar--size-medium
    width: 70px
    height: 70px
    flex: 0 0 70px

.avatar--size-huge
    width: 100px
    height: 100px
    flex: 0 0 100px

    +mq-tablet
        width: 160px
        height: 160px
        flex: 0 0 160px
</style>
