<template>
    <component :is="as" :class="antlerClass()">
        <slot />
    </component>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppTitle",
    props: {
        as: {
            type: String,
            default: "h1",
            validator: value => {
                return [
                    "div",
                    "h1",
                    "h2",
                    "h3",
                    "h4",
                    "h5",
                    "h6",
                    "div"
                ].includes(value);
            }
        },
        variant: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "",
            validator: value => {
                return [
                    "",
                    "xxxsmall",
                    "xxsmall",
                    "xsmall",
                    "small",
                    "large"
                ].includes(value);
            }
        },
        color: {
            type: String,
            default: "",
            validator: value => {
                return ["", "primary-dark"].includes(value);
            }
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("title", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.title
    color: $color-primary-dark
    @include font-size(24px, 20px)

    +mq-lte-tablet-portrait
        @include font-size(20px)

    .link
        color: currentColor
        text-decoration: none

// variants

.title--centered
    display: flex
    align-items: center

// fonts

.title--font-secondary
    font-family: $secondary-font-family

// sizes

.title--size-xxsmall
    @include font-size(14px, 14px)

.title--size-xsmall
    @include font-size(18px, 18px)

.title--size-small
    @include font-size(20px, 20px)

// colors
.title--color-primary-dark
    color: $color-primary-dark

/* Subtitles */
/*---------------------------------------------------------------------------*/

.subtitle
    @include font-size($subtitle-font-size, $subtitle-line-height)
    font:
        family: $subtitle-font-family
        weight: $subtitle-font-weight
    color: $color-subtitle

.subtitle-small
    @include font-size($base-font-size, $subtitle-line-height)
    font:
        family: $subtitle-font-family
        weight: $subtitle-font-weight
    color: $color-subtitle

.subtitle--medium
    font-weight: 500

.subtitle--underline
    padding-bottom: $spacing-xsmall
    border-bottom:
        width: 1px
        style: dashed
        color: currentColor

/* Absolutes */
/*---------------------------------------------------------------------------*/

.title-normal
    @include font-size($base-font-size)

.title-large
    @include font-size(34px)
</style>
