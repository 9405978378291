<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppDropdownMenu",
    setup() {
        const { antlerClass } = antlerComponent("dropdown-menu");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.dropdown-menu
    position: absolute
    top: -100vh
    left: 0
    z-index: $zindex-dropdown

    min-width: 100%

    border-radius: $global-border-radius
    opacity: 0
    pointer-events: none

    transform: translateY(-5px)
    transition:
        duration: 86ms
        property: opacity, transform

.dropdown__item
    white-space: nowrap
</style>
