import TheHeader from "@/components/TheHeader";
import TheHeaderStudent from "@/components/TheHeaderStudent";
import Vue from "vue";

import { apolloClient } from "@/apollo";
import { consoleError } from "@/console";

import gql from "graphql-tag";
import store from "@/store";

function getStudentEducationalLevels(studentId) {
    return apolloClient.query({
        query: gql`
            query allEducationalLevels($studentId: ID!) {
                allEducationalLevels(studentId: $studentId) {
                    edges {
                        node {
                            id
                            year
                            grade {
                                id
                                grade
                            }
                        }
                    }
                }
            }
        `,
        variables: {
            studentId: studentId
        },
        fetchPolicy: "cache-first"
    });
}

function getStudent(studentId) {
    return apolloClient.query({
        query: gql`
            query group($id: ID!) {
                student(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: studentId
        },
        fetchPolicy: "cache-first"
    });
}

export default [
    {
        path: Vue.prototype.$gettext("/leerlingen"),
        name: "student_overview",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "students" */ "@/views/students/Overview.vue"
                ),
            header: TheHeader
        },
        props: {
            default: route => ({
                page: parseInt(route.query.page) || 1
            })
        },
        meta: {
            title: Vue.prototype.$gettext("Overzicht")
        }
    },
    {
        path: Vue.prototype.$gettext("/leerlingen/:id"),
        components: {
            default: { render: h => h("router-view") },
            header: TheHeader,
            subHeader: TheHeaderStudent
        },
        props: {
            header: route => ({
                objectId: route.params.id
            }),
            subHeader: route => ({
                studentId: route.params.id
            })
        },
        beforeEnter: (to, from, next) => {
            const { params } = to;
            getStudent(params.id)
                .then(({ data, loading }) => {
                    if (!loading) {
                        if (data.student === null) {
                            store.dispatch("message/showMessage", {
                                message: Vue.prototype.$gettext(
                                    "Deze leerling is niet (meer) beschikbaar."
                                ),
                                type: "error"
                            });

                            next({
                                name: "dashboard"
                            });
                        }
                        next(true);
                    }
                })
                .catch(e => {
                    consoleError(e);
                    store.dispatch("message/showMessage", {
                        message: Vue.prototype.$gettext(
                            "Er is een fout opgetreden, probeer het a.u.b. opnieuw."
                        ),
                        type: "error"
                    });
                    next(false);
                });
        },
        children: [
            {
                path: "",
                name: "student_insight",
                component: () =>
                    import(
                        /* webpackChunkName: "students" */ "@/views/students/InsightOverview.vue"
                    ),
                props: route => {
                    const types = route.query.types ?? undefined;
                    const groups = route.query.groups ?? [];
                    const encouraging = route.query.encouraging ?? [];
                    return {
                        studentId: route.params.id,
                        subject: route.query.subject ?? null,
                        types:
                            Array.isArray(types) || types == null
                                ? types
                                : [types],
                        groups: Array.isArray(groups) ? groups : [groups],
                        encouraging: (Array.isArray(encouraging)
                            ? encouraging
                            : [encouraging]
                        ).map(x => String(x) === "true"),
                        important: String(route.query.important) === "true"
                    };
                },
                meta: {
                    title: Vue.prototype.$gettext("Inzichten")
                }
            },
            {
                path: Vue.prototype.$gettext("informatie"),
                component: () =>
                    import(
                        /* webpackChunkName: "students" */ "@/views/students/Information.vue"
                    ),
                props: route => ({
                    studentId: route.params.id
                }),
                children: [
                    {
                        path: "",
                        name: "student_information_base",
                        component: () =>
                            import(
                                /* webpackChunkName: "students" */ "@/views/students/InformationBase.vue"
                            ),
                        props: route => ({
                            studentId: route.params.id
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Informatie")
                        }
                    },
                    {
                        path: Vue.prototype.$gettext("diagnose"),
                        name: "student_information_diagnosis",
                        component: () =>
                            import(
                                /* webpackChunkName: "students" */ "@/views/students/InformationDiagnosis.vue"
                            ),
                        props: route => ({
                            studentId: route.params.id
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Diagnoses")
                        }
                    },
                    {
                        path: Vue.prototype.$gettext("documenten"),
                        name: "student_information_documents",
                        component: () =>
                            import(
                                /* webpackChunkName: "students" */ "@/views/students/InformationDocuments.vue"
                            ),
                        props: route => ({
                            studentId: route.params.id
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Documenten")
                        }
                    },

                    {
                        path: Vue.prototype.$gettext("talenten"),
                        name: "student_information_talents",
                        component: () =>
                            import(
                                /* webpackChunkName: "students" */ "@/views/students/InformationTalents.vue"
                            ),
                        props: route => ({
                            studentId: route.params.id
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Talenten")
                        }
                    }
                ]
            },
            {
                path: Vue.prototype.$gettext("onderwijsniveau"),
                component: () =>
                    import(
                        /* webpackChunkName: "students" */ "@/views/students/Level.vue"
                    ),
                props: route => ({
                    studentId: route.params.id
                }),
                meta: {
                    title: Vue.prototype.$gettext("Onderwijsniveau")
                },
                children: [
                    {
                        path: ":year-:grade",
                        name: "student_level_detail",
                        component: () =>
                            import(
                                /* webpackChunkName: "students" */ "@/views/students/LevelDetail.vue"
                            ),
                        props: route => ({
                            studentId: route.params.id,
                            year: parseInt(route.params.year),
                            grade: parseInt(route.params.grade)
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Onderwijsniveau")
                        }
                    },
                    {
                        path: "",
                        name: "student_level",
                        beforeEnter: (to, from, next) => {
                            const { params } = to;
                            getStudentEducationalLevels(params.id)
                                .then(({ data, loading }) => {
                                    if (!loading) {
                                        if (
                                            data.allEducationalLevels.edges
                                                .length
                                        ) {
                                            const levels = data.allEducationalLevels.edges
                                                .map(x => x.node)
                                                .filter(
                                                    (level, index, levels) =>
                                                        index ===
                                                        levels.findIndex(
                                                            x =>
                                                                x.year ===
                                                                    level.year &&
                                                                x.grade
                                                                    .grade ===
                                                                    level.grade
                                                                        .grade
                                                        )
                                                );
                                            next({
                                                name: "student_level_detail",
                                                params: {
                                                    id: params.id,
                                                    year: levels[0].year,
                                                    grade: levels[0].grade.grade
                                                }
                                            });
                                        } else {
                                            next();
                                        }
                                    }
                                })
                                .catch(e => {
                                    consoleError(e);
                                    store.dispatch("message/showMessage", {
                                        message: Vue.prototype.$gettext(
                                            "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                                        ),
                                        type: "error"
                                    });
                                    next(false);
                                });
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/students/LevelEmpty.vue"
                            ),
                        props: route => ({
                            studentId: route.params.id
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Onderwijsniveau")
                        }
                    }
                ]
            },
            {
                path: Vue.prototype.$gettext("rapporten"),
                name: "student_report",
                component: () =>
                    import(
                        /* webpackChunkName: "students" */ "@/views/students/Report.vue"
                    ),
                props: route => ({
                    studentId: route.params.id
                }),
                meta: {
                    title: Vue.prototype.$gettext("Rapporten")
                }
            }
        ]
    }
];
