<template>
    <transition name="modal">
        <div
            v-if="value"
            :class="antlerClass()"
            role="dialog"
            data-testid="modal"
            tabindex="-1"
            @click.self="clickBackground"
        >
            <div class="modal-dialog" role="document">
                <AppButton
                    v-if="closable"
                    variant="ghost close"
                    class="modal__close-button"
                    icon="cross"
                    @click="$emit('input', false)"
                />

                <component
                    :is="hasForm ? AppForm : 'div'"
                    :ref="hasForm ? 'form' : ''"
                    :class="hasForm && formClass ? formClass : ''"
                    :lazy-validation="hasForm ? true : null"
                    @submit.prevent="$emit('modalSubmit')"
                >
                    <div class="modal-content">
                        <header class="modal-header">
                            <AppTitle
                                v-if="$slots.title"
                                class="modal-header__title"
                            >
                                <slot name="title" />
                            </AppTitle>
                        </header>

                        <div class="modal-body">
                            <slot />
                        </div>
                    </div>

                    <footer v-if="$slots.footer" class="modal-footer">
                        <slot name="footer" />
                    </footer>

                    <div v-if="$slots.extra" class="modal-extra">
                        <slot name="extra" />
                    </div>
                </component>
            </div>
        </div>
    </transition>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppForm from "@/components/AppForm.vue";
import AppTitle from "@/components/AppTitle.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";
import { mapMutations } from "vuex";

export default defineComponent({
    name: "AppModal",
    components: { AppTitle, AppButton, AppForm },
    props: {
        persistent: Boolean,
        value: Boolean,
        hasForm: Boolean,
        closable: {
            type: Boolean,
            default: true
        },
        variant: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: ""
        },
        formClass: {
            type: String,
            default: ""
        }
    },
    computed: {
        AppForm() {
            return AppForm;
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("modal", props);

        return {
            antlerClass
        };
    },
    watch: {
        value(val) {
            if (val) {
                this.openModal(1);
            } else {
                this.openModal(-1);
            }
        }
    },
    // When the parent of a modal is destroyed, the modal reference is lost and the body keeps the "stop-scrolling" class.
    // This fix removes the opened modal reference via the state, triggering the removal of the body-class.
    beforeDestroy() {
        if (this.value) {
            this.openModal(-1);
        }
    },
    methods: {
        ...mapMutations("modals", ["openModal"]),
        clickBackground() {
            !this.persistent && this.closable && this.$emit("input", false);
        }
    }
});
</script>

<style lang="sass">
.modal
    @include overlay

    display: flex
    justify-content: center
    padding: $spacing-regular

    overflow-x: hidden
    overflow-y: auto
    outline: 0

    // reset specific styling from 'root' parent
    font:
        weight: $base-font-weight
        family: $secondary-font-family
        size: initial

    background-color: rgba($color-primary, .8)

.modal-dialog
    position: relative
    //z-index: $zindex-modal

    margin:
        top: auto
        bottom: auto
    width: 100%
    max-width: 600px

    background-color: #fff
    border-radius: 5px
    box-shadow: 1px 1px 2px rgba(#000, .16)

.modal-content
    position: relative

    display: flex
    flex-direction: column
    width: 100%
    padding: 32px

.modal-header
    position: relative

    display: flex
    min-height: 20px
    margin-bottom: $spacing-regular
    padding-bottom: $spacing-regular

    border-bottom: 1px solid #dce5f0

.modal-header__title
    display: flex
    width: 100%
    margin-bottom: 0

.modal-header__subtitle
    margin:
        right: $spacing-medium
        left: auto

    @include font-size($subtitle-font-size)
    color: $color-blue-shade

.modal__close-button
    position: absolute
    top: 16px
    right: 16px
    z-index: 4

.modal-body
    flex: 1 1 auto

.modal-extra
    display: flex
    padding: $spacing-medium

    background-color: $color-blue-lightest
    border-radius: 0 0 5px 5px

.modal-footer
    display: flex
    padding: $spacing-regular $spacing-medium

    background-color: $color-blue-lighter
    border-radius: 0 0 5px 5px

// variants

.modal--sticky
    .modal-header
        position: sticky
        top: 0
        z-index: 2

        background-color: #fff
        border-bottom:
            width: 1px
            style: solid
            color: $color-border

.modal--bordered
    .modal-header
        margin-bottom: $spacing-regular
        padding-bottom: $spacing-regular
        border-bottom:
            width: 1px
            style: solid
            color: $color-blue-shade-light

.modal--centered
    text-align: center

    .modal-content
        align-items: center

    .modal-body
        text-align: center

// sizes

.modal--size-small
    > .modal-dialog
        max-width: 390px

    .modal__close-button
        top: 8px
        right: 8px

.modal--size-smedium
    > .modal-dialog
        max-width: calc( 540px + (#{$spacing-medium} * 2) )

.modal--size-medium
    > .modal-dialog
        max-width: calc( 620px + (#{$spacing-medium} * 2) )

.modal--size-large
    > .modal-dialog
        max-width: calc( 900px + (#{$spacing-medium} * 2) )
</style>
