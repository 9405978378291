<template>
    <header class="masthead masthead--guest">
        <AppContainer>
            <img src="/img/logo-klassistent.svg" />
        </AppContainer>
    </header>
</template>

<script>
import AppContainer from "@/components/AppContainer.vue";
export default {
    components: {
        AppContainer
    }
};
</script>
<style lang="sass">
.logo
    font-family: $primary-font-family

.masthead--guest
    position: absolute
    top: 0
    left: 0

    width: 100vw

    background-color: transparent

.layout--offline .masthead--guest
    top: 48px
</style>
