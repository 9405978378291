<template>
    <TheLayout>
        <AppLayoutInner variant="centered">
            <AppOverviewPlaceholder v-if="!loading">
                <AppTitle class="overview-placeholder__title">
                    <translate :translate-params="{ name: me.firstName || '' }">
                        Welkom %{name}!
                    </translate>
                </AppTitle>
                <p v-if="allGroups.length === 0" key="nogroup" v-translate>
                    U bent niet toegewezen aan een groep. Vraag een beheerder om
                    toegang te krijgen tot uw groep.
                </p>
                <p v-else v-translate>
                    Er is geen groep geselecteerd. Kies een
                    <strong>groep</strong> linksboven uw scherm om door te gaan.
                </p>
            </AppOverviewPlaceholder>
        </AppLayoutInner>
    </TheLayout>
</template>

<script>
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutDefault";

import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    components: { AppTitle, AppLayoutInner, AppOverviewPlaceholder, TheLayout },
    data() {
        return {
            me: { groups: [] },
            loading: 0,
            allGroups: []
        };
    },
    apollo: {
        me: {
            query: gql`
                query meDashboard {
                    me {
                        id
                        firstName
                        groups {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            update(data) {
                if (data.me) {
                    const me = { ...data.me };
                    me.groups = me.groups.edges.map(x => x.node);
                    return me;
                } else {
                    return this.me;
                }
            },
            result() {
                if (this.me.groups.length === 1) {
                    this.$router.push({
                        name: "group_overview",
                        params: { id: this.me.groups[0].id }
                    });
                }
            }
        },
        allGroups: {
            query: gql`
                query allGroupsDashboard {
                    allGroups {
                        edges {
                            node {
                                id
                            }
                        }
                    }
                }
            `,
            update(data) {
                if (data.allGroups) {
                    return data.allGroups.edges.map(x => x.node);
                } else {
                    return this.allGroups;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de groepen."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
