import {
    InMemoryCache,
    IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";

import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import Vue from "vue";
import VueApollo from "vue-apollo";

import { createUploadLink } from "apollo-upload-client";
import introspectionQueryResultData from "@/fragmentTypes.json";
import store from "@/store";

Vue.use(VueApollo);

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = store.getters["auth/accessToken"];
    operation.setContext({
        headers: {
            authorization: token ? `JWT ${token}` : null
        }
    });
    return forward(operation);
});

const httpLink = createUploadLink({
    uri: process.env.VUE_APP_API_ENDPOINT
});

const apolloClient = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher }),
    connectToDevTools: process.env.NODE_ENV !== "production"
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
        $query: {
            loadingKey: "loading",
            fetchPolicy: "cache-and-network"
        }
    },
    watchLoading(isLoading, countModifier) {
        this.$store.commit("loading/loading", countModifier);
    }
});

export { apolloClient, apolloProvider };
