<template>
    <component :is="as" :class="antlerClass()">
        <slot />
    </component>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppLayout",
    props: {
        as: {
            type: String,
            default: "div",
            validator: value => {
                return ["div", "main"].includes(value);
            }
        },
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("layout", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.layout
    display: flex
    flex: 1
    min-height: 100%
    width: 100%
    max-width: 100vw

.layout--vertical
    flex-direction: column

.layout--horizontal
    flex-direction: row

.layout--centered
    align-items: center
    justify-content: center

// Since a Vue layout slot is rendered with 1 wrapping div this class is needed
// to maintain the fill in this child
.layout--parent-full
    display: flex
    flex: 1

    > div
        flex: 1
        height: auto
        min-height: 100%

.layout--auto
    flex: 0 0 auto

.layout--with-sidebar
    +mq-lte-tablet
        flex-direction: column

    .layout-main
        display: flex
        flex-direction: column
        max-width: 100%
        flex-grow: 1

        +mq-tablet-portrait
            max-width: calc(100% - 220px)

        +mq-tablet-landscape
            max-width: calc(100% - 280px)

.layout-side
    +mq-tablet-portrait
        display: flex
        flex: 0 0 220px
        width: 220px

    +mq-tablet-landscape
        flex: 0 0 280px
        width: 280px

.layout-side--large
    +mq-tablet-portrait
        flex-basis: 50%

.layout-main
    +mq-tablet-portrait
        display: flex
        flex: 1
        flex-direction: column

.layout--background
    background-color: $color-blue-lighter

.layout-root .layout--max-height
    max-height: calc(100vh - 75px) // minus 75px for the header
    min-height: 1px

.layout-root .page .layout--max-height
    max-height: calc(100vh - 126px) // minus header and page padding

.layout-header
    margin: $spacing-regular

.layout--offline
    padding-top: 48px
</style>
