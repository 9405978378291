import * as Integrations from "@sentry/integrations";
import * as Sentry from "@sentry/browser";

import Vue from "vue";

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Integrations.Vue({ Vue })]
    });
}
