import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import auth from "./store/auth";
import createMutationsSharer from "vuex-shared-mutations";
import insightmodal from "./store/insightmodal";
import loading from "./store/loading";
import message from "./store/message";
import modals from "./store/modals";
import online from "./store/online";

Vue.use(Vuex);

const vuexAuth = new VuexPersistence({
    storage: window.localStorage,
    modules: ["auth"]
});

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    modules: {
        auth,
        insightmodal,
        loading,
        message,
        online,
        modals
    },
    plugins: [
        vuexAuth.plugin,
        createMutationsSharer({
            predicate: ["auth/updateAuthTokens", "auth/passwordResetTokens"]
        })
    ],
    state: {},
    mutations: {},
    actions: {},
    getters: {}
});
