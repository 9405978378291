import TheHeader from "@/components/TheHeader";
import Vue from "vue";

import { apolloClient } from "@/apollo";
import { consoleError } from "@/console";
import gql from "graphql-tag";
import moment from "moment";
import store from "@/store";

function getGroupGrades(groupId) {
    return apolloClient.query({
        query: gql`
            query groupGrades($id: ID!) {
                group(id: $id) {
                    id
                    grades
                }
            }
        `,
        variables: {
            id: groupId
        },
        fetchPolicy: "cache-first"
    });
}
function getGroup(groupId) {
    return apolloClient.query({
        query: gql`
            query group($id: ID!) {
                group(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: groupId
        },
        fetchPolicy: "cache-first"
    });
}

function getGroupEducationalLevels(groupId, grade) {
    return apolloClient.query({
        query: gql`
            query groupEducationalLevels($groupId: ID!, $grade: Int!) {
                allEducationalLevels(groupId: $groupId, grade: $grade) {
                    edges {
                        node {
                            id
                            subject {
                                id
                                parent {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        `,
        variables: {
            groupId: groupId,
            grade: grade
        },
        fetchPolicy: "cache-first"
    });
}

function getGroupEducationPlanSubjects(groupId) {
    return apolloClient.query({
        query: gql`
            query allEducationPlanSubjectsQuery($id: ID!) {
                allEducationPlanSubjects(groupId: $id) {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        `,
        variables: {
            id: groupId
        },
        fetchPolicy: "cache-first"
    });
}

function getGroupReports(groupId) {
    return apolloClient.query({
        query: gql`
            query groupReports($groupId: ID!) {
                allReports(groupId: $groupId) {
                    edges {
                        node {
                            id
                            number
                        }
                    }
                }
            }
        `,
        variables: {
            groupId: groupId
        },
        fetchPolicy: "cache-first"
    });
}

export default [
    {
        path: Vue.prototype.$gettext("/groepen/:id"),
        components: {
            default: { render: h => h("router-view") },
            header: TheHeader
        },
        props: {
            header: route => ({
                objectId: route.params.id
            })
        },
        children: [
            {
                path: "",
                name: "group_overview",
                component: () =>
                    import(
                        /* webpackChunkName: "groups" */ "@/views/groups/Overview.vue"
                    ),
                beforeEnter: (to, from, next) => {
                    const { params } = to;
                    getGroup(params.id)
                        .then(({ data, loading }) => {
                            if (!loading) {
                                if (data.group === null) {
                                    store.dispatch("message/showMessage", {
                                        message: Vue.prototype.$gettext(
                                            "Deze groep is niet (meer) beschikbaar."
                                        ),
                                        type: "error"
                                    });

                                    next({
                                        name: "dashboard"
                                    });
                                }
                                next(true);
                            }
                        })
                        .catch(e => {
                            consoleError(e);
                            store.dispatch("message/showMessage", {
                                message: Vue.prototype.$gettext(
                                    "Er is een fout opgetreden, probeer het a.u.b. opnieuw."
                                ),
                                type: "error"
                            });
                            next(false);
                        });
                },
                props: route => ({
                    groupId: route.params.id
                }),
                meta: {
                    title: Vue.prototype.$gettext("Overzicht")
                }
            },
            {
                path: Vue.prototype.$gettext("planning"),
                component: () =>
                    import(
                        /* webpackChunkName: "groups" */ "@/views/groups/Schedule.vue"
                    ),
                props: route => ({
                    groupId: route.params.id
                }),
                meta: {
                    title: Vue.prototype.$gettext("Planning")
                },
                children: [
                    {
                        path: ":date(\\d{2}-\\d{2}-\\d{4})",
                        name: "group_schedule_detail",
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/groups/ScheduleDetail.vue"
                            ),
                        props: route => ({
                            groupId: route.params.id,
                            date: moment(route.params.date, "DDMMYYYY").toDate()
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Planning")
                        }
                    },
                    {
                        path: "",
                        name: "group_schedule",
                        redirect: to => ({
                            name: "group_schedule_detail",
                            params: {
                                id: to.params.id,
                                date: moment().format("DD-MM-YYYY")
                            }
                        })
                    }
                ]
            },
            {
                path: Vue.prototype.$gettext("planning/:groupScheduleId"),
                name: "group_schedule_update",
                component: () =>
                    import(
                        /* webpackChunkName: "groups" */ "@/views/groups/ScheduleUpdate.vue"
                    ),
                props: route => ({
                    groupScheduleId: route.params.groupScheduleId
                }),
                meta: {
                    title: Vue.prototype.$gettext("Planning")
                }
            },
            {
                path: Vue.prototype.$gettext("leerlingen"),
                name: "group_students",
                component: () =>
                    import(
                        /* webpackChunkName: "groups" */ "@/views/groups/Students.vue"
                    ),
                props: route => ({
                    groupId: route.params.id
                }),
                meta: {
                    title: Vue.prototype.$gettext("Leerlingen")
                }
            },
            {
                path: Vue.prototype.$gettext("onderwijsniveau"),
                name: "group_level_redirect",
                beforeEnter: (to, from, next) => {
                    const { params } = to;
                    getGroupGrades(params.id)
                        .then(({ data, loading }) => {
                            if (!loading) {
                                next({
                                    name: "group_level",
                                    params: {
                                        id: params.id,
                                        grade: data.group.grades[0]
                                    }
                                });
                            }
                        })
                        .catch(e => {
                            consoleError(e);
                            store.dispatch("message/showMessage", {
                                message: Vue.prototype.$gettext(
                                    "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                                ),
                                type: "error"
                            });
                            next(false);
                        });
                }
            },
            {
                path: Vue.prototype.$gettext("onderwijsniveau/:grade(\\d+)"),
                component: () =>
                    import(
                        /* webpackChunkName: "groups" */ "@/views/groups/Level.vue"
                    ),
                props: route => ({
                    groupId: route.params.id,
                    grade: parseInt(route.params.grade)
                }),
                meta: {
                    title: Vue.prototype.$gettext("Onderwijsniveau")
                },
                children: [
                    {
                        path: ":subject",
                        name: "group_level_detail",
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/groups/LevelDetail.vue"
                            ),
                        props: route => ({
                            groupId: route.params.id,
                            subjectId: route.params.subject,
                            grade: parseInt(route.params.grade)
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Onderwijsniveau")
                        }
                    },
                    {
                        path: "",
                        name: "group_level",
                        beforeEnter: (to, from, next) => {
                            const { params } = to;
                            getGroupEducationalLevels(
                                params.id,
                                parseInt(params.grade)
                            )
                                .then(({ data, loading }) => {
                                    if (!loading) {
                                        if (
                                            data.allEducationalLevels.edges
                                                .length
                                        ) {
                                            const levels = data.allEducationalLevels.edges
                                                .map(x => x.node)
                                                .filter(
                                                    (level, index, levels) =>
                                                        index ===
                                                        levels.findIndex(
                                                            x =>
                                                                x.subject.parent
                                                                    .id ===
                                                                level.subject
                                                                    .parent.id
                                                        )
                                                )
                                                .sort((a, b) => {
                                                    const nameA = a.subject.parent.name.toUpperCase();
                                                    const nameB = b.subject.parent.name.toUpperCase();
                                                    return nameA < nameB
                                                        ? -1
                                                        : nameA > nameB
                                                        ? 1
                                                        : 0;
                                                });
                                            next({
                                                name: "group_level_detail",
                                                params: {
                                                    id: params.id,
                                                    subject:
                                                        levels[0].subject.parent
                                                            .id,
                                                    grade: parseInt(
                                                        params.grade
                                                    )
                                                }
                                            });
                                        } else {
                                            next();
                                        }
                                    }
                                })
                                .catch(e => {
                                    consoleError(e);
                                    store.dispatch("message/showMessage", {
                                        message: Vue.prototype.$gettext(
                                            "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                                        ),
                                        type: "error"
                                    });
                                    next(false);
                                });
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/groups/LevelEmpty.vue"
                            ),
                        meta: {
                            title: Vue.prototype.$gettext("Onderwijsniveau")
                        }
                    }
                ]
            },
            {
                path: Vue.prototype.$gettext("ontwikkeling"),
                component: () =>
                    import(
                        /* webpackChunkName: "groups" */ "@/views/groups/EducationPlans.vue"
                    ),
                props: route => ({
                    groupId: route.params.id
                }),
                meta: {
                    title: Vue.prototype.$gettext("Ontwikkeling")
                },
                children: [
                    {
                        path: ":subject",
                        name: "group_education_plans_detail",
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/groups/EducationPlansDetail.vue"
                            ),
                        props: route => ({
                            groupId: route.params.id,
                            subjectId: route.params.subject
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Ontwikkeling")
                        }
                    },
                    {
                        path: "",
                        name: "group_education_plans",
                        beforeEnter: (to, from, next) => {
                            const { params } = to;
                            getGroupEducationPlanSubjects(params.id)
                                .then(({ data, loading }) => {
                                    if (!loading) {
                                        if (
                                            data.allEducationPlanSubjects.edges
                                                .length
                                        ) {
                                            const educationPlanSubjects = data.allEducationPlanSubjects.edges.map(
                                                x => x.node
                                            );
                                            next({
                                                name:
                                                    "group_education_plans_detail",
                                                params: {
                                                    id: params.id,
                                                    subject:
                                                        educationPlanSubjects[0]
                                                            .id
                                                }
                                            });
                                        } else {
                                            next();
                                        }
                                    }
                                })
                                .catch(e => {
                                    consoleError(e);
                                    store.dispatch("message/showMessage", {
                                        message: Vue.prototype.$gettext(
                                            "Er is een fout opgetreden bij het ophalen van de ontwikkelplannen."
                                        ),
                                        type: "error"
                                    });
                                    next(false);
                                });
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/groups/EducationPlansEmpty.vue"
                            ),
                        meta: {
                            title: Vue.prototype.$gettext("Ontwikkeling")
                        }
                    }
                ]
            },
            {
                path: Vue.prototype.$gettext("rapporten"),
                component: () =>
                    import(
                        /* webpackChunkName: "groups" */ "@/views/groups/Report.vue"
                    ),
                props: route => ({
                    groupId: route.params.id
                }),
                meta: {
                    title: Vue.prototype.$gettext("Rapporten")
                },
                children: [
                    {
                        path: ":number",
                        name: "group_report_detail",
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/groups/ReportDetail.vue"
                            ),
                        props: route => ({
                            groupId: route.params.id,
                            number: parseInt(route.params.number)
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Rapporten")
                        }
                    },
                    {
                        path: "",
                        name: "group_report",
                        beforeEnter: (to, from, next) => {
                            const { params } = to;
                            getGroupReports(params.id)
                                .then(({ data, loading }) => {
                                    if (!loading) {
                                        if (data.allReports.edges.length) {
                                            const nodeMaxNumber = data.allReports.edges.reduce(
                                                (prev, current) =>
                                                    prev.node.number >
                                                    current.node.number
                                                        ? prev
                                                        : current
                                            );
                                            next({
                                                name: "group_report_detail",
                                                params: {
                                                    id: params.id,
                                                    number:
                                                        nodeMaxNumber.node
                                                            .number
                                                }
                                            });
                                        } else {
                                            next();
                                        }
                                    }
                                })
                                .catch(e => {
                                    consoleError(e);
                                    store.dispatch("message/showMessage", {
                                        message: Vue.prototype.$gettext(
                                            "Er is een fout opgetreden bij het ophalen van rapporten."
                                        ),
                                        type: "error"
                                    });
                                    next(false);
                                });
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "groups" */ "@/views/groups/ReportEmpty.vue"
                            ),
                        props: route => ({
                            groupId: route.params.id
                        }),
                        meta: {
                            title: Vue.prototype.$gettext("Rapporten")
                        }
                    }
                ]
            }
        ]
    },
    {
        path: Vue.prototype.$gettext(
            "/groepen/:id/klasweergave/:date(\\d{2}-\\d{2}-\\d{4})"
        ),
        name: "group_schedule_class",
        component: () =>
            import(
                /* webpackChunkName: "groups" */ "@/views/groups/ScheduleClassView.vue"
            ),
        props: route => ({
            groupId: route.params.id,
            date: moment(route.params.date, "DDMMYYYY").toDate()
        }),
        meta: {
            title: Vue.prototype.$gettext("Klasweergave")
        }
    }
];
