<template>
    <AppFormGroup class="form-group--checkbox">
        <div class="form-group-wrapper">
            <slot />
        </div>

        <div class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormGroup>
</template>

<script>
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppMessageList from "@/components/AppMessageList";
import RegistrableProvider from "@/mixins/RegistrableProvider";

export default {
    name: "AppInputCheckboxGroup",
    components: {
        AppFormGroup,
        AppMessageList
    },
    mixins: [RegistrableProvider("checkbox")],
    provide() {
        return {
            checkbox: this
        };
    },
    data() {
        return {
            checkboxes: []
        };
    },
    computed: {
        hasError() {
            return this.checkboxes.some(x => x.hasError === true);
        },
        hasHint() {
            return this.checkboxes.some(x => x.hasHint === true);
        },
        hasMessages() {
            return this.checkboxes.some(x => x.hasMessages === true);
        },
        messages() {
            return this.hasHint
                ? [this.checkboxes.find(x => x.hasHint === true).hint]
                : this.hasMessages
                ? this.checkboxes.find(x => x.hasMessages === true).validations
                : [];
        },
        shouldValidate() {
            return this.checkboxes.some(x => x.shouldValidate === true);
        },
        validationState() {
            if (this.hasError && this.shouldValidate) return "error";
            return null;
        }
    },
    methods: {
        register(checkbox) {
            this.checkboxes.push(checkbox);
        },
        unregister(checkbox) {
            const index = this.checkboxes.findIndex(r => r === checkbox);
            if (index > -1) this.checkboxes.splice(index, 1);
        }
    }
};
</script>
