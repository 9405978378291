<template>
    <div
        :class="
            antlerClass('', {
                active: isActive
            })
        "
        v-on="$listeners"
    >
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        isActive: Boolean,
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("dropdown", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.dropdown
    position: relative

    display: flex
    align-items: center

    &.dropdown--active .dropdown-menu
        top: 100%
        opacity: 1
        pointer-events: auto
        transform: translateY(0)

.dropdown-content
    margin-bottom: 0

// variants
.dropdown--nav
    align-items: stretch

    .dropdown-menu
        min-width: 240px

        &::before,
        &::after
            position: absolute
            top: -3px
            left: 20px
            z-index: $zindex-dropdown

            display: block
            width: 20px
            height: 20px

            content: ''
            transform: rotate(45deg)
            transition: transform 100ms $global-transition-animation 100ms

        &::before
            z-index: 0
            box-shadow: 0 0 10px rgba(#000, .15)

        &::after
            background-color: #fff

.dropdown--right
    position: relative

    .dropdown-menu
        left: auto
        right: 0

        &::before,
        &::after
            right: 20px
            left: auto

.dropdown__divider
    display: block
    height: 1px
    width: 100%

    background-color: $color-border
</style>
