import "moment/locale/nl";

import Moment from "moment";
import Vue from "vue";
import VueMoment from "vue-moment";

import { extendMoment } from "moment-range";

Vue.use(VueMoment, {
    moment: extendMoment(Moment)
});
