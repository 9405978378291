<template>
    <div :class="antlerClass('', { 'with-button': hasButtonSlot })">
        <div class="overview-placeholder-inner">
            <AppArticle class="overview-placeholder-content">
                <slot />
            </AppArticle>
            <div v-if="hasButtonSlot" class="overview-placeholder-button">
                <slot name="button" />
            </div>
        </div>
    </div>
</template>

<script>
import AppArticle from "@/components/AppArticle.vue";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppOverviewPlaceholder",
    components: { AppArticle },
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("overview-placeholder", props);

        return {
            antlerClass
        };
    },
    computed: {
        hasButtonSlot() {
            return !!this.$slots.button;
        }
    }
};
</script>

<style lang="sass">
.overview-placeholder
    display: flex
    align-items: center
    justify-content: center
    width: 80vw
    height: 80vw
    min-width: 270px
    min-height: 270px
    max-width: 600px
    max-height: 600px
    padding: 10vw

    @include font-size(18px)
    text-align: center

    background-color: #fff
    border-radius: 50%

    +mq-tablet
        width: 70vw
        height: 70vw
        padding: 6vw

        @include font-size(22px)

    +mq-tablet-landscape
        width: 50vw
        height: 50vw

.overview-placeholder--centered
    margin: auto

.overview-placeholder--with-button .overview-placeholder-content
    margin:
        top: 30px
        bottom: $spacing-regular

.overview-placeholder__title
    margin-bottom: $spacing-regular

    @include font-size(20px)

    +mq-tablet
        margin-bottom: $spacing-medium

        @include font-size(24px)

.overview-placeholder--background
    background-color: $color-blue-lighter

.overview-placeholder--text-small
    @include font-size(18px)

    .overview-placeholder__title
        font-size: inherit
        line-height: inherit

/* sizes */

.overview-placeholder--small
    width: 60vw
    height: 60vw

    +mq-tablet
        width: 50vw
        height: 50vw

    +mq-tablet-landscape
        width: 40vw
        height: 40vw

/* in sidebar layouts */

.layout--with-sidebar
    .overview-placeholder:not(.overview-placeholder--fixed)
        width: 40vw
        height: 40vw

        +mq-desktop-large
            width: 60vw
            height: 60vw

    .overview-placeholder--small
        width: 50vw
        height: 50vw

        +mq-tablet-landscape
            width: 40vw
            height: 40vw

/* fixed sizes */

.overview-placeholder--fixed-small
    width: 260px
    height: 260px
    padding: $spacing-regular

    +mq-tablet-landscape
        width: 300px
        height: 300px
</style>
