export default namespace => {
    return {
        data() {
            return {
                [namespace]: []
            };
        },
        provide() {
            return {
                [namespace]: {
                    register: this.register,
                    unregister: this.unregister
                }
            };
        },
        methods: {
            register: null,
            unregister: null
        }
    };
};
