import TheHeader from "@/components/TheHeader";
import Vue from "vue";

export default [
    {
        path: Vue.prototype.$gettext("/weekplanning"),
        name: "schedule_weekly_overview",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "schedule" */ "@/views/schedules/Weekly.vue"
                ),
            header: TheHeader
        },
        meta: {
            title: Vue.prototype.$gettext("Weekplanning")
        }
    },
    {
        path: Vue.prototype.$gettext("/weekplanning/:id"),
        name: "schedule_weekly_detail",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "schedule" */ "@/views/schedules/WeeklyDetail.vue"
                ),
            header: TheHeader
        },
        props: {
            default: route => ({
                scheduleTemplateId: route.params.id
            })
        },
        meta: {
            title: Vue.prototype.$gettext("Weekplanning")
        }
    }
];
