<template>
    <header class="masthead">
        <AppContainer>
            <AppBrowse
                class="masthead-browse"
                :current="group"
                :empty-current-name="$gettext('Kies een groep')"
                :empty-current-name-mobile="$gettext('Groep')"
                :loading="loading"
                :options="allGroups"
            >
                <template slot="noOptions">
                    <translate>
                        Er zijn geen groepen aan uw profiel toegevoegd. Neem
                        contact op met de beheerder.
                    </translate>
                </template>
            </AppBrowse>

            <AppNavBar
                aria-label="main navigation"
                class="masthead-nav nav-main"
            >
                <ul class="nav-bar__list nav-main__list">
                    <li
                        v-if="group.id"
                        class="nav-bar__item nav-main__item d-none-lte-tablet"
                    >
                        <router-link
                            v-translate
                            :class="[
                                'nav-bar__link nav-main__link',
                                {
                                    'nav-main__link--active':
                                        $route.name === 'group_overview' &&
                                        'router-link-active'
                                }
                            ]"
                            :to="{
                                name: 'group_overview',
                                params: { id: group.id }
                            }"
                        >
                            Overzicht
                        </router-link>
                    </li>
                    <li
                        v-if="group.id"
                        class="nav-bar__item nav-main__item d-none-lte-tablet"
                    >
                        <router-link
                            v-translate
                            active-class="nav-main__link--active"
                            class="nav-bar__link nav-main__link"
                            :to="{
                                name: 'group_schedule',
                                params: { id: group.id }
                            }"
                        >
                            Planning
                        </router-link>
                    </li>
                    <li
                        v-if="group.id"
                        class="nav-bar__item nav-main__item d-none-lte-tablet"
                    >
                        <router-link
                            v-translate
                            active-class="nav-main__link--active"
                            :class="[
                                'nav-bar__link',
                                'nav-main__link',
                                {
                                    'nav-main__link--active':
                                        !!$route.name &&
                                        $route.name.startsWith('student_')
                                }
                            ]"
                            :to="{
                                name: 'group_students',
                                params: { id: group.id }
                            }"
                        >
                            Leerlingen
                        </router-link>
                    </li>
                    <li
                        v-if="group.id"
                        class="nav-bar__item nav-main__item d-none-lte-tablet"
                    >
                        <router-link
                            v-translate
                            active-class="nav-main__link--active"
                            class="nav-bar__link nav-main__link"
                            :to="{
                                name: 'group_level_redirect',
                                params: { id: group.id }
                            }"
                        >
                            Niveau
                        </router-link>
                    </li>
                    <li
                        v-if="group.id"
                        class="nav-bar__item nav-main__item d-none-lte-tablet"
                    >
                        <router-link
                            v-translate
                            active-class="nav-main__link--active"
                            class="nav-bar__link nav-main__link"
                            :to="{
                                name: 'group_education_plans',
                                params: { id: group.id }
                            }"
                        >
                            Ontwikkeling
                        </router-link>
                    </li>
                    <li
                        v-if="group.id"
                        class="nav-bar__item nav-main__item d-none-lte-tablet"
                    >
                        <router-link
                            v-translate
                            active-class="nav-main__link--active"
                            class="nav-bar__link nav-main__link"
                            :to="{
                                name: 'group_report',
                                params: { id: group.id }
                            }"
                        >
                            Rapporten
                        </router-link>
                    </li>
                    <li
                        class="nav-bar__item nav-main__item d-flex--align-right"
                    >
                        <AppInsightButton :group-id="group.id" />
                    </li>
                    <li class="nav-bar__item nav-main__item">
                        <AppAccountDropdown />
                    </li>
                </ul>
            </AppNavBar>
        </AppContainer>
    </header>
</template>

<script>
import AppAccountDropdown from "@/components/AppAccountDropdown";
import AppBrowse from "@/components/AppBrowse";
import AppContainer from "./AppContainer.vue";
import AppInsightButton from "@/components/AppInsightButton";
import AppNavBar from "@/components/AppNavBar.vue";

import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "TheHeader",
    components: {
        AppNavBar,
        AppContainer,
        AppAccountDropdown,
        AppInsightButton,
        AppBrowse
    },
    props: {
        objectId: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            allGroups: [],
            group: {},
            loading: 0,
            student: {}
        };
    },
    computed: {
        internalGroup() {
            if (this.loading === 0) {
                if (this.nodeName === "Group" && this.allGroups.length) {
                    return (
                        this.allGroups.find(
                            item => item.id === this.objectId
                        ) || {}
                    );
                } else if (
                    this.nodeName === "Student" &&
                    this.student &&
                    this.student.currentGroup
                ) {
                    return this.student.currentGroup;
                }
            }
            return undefined;
        },
        nodeName() {
            if (this.objectId) {
                return atob(this.objectId).split(":")[0];
            } else {
                return "";
            }
        }
    },
    apollo: {
        allGroups: {
            query: gql`
                query allGroupsTheHeader {
                    allGroups {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            update(data) {
                if (data.allGroups) {
                    return data.allGroups.edges.map(x => {
                        return {
                            name: this.$gettextInterpolate("Groep %{ name }", {
                                name: x.node.name
                            }),
                            id: x.node.id,
                            url: {
                                name: "group_overview",
                                params: { id: x.node.id }
                            }
                        };
                    });
                } else {
                    return this.allGroups;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de groepen."
                    ),
                    type: "error"
                });
            }
        },
        student: {
            query: gql`
                query studentGroupTheHeader($id: ID!) {
                    student(id: $id) {
                        id
                        currentGroup {
                            id
                            name
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.objectId
                };
            },
            update(data) {
                if (data.student) {
                    return {
                        ...data.student,
                        currentGroup: {
                            ...data.student.currentGroup,
                            name: this.$gettextInterpolate("Groep %{ name }", {
                                name: data.student.currentGroup.name
                            })
                        }
                    };
                } else {
                    return this.student;
                }
            },
            skip() {
                return this.nodeName !== "Student";
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de leerling."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        internalGroup(value) {
            if (value !== undefined) {
                this.group = value;
            } else if (!this.nodeName) {
                this.group = {};
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>

<style lang="sass">
.masthead
    position: relative
    width: 100%
    padding:
        top: $spacing-small
        bottom: $spacing-small

    background-color: #fff

.masthead-wrapper
    display: flex

.masthead-nav
    margin-left: $spacing-xsmall

    +mq-tablet
        margin-left: $spacing-regular

    +mq-tablet-landscape
        margin-left: $spacing-large

.masthead-nav
    .nav-main__link::before
        bottom: 10px
</style>
