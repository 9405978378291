import Login from "@/views/auth/Login.vue";
import TheHeaderAuth from "@/components/TheHeaderAuth";
import Vue from "vue";

export default [
    // Login
    {
        path: Vue.prototype.$gettext("/inloggen"),
        name: "login",
        components: { default: Login, header: TheHeaderAuth },
        meta: {
            authRequired: false,
            title: Vue.prototype.$gettext("Inloggen"),
            layoutClass: "layout--background"
        }
    },
    // Password reset
    {
        path: Vue.prototype.$gettext("/wachtwoord-vergeten"),
        name: "password_reset",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "password_reset" */ "@/views/auth/PasswordReset.vue"
                ),
            header: TheHeaderAuth
        },
        meta: {
            authRequired: false,
            title: Vue.prototype.$gettext("Wachtwoord vergeten"),
            layoutClass: "layout--background"
        }
    },
    {
        path: Vue.prototype.$gettext("/wachtwoord-vergeten/verzonden"),
        name: "password_reset_done",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "password_reset" */ "@/views/auth/PasswordResetDone.vue"
                ),
            header: TheHeaderAuth
        },
        meta: {
            authRequired: false,
            title: Vue.prototype.$gettext(
                "Instructies verzonden | Wachtwoord vergeten"
            ),
            layoutClass: "layout--background"
        }
    },
    {
        path: Vue.prototype.$gettext("/wachtwoord-vergeten/reset/:uid/:token"),
        name: "password_reset_confirm",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "password_reset" */ "@/views/auth/PasswordResetConfirm.vue"
                ),
            header: TheHeaderAuth
        },
        props: {
            default: route => ({
                uid: route.params.uid,
                token: route.params.token
            })
        },
        meta: {
            authRequired: false,
            title: Vue.prototype.$gettext(
                "Nieuw wachtwoord | Wachtwoord vergeten"
            ),
            layoutClass: "layout--background"
        }
    },
    {
        path: Vue.prototype.$gettext("/wachtwoord-vergeten/reset"),
        name: "password_reset_confirm_redirect",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "password_reset" */ "@/views/auth/PasswordResetConfirm.vue"
                ),
            header: TheHeaderAuth
        },
        meta: {
            authRequired: false,
            title: Vue.prototype.$gettext(
                "Nieuw wachtwoord | Wachtwoord vergeten"
            ),
            layoutClass: "layout--background"
        }
    },
    {
        path: Vue.prototype.$gettext("/wachtwoord-vergeten/voltooid"),
        name: "password_reset_completed",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "password_reset" */ "@/views/auth/PasswordResetCompleted.vue"
                ),
            header: TheHeaderAuth
        },
        meta: {
            authRequired: false,
            title: Vue.prototype.$gettext(
                "Wachtwoord ingesteld | Wachtwoord vergeten"
            ),
            layoutClass: "layout--background"
        }
    }
];
