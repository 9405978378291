import Dashboard from "@/views/Dashboard.vue";
import Router from "vue-router";
import TheHeader from "@/components/TheHeader";
import Vue from "vue";

import auth from "@/routes/auth";
import designsystem from "@/designsystem/routes";
import groups from "@/routes/groups";
import schedules from "@/routes/schedules";
import store from "@/store";
import students from "@/routes/students";
import teachers from "@/routes/teachers";

Vue.use(Router);

const main = [
    {
        path: "/",
        name: "dashboard",
        components: {
            default: Dashboard,
            header: TheHeader
        },
        meta: {
            title: Vue.prototype.$gettext("Dashboard"),
            layoutClass: "layout--background"
        }
    }
];

const notFound = [
    {
        path: "*",
        components: {
            default: () => import("@/views/NotFound.vue"),
            header: TheHeader
        },
        meta: {
            title: Vue.prototype.$gettext("Pagina niet gevonden"),
            layoutClass: "layout--background"
        }
    }
];

const routes = main.concat(
    auth,
    groups,
    schedules,
    students,
    teachers,
    designsystem,
    notFound
);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = (to.meta.title + " | " || "") + "Klassistent";
    }

    let userLoggedIn = store.getters["auth/userLoggedIn"];

    if (!userLoggedIn) {
        if (to.meta.authRequired === false) {
            next();
        } else {
            next({
                name: "login",
                query: { redirect: to.fullPath }
            });
        }
    } else {
        if (
            to.meta.adminRequired === true &&
            store.getters["auth/permissions"] !== "admin"
        ) {
            next({ name: "dashboard" });
        } else {
            next();
        }
    }
});

export default router;
