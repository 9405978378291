<template>
    <AppFormField
        :class="['form-field--textarea', { 'form-field--error': hasMessages }]"
    >
        <textarea
            ref="input"
            :class="[
                'input',
                'input--textarea',
                'form-field__input',
                { 'form-field__input--active': isDirty },
                size ? `input--textarea-${size}` : '',
                inputClass
            ]"
            :disabled="disabled"
            :readonly="readonly"
            :required="required"
            :value="value"
            @input="onInput"
            @blur="onBlur"
            @focus="onFocus"
            @keydown="onKeydown"
        />
        <!-- prettier-ignore -->
        <AppFormFieldLabel v-if="label" data-testid="label">
            {{ label }} <span v-if="required">*</span>
        </AppFormFieldLabel>

        <div
            v-if="counter || submit"
            class="form-field-options"
            @click="$refs.input.focus()"
        >
            <div
                v-if="counter"
                :class="[
                    'form-field-counter',
                    {
                        'form-field-counter--error':
                            internalValue.length > counter
                    }
                ]"
            >
                {{ internalValue.length }} / {{ counter }}
            </div>

            <AppButton
                v-if="submit"
                variant="primary small"
                class="u-margin-left-small"
                :disabled="isDisabled"
                type="submit"
            >
                {{ buttonText }}
            </AppButton>
        </div>

        <div v-if="!hideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormField>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppInputText from "@/components/AppInputText";
import AppMessageList from "@/components/AppMessageList";

export default {
    name: "AppInputTextarea",
    components: {
        AppButton,
        AppMessageList
    },
    extends: AppInputText,
    props: {
        autosize: Boolean,
        counter: {
            type: Number,
            default: undefined
        },
        inputClass: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: undefined,
            validator: value => {
                return [
                    undefined,
                    "large",
                    "small",
                    "tiny",
                    "xxsmall"
                ].includes(value);
            }
        },
        submit: Boolean,
        submitText: {
            type: String,
            default: ""
        }
    },
    computed: {
        buttonText() {
            return this.submitText || this.$gettext("Opslaan");
        }
    },
    watch: {
        value(val) {
            this.lazyValue = val;
            this.$nextTick(() => {
                this.autoResize();
            });
        }
    },
    mounted() {
        this.autoResize();
    },
    methods: {
        onKeydown(e) {
            if (this.isFocused && e.keyCode === 13) {
                e.stopPropagation();
            }
            this.$emit("keydown", e);
        },
        autoResize() {
            if (this.autosize) {
                this.$refs.input.style.minHeight = "auto";
                this.$refs.input.style.minHeight =
                    this.$refs.input.scrollHeight + 4 + "px";
            }
        }
    }
};
</script>

<style lang="sass">
.input--textarea
    height: 160px
    padding: 10px $spacing-small

    font-family: $base-font-family

    resize: none

.input--textarea-large
    height: 220px

.input--textarea-small
    height: 100px

.input--textarea-tiny,
.input--textarea-xxsmall
    height: 48px
</style>
