<template>
    <div>
        <AppButton
            variant="insight"
            :disabled="disabled"
            :title="title"
            :type="type"
            @click="openModal({ groupId: groupId ? groupId : null })"
        >
            <AppIcon name="plus" variant="tiny" />
        </AppButton>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";

import { mapMutations } from "vuex";

export default {
    name: "AppInsightButton",
    components: { AppButton, AppIcon },
    props: {
        disabled: Boolean,
        groupId: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "button",
            validator: value => {
                return ["button", "submit"].includes(value);
            }
        }
    },
    methods: mapMutations("insightmodal", ["openModal"])
};
</script>
