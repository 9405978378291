<template>
    <AppFormField
        :class="[
            { 'form-field--error': hasMessages },
            'datepicker--' + preferredOpenDirection,
            { 'datepicker--disabled': isDisabled }
        ]"
    >
        <Datepicker
            ref="input"
            :class="[
                'form-field__input',
                {
                    'form-field__input--active': isDirty || isFocused
                }
            ]"
            :disabled="isDisabled"
            :disabled-dates="disabledDates"
            format="dd MMMM yyyy"
            full-month-name
            :highlighted="highlightedDates"
            :language="languageNl"
            monday-first
            :required="required"
            :value="value"
            @input="onInput"
        />
        <!-- prettier-ignore -->
        <AppFormFieldLabel v-if="label" data-testid="label">
            {{ label }} <span v-if="required">*</span>
        </AppFormFieldLabel>

        <div class="form__icon form-field__icon">
            <AppIcon name="calendar" />
        </div>

        <div v-if="!hideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormField>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppInputText from "@/components/AppInputText";
import AppMessageList from "@/components/AppMessageList";
import Datepicker from "vuejs-datepicker";

import { nl } from "vuejs-datepicker/dist/locale";

export default {
    name: "AppInputDate",
    components: {
        AppIcon,
        Datepicker,
        AppMessageList
    },
    extends: AppInputText,
    props: {
        disabledDates: {
            type: Object,
            default: undefined
        },
        highlightedDates: {
            type: Object,
            default: undefined
        },
        value: {
            type: [String, Date],
            default: ""
        }
    },
    data() {
        return {
            languageNl: nl,
            preferredOpenDirection: "above"
        };
    },
    updated() {
        this.adjustPosition();
    },
    mounted() {
        this.adjustPosition();
    },
    methods: {
        adjustPosition() {
            const spaceTop = this.$el.getBoundingClientRect().top;
            const hasEnoughSpaceAbove = spaceTop > 300; //size of datepicker popout

            if (hasEnoughSpaceAbove) {
                this.preferredOpenDirection = "above";
            } else {
                this.preferredOpenDirection = "below";
            }
        },
        blur() {
            this.onBlur();
        },
        onBlur() {
            if (!this.$refs.input) return;
            this.$refs.input.close();
        },
        onFocus() {
            if (!this.$refs.input) return;
            this.$refs.input.showCalendar();
        },
        onInput(value) {
            this.internalValue = value;
        }
    }
};
</script>

<style lang="sass">
// These styles are direct copies of the ones used in vue-datepicker
// They are globally scoped and placed in this component for context

/* Datepicker global inputs */
.form-field:not(.form-field--error) .vdp-datepicker
    input[type="text"][readonly]:not(:disabled)
        color: $color-base-font

        border-color: $color-input-border

.vdp-datepicker
    input[type="text"][readonly]:not(:disabled)
        background-color: inherit

.datepicker--disabled .form-field__label::after
    background: linear-gradient(#fff 50%, $color-blue-lighter 50%)

/* Datepicker styling */

.vdp-datepicker__calendar
    top: calc(100% - 2px)
    z-index: $zindex-overlay

    padding: $spacing-small
    width: 100%
    min-width: 300px

    font-family: $secondary-font-family
    border:
        width: 0
        radius: 4px

    box-shadow: 0 0 9px rgba(#000, .16)

.datepicker--above .vdp-datepicker__calendar
    top: auto
    bottom: calc(100% - 4px)

// calendar headers

.vdp-datepicker__calendar header span
    border-radius: 5px

.vdp-datepicker__calendar header .prev:after
    border-right-color: $color-primary

.vdp-datepicker__calendar header .prev.disabled:after
    border-right-color: $color-blue-lighter

.vdp-datepicker__calendar header .next:after
    border-left-color: $color-primary

.vdp-datepicker__calendar header .next.disabled:after
    border-left-color: $color-blue-lighter

.vdp-datepicker__calendar .cell.day-header,
.vdp-datepicker__calendar .day__month_btn,
.vdp-datepicker__calendar .cell.month
    text-transform: capitalize

// day cells

.vdp-datepicker__calendar .cell
    display: inline-flex
    align-items: center
    justify-content: center
    height: 40px
    width: calc(100% / 7)
    padding: 0

    border-radius: $global-border-radius-rounded

    transition: $global-transition

.vdp-datepicker__calendar .month,
.vdp-datepicker__calendar .year
    width: calc(100% / 3)

// today

.vdp-datepicker__calendar .cell.today
    text-decoration: underline

// day headers

.vdp-datepicker__calendar .cell.day-header
    height: auto
    width: calc(100% / 7)
    padding:
        right: 0
        left: 0
    margin-bottom: $spacing-small

    border:
        radius: 0
        width: 0
    border-bottom:
        width: 1px
        style: solid
        color: $color-blue-lighter

    @include font-size(13px)

// hovers

.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover
    background-color: $color-blue-lighter

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover
    border-color: transparent

.vdp-datepicker__calendar .cell:not(.blank):not(.day-header):not(.disabled):hover
    background-color: $color-blue-lighter

// highlighted

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).highlighted
    color: $color-secondary

    background-color: tint($color-secondary, 90)

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).highlighted:hover
    background-color: tint($color-secondary, 80)

// highlighted + disabled

.vdp-datepicker__calendar .cell:not(.blank).highlighted.disabled
    color: $color-disabled

    background-color: tint($color-secondary, 95)

// selected

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).selected
    color: #fff

    background-color: $color-primary

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).selected:hover
    background-color: shade($color-primary, 4)

// disabled

.vdp-datepicker__calendar .disabled,
.vdp-datepicker__calendar .disabled:hover
    color: $color-blue-light
    background-color: transparent

/* states */

.form-field--error
    .vdp-datepicker input[type="text"]
        color: $color-base-font

        border-color: $color-red
</style>
