<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppLayoutInner",
    props: {
        variant: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("layout-inner", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.layout-inner
    flex: 1
    padding: $spacing-medium
    min-height: 100%
    min-width: 0

.layout-inner--size-large
    padding:
        right: 56px
        left: 56px

.layout-inner--overflow
    margin: -$spacing-regular

.layout-inner--overflow-page
    margin-bottom: -$spacing-regular

    .container &
        margin:
            right: -$spacing-regular
            left: -$spacing-regular

.layout-inner--centered
    display: flex
    align-items: center
    justify-content: center

.layout-inner--background
    background-color: $color-blue-lighter
</style>
