<template>
    <ul
        :class="[
            'message-list',
            {
                'message-list--error': type === 'error',
                'message-list--success': type === 'success'
            }
        ]"
    >
        <li
            v-for="(message, index) in messages"
            :key="index"
            class="message-list__item"
        >
            {{ message }}
        </li>
    </ul>
</template>

<script>
export default {
    name: "AppMessageList",
    props: {
        messages: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            default: "error"
        }
    }
};
</script>

<style lang="sass">
.message-list
    list-style: none

.message-list--error
    color: $color-red

.message-list--success
    color: $color-success

.message-list__item:not(:last-child)
    margin-bottom: $spacing-xsmall
</style>
