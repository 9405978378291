var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppFormField',{class:{ 'form-field--error': _vm.hasMessages }},[_c('Multiselect',{ref:"input",class:[
            'form-field__input',
            {
                'form-field__input--active': _vm.isDirty,
                'multiselect--multiple': _vm.multiple,
                'multiselect--clearable': _vm.clearable,
                'multiselect--required': _vm.required,
                'multiselect--with-icon': _vm.icon
            }
        ],attrs:{"allow-empty":_vm.allowEmpty,"block-keys":['Delete'],"close-on-select":!_vm.multiple,"disabled":_vm.isDisabled,"label":_vm.optionsLabel,"loading":_vm.loading,"multiple":_vm.multiple,"options":_vm.options,"placeholder":"","preselect-first":_vm.preselectFirst,"show-labels":false,"track-by":_vm.optionsTrackBy,"value":_vm.value},on:{"close":_vm.onBlur,"input":_vm.onInput,"open":_vm.onFocus},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"clear",fn:function(){return [(_vm.clearable && _vm.value)?_c('AppButton',{staticClass:"form-field__clear",attrs:{"variant":"ghost close"},on:{"click":_vm.onClear}},[_vm._v(" × ")]):_vm._e()]},proxy:true}],null,true)}),(_vm.label)?_c('AppFormFieldLabel',{attrs:{"data-testid":"label"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.icon)?_c('div',{staticClass:"form__icon form-field__icon"},[_c('AppIcon',{attrs:{"name":_vm.icon,"variant":"tiny"}})],1):_vm._e(),(!_vm.hideDetails)?_c('div',{staticClass:"form-field-messages"},[(_vm.hasMessages || _vm.hasHint)?_c('AppMessageList',{attrs:{"messages":_vm.messages,"type":_vm.validationState}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }