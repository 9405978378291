<template>
    <AppFormField :class="[{ 'form-field--error': hasMessages }]">
        <input
            ref="input"
            :autocomplete="autocomplete"
            :class="[
                'input',
                `input--${type}`,
                'form-field__input',
                { 'form-field__input--active': isDirty }
            ]"
            :disabled="disabled"
            :readonly="readonly"
            :required="required"
            :type="internalType"
            :value="value"
            @input="onInput"
            @blur="onBlur"
            @focus="onFocus"
            @keydown="onKeydown"
        />
        <!-- prettier-ignore -->
        <AppFormFieldLabel v-if="label" data-testid="label">
            {{ label }} <span v-if="required">*</span>
        </AppFormFieldLabel>

        <AppButton
            v-if="type === 'password'"
            variant="ghost small"
            class="form__icon form-field__icon form-field__icon--button form-field__icon--clickable"
            data-testid="button"
            :disabled="isDisabled"
            type="button"
            tabindex="-1"
            @click.native="showPassword = !showPassword"
        >
            <AppIcon :name="!showPassword ? 'eye' : 'eye-closed'" />
        </AppButton>

        <div v-if="!hideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormField>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import AppFormField from "@/components/AppFormField.vue";
import AppFormFieldLabel from "@/components/AppFormFieldLabel.vue";
import AppIcon from "@/components/AppIcon";
import AppInput from "@/components/AppInput";
import AppMessageList from "@/components/AppMessageList";

export default {
    name: "AppInputText",
    components: {
        AppButton,
        AppFormField,
        AppFormFieldLabel,
        AppIcon,
        AppMessageList
    },
    extends: AppInput,
    props: {
        autocomplete: {
            type: String,
            default: undefined
        },
        type: {
            type: String,
            default: "text",
            validator: value => {
                return ["text", "number", "email", "password"].includes(value);
            }
        }
    },
    data() {
        return {
            badInput: false,
            showPassword: false
        };
    },
    computed: {
        internalType() {
            return this.showPassword ? "text" : this.type;
        },
        isDirty() {
            return (
                (this.lazyValue != null &&
                    this.lazyValue.toString().length > 0) ||
                this.badInput
            );
        }
    },
    watch: {
        isFocused(val) {
            if (val) {
                this.initialValue = this.lazyValue;
            } else if (this.initialValue !== this.lazyValue) {
                this.$emit("change", this.lazyValue);
            }
        }
    },
    methods: {
        focus() {
            this.onFocus();
        },
        blur() {
            this.$refs.input ? this.$refs.input.blur() : this.onBlur();
        },
        onBlur(event) {
            this.isFocused = false;
            this.$emit("blur", event);
        },
        onFocus(event) {
            if (!this.$refs.input) return;

            if (document.activeElement !== this.$refs.input) {
                return this.$refs.input.focus();
            }

            if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("focus", event);
            }
        },
        onInput(event) {
            this.internalValue = event.target.value;
            this.badInput =
                event.target.validity && event.target.validity.badInput;
        },
        onKeydown(event) {
            if (event.keyCode === 13) this.$emit("change", this.internalValue);
            this.$emit("keydown", event);
        }
    }
};
</script>
