<template>
    <header class="masthead-sub masthead-student">
        <div class="masthead-student-profile">
            <AppAvatar
                class="u-margin-right"
                size="small"
                :image="student.profilePicture.url || undefined"
            />
            <span class="masthead-student-profile__name">{{
                student.fullName
            }}</span>
        </div>
        <AppNavBar
            class="nav-main masthead-sub-nav masthead-student-nav"
            aria-label="student navigation"
        >
            <ul class="nav-bar__list nav-main__list">
                <li
                    class="nav-bar__item nav-main__item masthead-student-nav__item"
                >
                    <!-- Class logic is a workaround for routes with a query string https://github.com/vuejs/vue-router/issues/2040 -->
                    <router-link
                        v-translate
                        :to="{ name: 'student_insight' }"
                        :class="[
                            'nav-bar__link',
                            'nav-main__link',
                            {
                                'nav-main__link--active':
                                    $route.name === 'student_insight'
                            }
                        ]"
                    >
                        Inzicht
                    </router-link>
                </li>
                <li
                    class="nav-bar__item nav-main__item masthead-student-nav__item"
                >
                    <router-link
                        v-translate
                        :to="{ name: 'student_information_base' }"
                        class="nav-bar__link nav-main__link"
                        active-class="nav-main__link--active"
                    >
                        Informatie
                    </router-link>
                </li>
                <li
                    class="nav-bar__item nav-main__item masthead-student-nav__item"
                >
                    <router-link
                        v-translate
                        :to="{ name: 'student_level' }"
                        class="nav-bar__link nav-main__link"
                        active-class="nav-main__link--active"
                    >
                        Onderwijsniveau
                    </router-link>
                </li>
                <li
                    class="nav-bar__item nav-main__item masthead-student-nav__item"
                >
                    <router-link
                        v-translate
                        :to="{ name: 'student_report' }"
                        class="nav-bar__link nav-main__link"
                        active-class="nav-main__link--active"
                    >
                        Rapporten
                    </router-link>
                </li>
            </ul>
        </AppNavBar>
    </header>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppNavBar from "./AppNavBar.vue";
import gql from "graphql-tag";

export default {
    name: "TheHeaderStudent",
    components: { AppNavBar, AppAvatar },
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            student: {
                profilePicture: {}
            }
        };
    },
    apollo: {
        student: {
            query: gql`
                query studentTheHeaderStudent($id: ID!) {
                    student(id: $id) {
                        id
                        profilePicture {
                            url(
                                options: {
                                    geometry: "100x100"
                                    asynchronous: true
                                    crop: "center"
                                }
                            )
                        }
                        fullName
                    }
                }
            `,
            variables() {
                return {
                    id: this.studentId
                };
            },
            update(data) {
                if (data.student) {
                    return { ...data.student };
                } else {
                    return this.student;
                }
            }
        }
    }
};
</script>

<style lang="sass">
.masthead-student
    +mq-lte-mobile
        display: block

.masthead-student-profile
    display: flex
    align-items: center

    @include font-size(18px)
    font-weight: $strong-weight

    +mq-tablet
        padding-right: $spacing-regular

        border-right:
            width: 1px
            style: solid
            color: $color-blue-lighter

.masthead-student-nav
    min-height: 40px

    +mq-tablet
        margin-left: $spacing-regular

// sub
.masthead-sub
    position: relative

    display: flex
    width: 100vw
    padding: $spacing-small $spacing-regular

    box-shadow: 0 2px 2px rgba(#000, .03)
</style>
