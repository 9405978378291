var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppFormField',{class:[
        'form-field--file',
        'form-field--with-icon',
        {
            'form-field--error': _vm.hasMessages,
            'form-field--file-hidden-input':
                _vm.hideInputOnValue && _vm.lazyValue && _vm.lazyValue.url
        }
    ]},[_c('div',{class:[
            'input',
            'form-field__input',
            {
                'input--disabled': _vm.isDisabled,
                'form-field__input--active': _vm.lazyValue && _vm.lazyValue.name
            }
        ],attrs:{"tabindex":"1"}},[_c('input',{ref:"input",staticClass:"input input--file",attrs:{"accept":_vm.accept,"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.required,"type":"file"},on:{"blur":_vm.onBlur,"change":_vm.onInput,"focus":_vm.onFocus}}),(_vm.lazyValue && _vm.lazyValue.name)?_c('span',{staticClass:"label-value t-truncate"},[_vm._v(_vm._s(_vm.lazyValue.name))]):_vm._e()]),(_vm.label)?_c('AppFormFieldLabel',{attrs:{"data-testid":"label"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-field__icon"},[_c('AppIcon',{attrs:{"name":"attachment"}})],1),_c('div',{staticClass:"form-field-attachment"},[(_vm.lazyValue && _vm.lazyValue.filename)?_c('a',{attrs:{"download":"","href":_vm.lazyValue.url,"target":"_blank"}},[_c('AppIcon',{staticClass:"u-margin-right-tiny",attrs:{"name":"download"}}),_c('span',{class:[
                    't-decoration-underline',
                    { 't-decoration-line-through': _vm.lazyValue.clear }
                ]},[_vm._v(_vm._s(_vm.lazyValue.filename))])],1):_vm._e()]),(_vm.clearable && _vm.lazyValue && _vm.lazyValue.url)?_c('AppInputCheckbox',{attrs:{"label":_vm.$gettext('Bestand verwijderen'),"disabled":_vm.isDisabled},model:{value:(_vm.lazyValue.clear),callback:function ($$v) {_vm.$set(_vm.lazyValue, "clear", $$v)},expression:"lazyValue.clear"}}):_vm._e(),(!_vm.hideDetails)?_c('div',{staticClass:"form-field-messages"},[(_vm.hasMessages || _vm.hasHint)?_c('AppMessageList',{attrs:{"messages":_vm.messages,"type":_vm.validationState}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }