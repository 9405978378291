<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppButtonGroup",
    props: {
        variant: {
            type: String,
            default: ""
        },
        gap: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("button-group", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.button-group
    display: inline-flex
    gap: $spacing-small

.button-group--between
    display: flex
    justify-content: space-between
    width: 100%

.button-group--column
    flex-direction: column
    height: 100%

.button-group--gap-none
    gap: 0

.button-group--gap-large
    gap: 32px
</style>
