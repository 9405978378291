<template>
    <AppDropdown
        class="browse"
        variant="nav"
        :is-active="dropdownActive"
        @mouseover.native="dropdownActive = true"
        @mouseleave.native="dropdownActive = false"
        @keydown="onKeyDown"
    >
        <AppDropdownToggle class="browse-toggle">
            <span v-if="current.name" data-testid="browse-group">{{
                current.name
            }}</span>
            <span v-else data-testid="browse-group">
                <span
                    class="d-none-lte-mobile"
                    data-testid="browse-group-large"
                >
                    {{ emptyCurrentName }}
                </span>
                <span class="d-none-tablet" data-testid="browse-group-small">{{
                    emptyCurrentNameMobile
                }}</span>
            </span>
        </AppDropdownToggle>

        <AppDropdownMenu v-if="!loading" class="browse-menu">
            <AppDropdownContent
                variant="nav"
                data-testid="browse-group-content"
            >
                <AppList v-if="options.length > 0" ref="itemList">
                    <AppListItem
                        v-for="option in options"
                        :key="option.key || option.name"
                        data-testid="browse-group-list-item"
                    >
                        <router-link
                            class="nav-dropdown__link"
                            :to="option.url"
                            :tabindex="dropdownActive ? 0 : -1"
                            @click.native="dropdownActive = false"
                        >
                            <span>
                                <strong>{{ option.name }}</strong>
                            </span>
                        </router-link>
                    </AppListItem>
                </AppList>
                <div v-else>
                    <slot name="noOptions">
                        <translate>Er zijn geen opties beschikbaar.</translate>
                    </slot>
                </div>
            </AppDropdownContent>
        </AppDropdownMenu>
    </AppDropdown>
</template>

<script>
import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownContent from "@/components/AppDropdownContent.vue";
import AppDropdownMenu from "@/components/AppDropdownMenu.vue";
import AppDropdownToggle from "@/components/AppDropdownToggle.vue";
import AppList from "@/components/AppList.vue";
import AppListItem from "@/components/AppListItem.vue";
import { nextTick } from "vue";

export default {
    name: "AppBrowse",
    components: {
        AppDropdownToggle,
        AppDropdownContent,
        AppListItem,
        AppList,
        AppDropdownMenu,
        AppDropdown
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        current: {
            type: Object,
            required: true
        },
        emptyCurrentName: {
            type: String,
            required: true
        },
        emptyCurrentNameMobile: {
            type: String,
            required: true
        },
        loading: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            dropdownActive: false
        };
    },
    methods: {
        onKeyDown(event) {
            if (event.code === "Enter") {
                this.dropdownActive = !this.dropdownActive;

                if (this.dropdownActive) {
                    nextTick(() => {
                        const firstLink = this.$refs.itemList.$el.querySelector(
                            "a"
                        );
                        if (firstLink) {
                            firstLink.focus();
                        }
                    });
                }
            }

            if (event.code === "Escape") {
                this.dropdownActive = false;
            }
        }
    }
};
</script>

<style lang="sass">
.browse:hover
    .browse-toggle
        background-color: shade($color-primary, 15%)
        color: #fff

.browse-toggle
    padding:
        top: $spacing-small
        right: $spacing-large
        bottom: $spacing-small
        left: $spacing-small
    min-height: 54px

    @include font-size(16px, 1.5)
    font-weight: $strong-weight
    color: #fff

    border:
        radius: $global-border-radius-rounded
        width: 0
    background-color: $color-primary

    &::after
        right: $spacing-regular

    +mq-tablet
        padding:
            top: $spacing-small
            right: $spacing-large
            bottom: $spacing-small
            left: $spacing-regular

        @include font-size(18px, 1.5)

    +mq-tablet-landscape
        padding-left: $spacing-regular

.browse__divider
    margin:
        right: $spacing-xsmall
        left: $spacing-xsmall

    font-weight: $strong-weight

.browse-menu
    min-width: 240px
</style>
