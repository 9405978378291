<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppFormGroup",
    props: {
        variant: {
            type: String,
            default: ""
        },
        align: {
            type: String,
            default: ""
        },
        spacing: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("form-group", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.form-group
    display: flex
    flex-direction: column

.form-group + .form-group
    margin-top: $spacing-regular

.form--large .form-group + .form-group
    margin-top: 64px

.form-group + .form-group--spacing-small
    margin-top: 16px

.form-group + .form-group--spacing-none
    margin-top: 0

/* Form Group Types */
/*---------------------------------------------------------------------------*/

.form-group--hide-messages
    .form-field-messages
        min-height: 0

        &:empty
            margin:
                top: 0
                bottom: 0

.form-group--inline
    flex-direction: row
    flex-wrap: wrap
    gap: 16px

    .form-field
        min-width: 0

.form-group--inline-tablet-portrait
    +mq-tablet-portrait
        flex-direction: row
        flex-wrap: wrap
        gap: 16px

.form-group--horizontal
    +mq-tablet
        flex-direction: row
        flex-wrap: wrap

        .form-group-wrapper
            display: flex
            gap: $spacing-small
            width: 100%

        .form-field,
        .form-field-holder
            flex: 0 0 auto

    .form-field-messages
        flex: 0 0 100%
        width: 100%

    .form-field-holder
        display: inline-flex

.form-group--bordered
    .form-field
        flex: 1

        &:hover .label
            border-color: $color-input-focus-border

    .label
        padding: $spacing-small

        border:
            width: 1px
            style: solid
            color: $color-input-border

    .input-control
        top: 50%
        left: $spacing-small

        transform: translateY(-50%)

    .form-field--radio,
    .form-field--checkbox
        .label
            display: flex
            align-items: center
            padding:
                top: $spacing-small
                right: $spacing-small
                bottom: $spacing-small
                left: $spacing-large

            border:
                width: 2px
                style: solid
                color: $color-blue-lighter
                radius: $input-border-radius

        &:hover .label
            border-color: $color-blue-light

.form-group--icons
    display: flex
    width: 100px

    .form-group-wrapper
        display: flex
        gap: $spacing-small
        width: 100%

    .form-field--star,
    .form-field--radio-icon
        flex: 0 0 auto

.form-group--align-right
    align-items: flex-end
</style>
