export default {
    namespaced: true,
    state: {
        onLine: window.navigator.onLine
    },
    getters: {
        onLine: state => state.onLine
    },
    mutations: {
        updateOnline(state) {
            state.onLine = window.navigator.onLine;
        }
    }
};
