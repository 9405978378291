import "@/plugins/moment";
import "@/plugins/sentry";
import "@/lang";

import App from "@/App.vue";
import Vue from "vue";

import { apolloProvider } from "@/apollo";
import { initSession } from "@/sessionManager";
import router from "@/router";
import store from "@/store";

Vue.config.productionTip = false;

// icons

const requireIcon = require.context("./assets/icons", false, /.*\.svg$/);

requireIcon.keys().forEach(fileName => {
    const componentConfig = requireIcon(fileName);
    const componentName = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
    Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
    router,
    store,
    apolloProvider,
    mounted() {
        initSession();
    },
    render: h => h(App)
}).$mount("#app");
