import { kebabCase } from "lodash";
import { ref } from "vue";

export const defaultProps = {
    uGap: {
        type: [Number, String],
        default: null
    },
    uGapX: {
        type: [Number, String],
        default: null
    },
    uGapY: {
        type: [Number, String],
        default: null
    },
    uM: {
        type: [Number, String],
        default: null
    },
    uMt: {
        type: [Number, String],
        default: null
    },
    uMr: {
        type: [Number, String],
        default: null
    },
    uMb: {
        type: [Number, String],
        default: null
    },
    uMl: {
        type: [Number, String],
        default: null
    },
    uOffset: {
        type: [Number, String],
        default: null
    },
    uText: {
        type: String,
        default: null
    },
    uTruncate: {
        type: Number,
        default: null
    }
};

const STYLEPROPS = [
    "size",
    "variant",
    "color",
    "gap",
    "layout",
    "align",
    "position",
    "spacing",
    "weight",
    "align",
    "textcolor",
    "offset"
]; // props that generate an added class
const ANTLERPROPS = Object.keys(defaultProps);

export function antlerComponent(baseClass, props = null) {
    const classPrefix = ref(baseClass);

    const antlerClass = (id = null, modifiers = {}) => {
        const classes = [];

        // setupClass
        const setupClass =
            typeof id === "object" || !id
                ? classPrefix.value
                : baseClass.includes("__")
                ? `${classPrefix.value}-${id}`
                : `${classPrefix.value}__${id}`;
        classes.push(setupClass);

        // modifiers
        const modifiersArray = modifiers
            ? typeof modifiers === "string"
                ? [modifiers]
                : Array.isArray(modifiers)
                ? modifiers
                : Object.keys(modifiers).filter(key => modifiers[key])
            : [];
        modifiersArray.forEach(modifier =>
            classes.push(`${setupClass}--${modifier}`)
        );

        // prop-modifiers
        // only for component base (root)
        if ((typeof id === "object" || !id) && props) {
            Object.keys(props).forEach(prop => {
                // dynamic props
                if (
                    STYLEPROPS.includes(prop) &&
                    props[prop] &&
                    props[prop].length
                ) {
                    const propValue = props[prop];
                    const classNames = propValue.split(" ");

                    classNames.forEach(value => {
                        const prefixed = value.split(":");
                        const propPrefix = prop === "variant" ? "" : `${prop}-`;

                        if (prefixed.length > 1) {
                            classes.push(
                                `${prefixed[0]}:${setupClass}--${propPrefix}${prefixed[1]}`
                            );
                        } else {
                            classes.push(
                                `${setupClass}--${propPrefix}${prefixed[0]}`
                            );
                        }
                    });
                }

                // static antler props
                if (
                    ANTLERPROPS.includes(prop) &&
                    props[prop] &&
                    props[prop].length
                ) {
                    const propValue = props[prop];
                    const classNames = propValue.split(" ");

                    classNames.forEach(value => {
                        const prefixed = value.split(":");
                        if (prefixed.length > 1) {
                            classes.push(
                                `${prefixed[0]}:${kebabCase(prop)}-${
                                    prefixed[1]
                                }`
                            );
                        } else {
                            classes.push(`${kebabCase(prop)}-${prefixed[0]}`);
                        }
                    });
                }
            });
        }

        return classes.join(" ");
    };

    return {
        classPrefix,
        antlerClass
    };
}
