<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppContainer",
    setup() {
        const { antlerClass } = antlerComponent("container");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.container
    display: flex
    width: 100%
    padding:
        right: $spacing-regular
        left: $spacing-regular
    margin:
        right: auto
        left: auto

.container--small
    max-width: breakpoint(tablet)
</style>
