<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppRow",
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("row", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.row
    display: flex
    flex-wrap: wrap
    margin:
        right: -$spacing-regular
        left: -$spacing-regular

.row--no-wrap
    flex-wrap: nowrap

.row--full-columns
    > .column
        flex-basis: 100%
        max-width: 100%

.row--gutters-small
    margin:
        right: -$spacing-small
        left: -$spacing-small
    width: calc(100% + #{$spacing-small} * 2)

    .column
        padding:
            right: $spacing-small
            left: $spacing-small

.row--no-gutters
    margin:
        right: 0
        left: 0
    width: 100%

    .column
        padding:
            right: 0
            left: 0

.row--inner-gutters
    margin:
        right: 0
        left: 0

    .column
        &:first-child
            padding-left: 0

        &:last-child
            padding-right: 0


.row--gutters-small
    margin:
        right: -$spacing-small
        left: -$spacing-small
    width: calc(100% + #{$spacing-small} * 2)

    .column
        padding:
            right: $spacing-small
            left: $spacing-small

.row--gutters-tiny
    margin:
        right: -$spacing-xsmall
        left: -$spacing-xsmall
    width: calc(100% + #{$spacing-xsmall} * 2)

    .column
        padding:
            right: $spacing-xsmall
            left: $spacing-xsmall

.row--centered
    align-items: center

.row--bordered
    > .column + .column
        border-left: 1px solid $color-blue-lighter
</style>
