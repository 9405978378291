<template>
    <AppFormField
        :class="[icon ? 'form-field--radio-icon' : 'form-field--radio']"
    >
        <label :class="['label', { 'label--active': label || $slots.label }]">
            <input
                ref="input"
                :checked="isActive"
                class="input input--hidden"
                :disabled="isDisabled"
                :name="
                    radio.name ||
                        (radio._uid ? 'app-input-radio-' + radio._uid : false)
                "
                type="radio"
                :value="value"
                @blur="onBlur"
                @change="onChange"
                @focus="onFocus"
                @keydown="onKeydown"
            />
            <slot name="label">
                <span class="label-value">{{ label }}</span>
            </slot>

            <AppIcon
                v-if="icon"
                :name="icon"
                :class="[
                    'input-control',
                    'input-control--radio-icon',
                    iconClass,
                    isActive ? iconActiveClass : ''
                ]"
            />
            <div v-else class="input-control input-control--radio" />
        </label>
    </AppFormField>
</template>

<script>
import AppFormField from "@/components/AppFormField.vue";
import AppIcon from "@/components/AppIcon";
import RegistrableInject from "@/mixins/RegistrableInject";

export default {
    name: "AppInputRadio",
    components: { AppFormField, AppIcon },
    mixins: [RegistrableInject("radio")],
    props: {
        disabled: Boolean,
        icon: {
            type: String,
            default: undefined
        },
        iconClass: {
            type: String,
            default: undefined
        },
        iconActiveClass: {
            type: String,
            default: undefined
        },
        label: {
            type: String,
            default: ""
        },
        readonly: Boolean,
        value: {
            type: [String, Number, Boolean],
            default: null
        }
    },
    data() {
        return {
            isActive: false,
            isFocused: false
        };
    },
    computed: {
        isDisabled() {
            return this.disabled || !!this.radio.disabled;
        },
        isReadonly() {
            return this.readonly || !!this.radio.readonly;
        }
    },
    methods: {
        focus() {
            this.onFocus();
        },
        blur() {
            this.$refs.input ? this.$refs.input.blur() : this.onBlur();
        },
        onBlur(event) {
            this.isFocused = false;
            this.$emit("blur", event);
        },
        onChange() {
            if (this.isDisabled || this.isReadonly) return;

            if (!this.isDisabled && !this.isActive) {
                this.$emit("change", this.value);
            }
        },
        onFocus(event) {
            if (!this.$refs.input) return;

            if (document.activeElement !== this.$refs.input) {
                return this.$refs.input.focus();
            }

            if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("focus", event);
            }
        },
        onKeydown() {}
    }
};
</script>

<style lang="sass">
.form-field--radio .label
    display: block
    min-height: 28px
    min-width: 28px

.form-field--radio .label--active
    padding:
        top: 4px
        left: 36px

.input-control--radio
    border-radius: 50%

.input-control--radio::after
    height: 12px
    width: 12px

    border-radius: 50%

    transform: translate3D(-50%, -50%, 0) scale(0)
    transition: $global-transition-fast

.input:checked ~ .input-control--radio::after
    background-color: #fff

    transform: translate3D(-50%, -50%, 0) scale(1)

.input:checked:disabled ~ .input-control--radio::after
    background-color: $color-blue-light

// Radio icons
.form-field--radio-icon
    flex: 0 0 auto

.form-field--radio-icon .label
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

    cursor: pointer

    &:hover
        opacity: .8

.input-control--radio-icon
    position: relative
    top: auto
    left: auto

    min-height: 28px
    min-width: 28px

    background-color: transparent
    border: 0
    border-radius: 50%

    cursor: pointer

.input:not(:disabled) ~ .input-control--radio-icon:hover
    opacity: .8

.input:disabled ~ .input-control--radio-icon
    background-color: transparent
    opacity: .6

    cursor: default

.input:checked ~ .input-control--radio-icon
    background-color: transparent

// circular radio buttons with backgrounds

.input-control--radio-circle
    display: flex
    align-items: center
    justify-content: center
    flex: 0 0 auto
    width: 20px
    height: 20px
    min-width: 20px
    min-height: 20px
    padding: 4px

    color: #fff

    border-radius: 50%
    background-color: $color-blue-lighter

    &:hover
        background-color: $color-blue-light

.input-control--radio-circle.icon--minus-small
    padding: 6px

.input:checked ~ .input-control--radio-circle
    background-color: $color-blue-lighter

.input-control--radio-circle-blue,
.input:checked ~ .input-control--radio-circle-blue
    background-color: $color-blue

.input-control--radio-circle-green-dark,
.input:checked ~ .input-control--radio-circle-green-dark
    background-color: $color-green-dark

.input-control--radio-circle-purple,
.input:checked ~ .input-control--radio-circle-purple
    background-color: $color-purple

.input:disabled:not(:checked) ~ .input-control--radio-circle
    background-color: $color-blue-lighter
</style>
