<template>
    <AppDropdown
        variant="nav right"
        :is-active="accountDropdownActive"
        @mouseover="accountDropdownActive = true"
        @mouseleave="accountDropdownActive = false"
    >
        <AppDropdownToggle variant="link">
            <AppIcon variant="tiny" class="u-margin-right-small" name="user" />
            <span class="d-none-lte-tablet-portrait">{{ me.firstName }}</span>
        </AppDropdownToggle>

        <AppDropdownMenu>
            <AppDropdownContent variant="nav">
                <AppList>
                    <AppListItem>
                        <router-link
                            v-translate
                            :to="{
                                name: 'account'
                            }"
                            class="dropdown__link nav-dropdown__link nav-main__link"
                            active-class="nav-dropdown__link--active nav-main__link--active"
                            @click.native="accountDropdownActive = false"
                        >
                            Account
                        </router-link>
                    </AppListItem>
                    <AppListItem>
                        <router-link
                            v-translate
                            :to="{
                                name: 'schedule_weekly_overview'
                            }"
                            class="dropdown__link nav-dropdown__link nav-main__link"
                            active-class="nav-dropdown__link--active nav-main__link--active"
                            @click.native="accountDropdownActive = false"
                        >
                            Weekplanning
                        </router-link>
                    </AppListItem>
                    <AppListItem>
                        <router-link
                            v-translate
                            :to="{
                                name: 'student_overview'
                            }"
                            class="dropdown__link nav-dropdown__link nav-main__link"
                            active-class="nav-dropdown__link--active nav-main__link--active"
                            @click.native="accountDropdownActive = false"
                        >
                            Leerlingen
                        </router-link>
                    </AppListItem>
                    <template v-if="isAdmin">
                        <li class="dropdown__divider nav-dropdown__divider">
                            &nbsp;
                        </li>
                        <AppListItem>
                            <a
                                class="dropdown__link nav-dropdown__link nav-main__link"
                                :href="adminURL"
                                @click="accountDropdownActive = false"
                            >
                                <AppIcon
                                    variant="tiny"
                                    class="u-margin-right-small"
                                    name="lock"
                                />
                                <translate>Administrator</translate>
                            </a>
                        </AppListItem>
                    </template>
                    <li class="dropdown__divider nav-dropdown__divider">
                        &nbsp;
                    </li>
                    <AppListItem>
                        <!-- TODO convert to a button? -->
                        <a
                            href="#"
                            class="dropdown__link nav-dropdown__link nav-main__link"
                            @click="accountDropdownActive = false"
                            @click.prevent="logout"
                        >
                            <AppIcon
                                variant="tiny"
                                class="u-margin-right-small"
                                name="logout"
                            />
                            <translate>Uitloggen</translate>
                        </a>
                    </AppListItem>
                </AppList>
            </AppDropdownContent>
        </AppDropdownMenu>
    </AppDropdown>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownContent from "@/components/AppDropdownContent.vue";
import AppDropdownMenu from "@/components/AppDropdownMenu.vue";
import AppDropdownToggle from "@/components/AppDropdownToggle.vue";
import AppIcon from "@/components/AppIcon";
import AppList from "@/components/AppList.vue";
import AppListItem from "@/components/AppListItem.vue";
import gql from "graphql-tag";

export default {
    name: "AppAccountDropdown",
    components: {
        AppDropdown,
        AppDropdownToggle,
        AppListItem,
        AppDropdownContent,
        AppList,
        AppDropdownMenu,
        AppIcon
    },
    data() {
        return {
            me: {},
            accountDropdownActive: false
        };
    },
    computed: {
        ...mapGetters("auth", ["permissions"]),
        adminURL: () => process.env.VUE_APP_ADMIN_ENDPOINT,
        isAdmin() {
            return this.permissions === "admin";
        }
    },
    apollo: {
        me: {
            query: gql`
                query meAppAccountDropdown {
                    me {
                        id
                        firstName
                    }
                }
            `
        }
    },
    methods: {
        ...mapMutations("auth", ["updateAuthTokens"]),
        logout() {
            this.updateAuthTokens();
            this.$router.push({
                name: "login",
                query: { redirect: this.$route.fullPath }
            });
        }
    }
};
</script>
