<template>
    <component :is="as" :class="antlerClass()">
        <slot />
    </component>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppHeader",
    props: {
        as: {
            type: String,
            default: "header",
            validator: value => {
                return ["header", "div"].includes(value);
            }
        },
        variant: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "",
            validator: value => {
                return ["", "small", "smedium", "xsmall"].includes(value);
            }
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("header", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.header
    margin-bottom: $spacing-regular

.header--size-xsmall
    margin-bottom: 4px

.header-small,
.header--size-small
    margin-bottom: $spacing-small

.header-smedium,
.header--size-smedium
    margin-bottom: 16px

.header--overview
    +mq-tablet-portrait
        margin-left: 36px

.header--centered
    +mq-tablet
        display: flex
        align-items: center

.header-options
    +mq-tablet
        display: flex
        flex-grow: 1
        margin-left: $spacing-large

.header-options__option
    flex: 0 0 auto
    margin-right: $spacing-regular

    &:last-child
        margin-right: 0

    +mq-lte-mobile
        margin:
            right: 0
            bottom: $spacing-small

        &:last-child
            margin-bottom: 0

.header-options__option--select
    +mq-tablet-landscape
        min-width: 240px

/* Special */

.header--levels
    position: fixed
    z-index: 1001

    width: 200px
    height: 190px // same as .overview-list-head--rotated .overview-list__column
    padding-right: $spacing-small
    margin-bottom: 0

    background-color: #fff

    .header-options
        flex-direction: column
        margin-left: 0

    .header-options__option
        margin:
            right: 0
            bottom: $spacing-small

        max-width: 100%

        &:last-child
            margin-bottom: 0

.header--bordered
    padding-bottom: 12px

    border-bottom: 1px solid $color-blue-lighter
</style>
