var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"masthead"},[_c('AppContainer',[_c('AppBrowse',{staticClass:"masthead-browse",attrs:{"current":_vm.group,"empty-current-name":_vm.$gettext('Kies een groep'),"empty-current-name-mobile":_vm.$gettext('Groep'),"loading":_vm.loading,"options":_vm.allGroups}},[_c('template',{slot:"noOptions"},[_c('translate',[_vm._v(" Er zijn geen groepen aan uw profiel toegevoegd. Neem contact op met de beheerder. ")])],1)],2),_c('AppNavBar',{staticClass:"masthead-nav nav-main",attrs:{"aria-label":"main navigation"}},[_c('ul',{staticClass:"nav-bar__list nav-main__list"},[(_vm.group.id)?_c('li',{staticClass:"nav-bar__item nav-main__item d-none-lte-tablet"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],class:[
                            'nav-bar__link nav-main__link',
                            {
                                'nav-main__link--active':
                                    _vm.$route.name === 'group_overview' &&
                                    'router-link-active'
                            }
                        ],attrs:{"to":{
                            name: 'group_overview',
                            params: { id: _vm.group.id }
                        }}},[_vm._v(" Overzicht ")])],1):_vm._e(),(_vm.group.id)?_c('li',{staticClass:"nav-bar__item nav-main__item d-none-lte-tablet"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-bar__link nav-main__link",attrs:{"active-class":"nav-main__link--active","to":{
                            name: 'group_schedule',
                            params: { id: _vm.group.id }
                        }}},[_vm._v(" Planning ")])],1):_vm._e(),(_vm.group.id)?_c('li',{staticClass:"nav-bar__item nav-main__item d-none-lte-tablet"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],class:[
                            'nav-bar__link',
                            'nav-main__link',
                            {
                                'nav-main__link--active':
                                    !!_vm.$route.name &&
                                    _vm.$route.name.startsWith('student_')
                            }
                        ],attrs:{"active-class":"nav-main__link--active","to":{
                            name: 'group_students',
                            params: { id: _vm.group.id }
                        }}},[_vm._v(" Leerlingen ")])],1):_vm._e(),(_vm.group.id)?_c('li',{staticClass:"nav-bar__item nav-main__item d-none-lte-tablet"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-bar__link nav-main__link",attrs:{"active-class":"nav-main__link--active","to":{
                            name: 'group_level_redirect',
                            params: { id: _vm.group.id }
                        }}},[_vm._v(" Niveau ")])],1):_vm._e(),(_vm.group.id)?_c('li',{staticClass:"nav-bar__item nav-main__item d-none-lte-tablet"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-bar__link nav-main__link",attrs:{"active-class":"nav-main__link--active","to":{
                            name: 'group_education_plans',
                            params: { id: _vm.group.id }
                        }}},[_vm._v(" Ontwikkeling ")])],1):_vm._e(),(_vm.group.id)?_c('li',{staticClass:"nav-bar__item nav-main__item d-none-lte-tablet"},[_c('router-link',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-bar__link nav-main__link",attrs:{"active-class":"nav-main__link--active","to":{
                            name: 'group_report',
                            params: { id: _vm.group.id }
                        }}},[_vm._v(" Rapporten ")])],1):_vm._e(),_c('li',{staticClass:"nav-bar__item nav-main__item d-flex--align-right"},[_c('AppInsightButton',{attrs:{"group-id":_vm.group.id}})],1),_c('li',{staticClass:"nav-bar__item nav-main__item"},[_c('AppAccountDropdown')],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }