<template>
    <TheLayout>
        <AppHeader class="u-margin-bottom-large">
            <AppTitle class="header__title">
                <AppIcon class="u-margin-right-small" name="lock" />
                <translate>Inloggen</translate>
            </AppTitle>
        </AppHeader>

        <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
            <AppFormGroup>
                <AppInputText
                    v-model="formValues.email"
                    autocomplete="email"
                    :disabled="loading > 0"
                    :label="$gettext('E-mailadres')"
                    required
                    type="email"
                />
                <AppInputText
                    v-model="formValues.password"
                    autocomplete="current-password"
                    :disabled="loading > 0"
                    :label="$gettext('Wachtwoord')"
                    required
                    type="password"
                />
            </AppFormGroup>
            <AppFormGroup align="right">
                <AppButton
                    v-translate
                    variant="secondary huge full-mobile"
                    :disabled="!onLine"
                    :loading="loading > 0"
                    :title="$gettext('Inloggen')"
                    type="submit"
                >
                    Inloggen
                </AppButton>
            </AppFormGroup>
            <AppFormGroup>
                <AppLink
                    v-translate
                    variant="large underline"
                    :to="{ name: 'password_reset' }"
                >
                    Wachtwoord vergeten?
                </AppLink>
            </AppFormGroup>
        </AppForm>
    </TheLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import AppButton from "@/components/AppButton";
import AppForm from "@/components/AppForm";
import AppFormGroup from "@/components/AppFormGroup";
import AppHeader from "@/components/AppHeader";
import AppIcon from "@/components/AppIcon";
import AppInputText from "@/components/AppInputText";
import AppLink from "@/components/AppLink";
import AppTitle from "@/components/AppTitle";
import FormSave from "@/mixins/FormSave";

import TheLayout from "@/components/TheLayoutAuth";
import gql from "graphql-tag";

export default {
    components: {
        AppButton,
        AppForm,
        AppFormGroup,
        AppHeader,
        AppIcon,
        AppInputText,
        AppLink,
        AppTitle,
        TheLayout
    },
    mixins: [FormSave],
    data() {
        return {
            formValues: {
                email: "",
                password: ""
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["userLoggedIn"]),
        ...mapGetters("online", ["onLine"])
    },
    watch: {
        userLoggedIn(value) {
            if (value) {
                this.redirect();
            }
        }
    },
    mounted() {
        if (this.userLoggedIn) {
            this.redirect();
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        ...mapMutations("auth", ["updateAuthTokens"]),
        getMutation() {
            return {
                mutation: gql`
                    mutation tokenAuth($email: String!, $password: String!) {
                        tokenAuth(email: $email, password: $password) {
                            token
                        }
                    }
                `,
                variables: {
                    email: this.formValues.email,
                    password: this.formValues.password
                },
                fetchPolicy: "no-cache"
            };
        },
        formSuccess(data) {
            this.showMessage();
            this.updateAuthTokens({
                token: data.data.tokenAuth.token,
                payload: JSON.parse(
                    atob(data.data.tokenAuth.token.split(".")[1])
                )
            });
            this.redirect();
        },
        formError() {
            this.showMessage({
                message: this.$gettext(
                    "Inloggen mislukt. Controleer uw inloggegevens en probeer het opnieuw."
                ),
                type: "error"
            });
        },
        redirect() {
            this.$router.push(
                this.$route.query.redirect || {
                    name: "dashboard"
                }
            );
        }
    }
};
</script>
