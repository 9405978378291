import { apolloClient } from "@/apollo";
import { consoleError } from "@/console";
import gql from "graphql-tag";
import store from "@/store";

export { initSession };

function initSession() {
    return new Promise(() => {
        refreshToken(true);
        setInterval(refreshToken, 60 * 1000);
    });
}

function refreshToken(initalRequest) {
    let tokenExpiryDate = store.getters["auth/tokenExpiry"];
    if (!tokenExpiryDate) {
        // Make sure both tokenExpiry and accessToken are empty
        store.commit("auth/updateAuthTokens", null);
        return;
    }

    let timeDiff = tokenExpiryDate - new Date().getTime() / 1000;

    if (timeDiff < 10) {
        store.commit("auth/updateAuthTokens", null);
    } else {
        apolloClient
            .mutate({
                mutation: gql`
                    mutation refreshToken($token: String!) {
                        refreshToken(token: $token) {
                            token
                            payload
                        }
                    }
                `,
                variables: {
                    token: store.getters["auth/accessToken"]
                },
                fetchPolicy: "no-cache"
            })
            .then(data => {
                store.commit(
                    "auth/updateAuthTokens",
                    data.data && data.data.refreshToken
                );
            })
            .catch(err => {
                if (initalRequest) {
                    store.commit("auth/updateAuthTokens", null);
                }
                consoleError(err);
            });
    }
}
