<template>
    <transition name="message">
        <AppMessage
            v-if="isActive"
            :class="[`message--${type}`, 'message--flash']"
            :icon="type === 'success' ? 'check' : 'info'"
        >
            <div class="message-text">
                {{ message }}
            </div>

            <template #options>
                <AppButton
                    variant="ghost close"
                    type="button"
                    @click="isActive = false"
                >
                    <span class="button--icon-text">&times;</span>
                </AppButton>
            </template>
        </AppMessage>
    </transition>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppMessage from "./AppMessage.vue";
import { mapGetters } from "vuex";

export default {
    name: "TheMessage",
    components: { AppMessage, AppButton },
    props: {
        timeout: {
            type: Number,
            default: 6000
        }
    },
    data() {
        return {
            activeTimeout: -1,
            isActive: false
        };
    },
    computed: mapGetters("message", ["message", "show", "type"]),
    watch: {
        show(val) {
            this.isActive = !!val;
        },
        isActive() {
            this.setTimeout();
        }
    },
    mounted() {
        this.setTimeout();
    },
    methods: {
        setTimeout() {
            window.clearTimeout(this.activeTimeout);

            if (this.isActive && this.timeout) {
                this.activeTimeout = window.setTimeout(() => {
                    this.isActive = false;
                }, this.timeout);
            }
        }
    }
};
</script>
