import TheHeader from "@/components/TheHeader";
import Vue from "vue";

export default [
    {
        path: Vue.prototype.$gettext("/account"),
        name: "account",
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "account" */ "@/views/teachers/Account.vue"
                ),
            header: TheHeader
        },
        meta: {
            title: Vue.prototype.$gettext("Account")
        }
    }
];
