import Vue from "vue";

export default [
    {
        path: "/__ds",
        name: "ds_home",
        component: () =>
            import(
                /* webpackChunkName: "ds_home" */ "@/designsystem/views/DsApp.vue"
            ),
        meta: {
            authRequired: true,
            title: Vue.prototype.$gettext("DS | Home")
        },
        children: [
            {
                path: "/",
                name: "ds_home",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_layout" */ "@/designsystem/views/DsHome.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Layout")
                }
            },
            {
                path: "layouts",
                name: "ds_layouts",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_layout" */ "@/designsystem/views/DsLayout.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Layout")
                }
            },
            {
                path: "buttons-links",
                name: "ds_buttons_links",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_buttonslinks" */ "@/designsystem/views/DsButtonsLinks.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Buttons & Links")
                }
            },
            {
                path: "components",
                name: "ds_components",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_components" */ "@/designsystem/views/DsComponents.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Components")
                }
            },
            {
                path: "schedule-class-view",
                name: "ds_schedule_class_view",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_components" */ "@/designsystem/views/DsScheduleClassView.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Components")
                }
            },
            {
                path: "icons",
                name: "ds_icons",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_icons" */ "@/designsystem/views/DsIcons.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Icons")
                }
            },
            {
                path: "inputs",
                name: "ds_inputs",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_inputs" */ "@/designsystem/views/DsInputs.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Inputs")
                }
            },
            {
                path: "typography",
                name: "ds_typography",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_typography" */ "@/designsystem/views/DsTypography.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Typography")
                }
            },
            {
                path: "utilities",
                name: "ds_utilities",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_utilities" */ "@/designsystem/views/DsUtilities.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Utilities")
                }
            },
            {
                path: "planning",
                name: "ds_planning",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_planning" */ "@/designsystem/views/DsPlanning.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Planning")
                }
            },
            {
                path: "planning_lower_grade",
                name: "ds_planning_lower_grade",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_planning_lower_grade" */ "@/designsystem/views/DsPlanningLowerGrade.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Planning Lower Grade")
                }
            },
            {
                path: "schedule_update",
                name: "ds_schedule_update",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_schedule_update" */ "@/designsystem/views/DsScheduleUpdate.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Planning")
                }
            },
            {
                path: "education_plans",
                name: "ds_education_plans",
                component: () =>
                    import(
                        /* webpackChunkName: "ds_education_plans" */ "@/designsystem/views/DsEducationPlans.vue"
                    ),
                meta: {
                    authRequired: true,
                    title: Vue.prototype.$gettext("DS | Education Plans")
                }
            }
        ]
    }
];
