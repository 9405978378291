<template>
    <component
        :is="as"
        :class="
            antlerClass('', {
                'with-icon': icon
            })
        "
        :to="to"
    >
        <AppIcon v-if="icon" :name="icon" :class="iconClass" />
        <slot />
    </component>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppLink",
    components: { AppIcon },
    props: {
        as: {
            type: String,
            default: "router-link",
            validator: value => {
                return ["a", "div", "button", "router-link"].includes(value);
            }
        },
        variant: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: ""
        },
        iconClass: {
            type: String,
            default: ""
        },
        to: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("link", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.link
    display: inline-flex
    align-items: center
    padding: 0 //unset padding for <button> elements

    color: $color-link

    cursor: pointer
    background-color: transparent
    border: none
    transition: $global-transition

    &:hover,
    .has-link:hover &
        color: $color-link-hover

    &:active
        color: $color-link-active

.link--with-icon
    display: inline-flex
    align-items: center
    gap: 4px

/* Link Styles */
/*---------------------------------------------------------------------------*/

.link--underline,
.link--underlined
    text-decoration: underline

.link--block
    display: block

.link--bold
    font-weight: bold

.link--font-primary
    font-family: $primary-font-family

/* Link Colors */
/*---------------------------------------------------------------------------*/

.link--green
    color: $color-green

.link--white
    color: #fff

    &:hover
        color: rgba(#fff, .8)

/* Link Sizes */
/*---------------------------------------------------------------------------*/

.link--large
    @include font-size(18px)

.link--small
    @include font-size(14px)
</style>
