<template>
    <AppFormField :class="{ 'form-field--error': hasMessages }">
        <Multiselect
            ref="input"
            :allow-empty="allowEmpty"
            :block-keys="['Delete']"
            :class="[
                'form-field__input',
                {
                    'form-field__input--active': isDirty,
                    'multiselect--multiple': multiple,
                    'multiselect--clearable': clearable,
                    'multiselect--required': required,
                    'multiselect--with-icon': icon
                }
            ]"
            :close-on-select="!multiple"
            :disabled="isDisabled"
            :label="optionsLabel"
            :loading="loading"
            :multiple="multiple"
            :options="options"
            placeholder=""
            :preselect-first="preselectFirst"
            :show-labels="false"
            :track-by="optionsTrackBy"
            :value="value"
            @close="onBlur"
            @input="onInput"
            @open="onFocus"
        >
            <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                <slot :name="slot" v-bind="scope" />
            </template>
            <template #clear>
                <AppButton
                    v-if="clearable && value"
                    variant="ghost close"
                    class="form-field__clear"
                    @click="onClear"
                >
                    ×
                </AppButton>
            </template>
        </Multiselect>

        <!-- prettier-ignore -->
        <AppFormFieldLabel v-if="label" data-testid="label">
            {{ label }} <span v-if="required">*</span>
        </AppFormFieldLabel>

        <div v-if="icon" class="form__icon form-field__icon">
            <AppIcon :name="icon" variant="tiny" />
        </div>

        <div v-if="!hideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormField>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import AppFormField from "@/components/AppFormField.vue";
import AppFormFieldLabel from "@/components/AppFormFieldLabel.vue";
import AppIcon from "@/components/AppIcon";
import AppInput from "@/components/AppInput";
import AppMessageList from "@/components/AppMessageList";
import Multiselect from "vue-multiselect";
import { clone } from "lodash";

export default {
    name: "AppInputSelect",
    components: {
        AppButton,
        AppFormFieldLabel,
        AppFormField,
        AppIcon,
        AppMessageList,
        Multiselect
    },
    extends: AppInput,
    props: {
        icon: {
            type: String,
            default: ""
        },
        allowEmpty: Boolean,
        clearable: Boolean,
        loading: Boolean,
        multiple: Boolean,
        options: {
            type: Array,
            required: true
        },
        optionsLabel: {
            type: String,
            default: undefined
        },
        optionsTrackBy: {
            type: String,
            default: undefined
        },
        preselectFirst: Boolean,
        value: {
            type: [String, Number, Array, Object],
            default: ""
        }
    },
    methods: {
        focus() {
            this.onFocus();
        },
        blur() {
            this.$refs.input
                ? this.$refs.input.isOpen && this.$refs.input.deactivate()
                : this.onBlur();
        },
        onBlur(event) {
            this.isFocused = false;
            this.$emit("blur", event);
        },
        onFocus(event) {
            if (!this.$refs.input) return;

            if (!this.$refs.input.isOpen) {
                return this.$refs.input.activate();
            }

            if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("focus", event);
            }
        },
        onInput(value) {
            this.internalValue = clone(value);
        },
        onClear() {
            this.internalValue = null;
        }
    }
};
</script>

<style lang="sass">
.multiselect,
.multiselect__input,
.multiselect__single
    touch-action: manipulation

.form-field__clear
    position: absolute
    top: 4px
    right: 28px
    z-index: 10

/* Spinner */

.multiselect__spinner
    position: absolute
    right: 2px
    top: 2px

    display: block
    width: 48px
    height: calc(100% - 4px)

    background-color: #fff

    &::before,
    &::after
        animation: spinning 2.4s $global-transition-animation
        animation-iteration-count: infinite

.multiselect__spinner::before,
.multiselect__spinner::after
    position: absolute
    top: 50%
    left: 50%

    width: 16px
    height: 16px
    margin:
        top: -8px
        left: -8px

    border:
        color: $color-primary transparent transparent
        radius: 100%
        style: solid
        width: 2px
    content: ""

/* General Styles */
/*---------------------------------------------------------------------------*/

.multiselect
    position: relative

    min-height: 44px

    background-color: #fff
    border:
        width: 2px
        color: $color-input-border
        style: solid
        radius: $input-border-radius

    //disable default input styling
    input[type='email'],
    input[type='text'],
    input[type='password'],
    input[type='number'],
    input[type='file']
        height: auto
        padding: 0

        border: none

.multiselect__input,
.multiselect__single
    position: relative

    display: flex
    align-items: center
    width: 100%
    line-height: 1.4

    @include font-size($input-font-size, $base-line-height)

    border: 0

    transition: border 0.1s ease

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single
    width: auto

.multiselect__current
    display: block
    padding:
        top: 8px
        right: 30px
        left: 12px
    overflow: hidden

    line-height: 16px
    text-decoration: none

    border:
        width: 1px
        style: solid
        color: #e8e8e8
        radius: 5px
    cursor: pointer

    white-space: nowrap

.multiselect__select
    position: absolute
    right: 1px
    top: 1px

    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: calc(100% - 2px)

    line-height: 1
    text-align: center

    cursor: pointer

    &::before
        border:
            style: solid
            width: 5px 5px 0 5px
            color: $color-primary transparent transparent transparent
        content: ""

        .form-field--error &
            border-color: $color-error transparent transparent transparent

.multiselect--with-icon
    .multiselect__select
        display: none

/* tags */

.multiselect__tags-wrap
    display: flex
    align-items: center
    flex-wrap: wrap
    width: 100%

    margin-bottom: -6px //because all inner tags have margin-bottom: 10px

.multiselect__tags
    padding:
        top: 10px
        right: 40px
        bottom: 10px
        left: $spacing-small
    min-height: 44px

    @include font-size(14px)

.multiselect--clearable .multiselect__tags
    padding-right: 52px

.multiselect__tag
    position: relative

    display: inline-flex
    align-items: center
    height: 26px
    padding:
        top: 4px
        right: 26px
        bottom: 4px
        left: 10px
    margin:
        right: 10px
        bottom: 4px
    max-width: 100%

    color: #fff
    line-height: 1

    background-color: $color-primary
    border-radius: 5px

    @extend %truncate

.multiselect__tag-icon
    position: absolute
    right: 0
    top: 0
    bottom: 0

    display: flex
    align-items: center
    justify-content: center
    width: 22px
    margin-left: 7px

    text-align: center
    line-height: 22px

    border-radius: 5px

    transition: all 0.2s ease
    cursor: pointer

    &::after
        content: "×"
        @include font-size(14px)
        font-style: normal

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover
    background-color: shade($color-primary, 20%)

/* States */
/*---------------------------------------------------------------------------*/

/* errors */

.form-field--error .multiselect
    border-color: $color-error

.form-field--error .multiselect--active
    border-color: $color-input-focus-border

/* disabled */

.multiselect--disabled
    pointer-events: none
    color: $color-input-disabled

    border-color: $color-border-disabled
    background-color: $color-input-disabled-background

    .multiselect__select::before
        border-color: $color-border-disabled transparent transparent transparent

    .multiselect__tag
        padding-right: 10px

        background-color: $color-input-disabled

    .multiselect__tag-icon
        display: none

    & + .form-field__label
        color: $color-input-disabled

        &::after
            background: $color-input-disabled-label

/* active */

.multiselect--active
    border-color: $color-input-focus-border

    .multiselect__select
        transform: rotateZ(180deg)

    .multiselect__placeholder
        display: none

    .multiselect__input
        width: 100%

    & ~ .form__icon
        color: $color-input-focus-border


/* Item Popup */
/*---------------------------------------------------------------------------*/

.multiselect__content-wrapper
    position: absolute
    z-index: $zindex-overlay

    display: block
    width: calc(100% + 4px) //overflow the parents' border
    max-height: 240px
    margin:
        right: -2px
        left: -2px
    overflow: auto

    background-color: #fff
    box-shadow: 0 0 9px rgba(#000, .16)

    -webkit-overflow-scrolling: touch

.multiselect--above .multiselect__content-wrapper
    bottom: 100%

.multiselect__content
    display: inline-block
    min-width: 100%

    vertical-align: top
    list-style: none

.multiselect__element
    display: block

.multiselect__option
    position: relative

    display: block
    padding: 12px

    vertical-align: middle

    cursor: pointer
    white-space: nowrap

.multiselect__option--highlight
    background-color: $color-blue-lighter

.multiselect__option--selected,
.multiselect__option--selected.multiselect__option--highlight
    color: #fff

    background-color: $color-primary

/* Animation States */

.multiselect__loading-enter-active,
.multiselect__loading-leave-active
    transition: opacity 400ms $global-transition-animation
    opacity: 1

.multiselect__loading-enter,
.multiselect__loading-leave-active
    opacity: 0

.multiselect-enter-active,
.multiselect-leave-active
    transition: all 150ms $global-transition-animation

.multiselect-enter,
.multiselect-leave-active
    opacity: 0

/* Styles */
/*---------------------------------------------------------------------------*/

.multiselect--multiple
    .multiselect__element .multiselect__option
        display: flex
        align-items: center

        &::before
            display: inline-block
            width: 28px
            height: 28px
            margin-right: $spacing-small

            text-align: center
            vertical-align: top

            content: ""
            background-color: #fff
            border:
                width: 2px
                color: $color-input-border
                style: solid
                radius: $global-border-radius

        &:hover::before
            border-color: $color-primary

    .multiselect__element .multiselect__option--highlight
        color: inherit

        background-color: transparent

        &::after
            color: inherit

            background-color: transparent

        &::before
            border-color: $color-primary

    .multiselect__element .multiselect__option--selected
        color: inherit

        background-color: transparent

        &::before
            background-color: $color-primary

            border-color: $color-primary

        &::after
            position: absolute
            top: 26px
            left: 26px

            display: block
            height: 9px
            width: 14px

            border:
                bottom: 2px solid #fff
                left: 2px solid #fff

            content: ""
            transform: translate3D(-50%, -70%, 0) rotate(-45deg)
            transition: $global-transition-fast
</style>
