<template>
    <transition name="loadingbar">
        <div class="loading-bar" />
    </transition>
</template>

<script>
export default {
    name: "TheLoadingBar"
};
</script>

<style lang="sass">
.loading-bar
    position: fixed
    top: 0
    left: 0
    z-index: $zindex-overlay

    height: 4px
    width: 80%

    background-color: $color-blue-lightest
    overflow: hidden

    &::before
        position: absolute
        top: 0
        left: 0

        display: block
        width: 0
        height: 100%
        content: ""

        background-color: $color-primary
        animation: loadingbar 3000ms $global-transition-animation normal
        animation-fill-mode: forwards

.layout--offline .loading-bar
    top: 48px
</style>
