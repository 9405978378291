export default {
    namespaced: true,
    state: {
        loading: 0
    },
    getters: {
        loading: state => state.loading,
        isLoading: state => state.loading > 0
    },
    mutations: {
        loading(state, countModifier) {
            state.loading += countModifier;
        }
    }
};
