import GetTextPlugin from "vue-gettext";
import Vue from "vue";

import translations from "./locale/dist/translations.json";

Vue.use(GetTextPlugin, {
    translations: translations,
    defaultLanguage: "nl",
    availableLanguages: {
        nl: "Dutch"
    },
    muteLanguages: ["nl"]
});
