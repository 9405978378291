<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppContent",
    props: {
        variant: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("content", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.content-group
    .content
        margin-bottom: $spacing-regular

        &:last-child
            margin-bottom: 0

.content
    padding: $spacing-regular
    width: 100%

    background-color: #fff

.content--small
    max-width: breakpoint(tablet)

.content--size-medium
    padding:
        top: $spacing-medium
        bottom: $spacing-medium

.content--size-small
    padding: 16px

.content--shadow
    box-shadow: 1px 1px 2px rgba(#000, .16)

.content--bordered
    border:
        width: 1px
        style: solid
        color: $color-border

.content--bordered-large
    border:
        width: 2px
        color: $color-input-border
        style: solid
        radius: $input-border-radius

.content--rounded
    border-radius: $global-border-radius

.content--background
    background-color: $color-blue-lighter

.content--clean
    padding: 0

    background-color: transparent

.content--inline-form
    border-radius: $input-border-radius

    background-color: $color-blue-lighter

    &:not(:last-of-type)
        margin-bottom: $spacing-small

.content--message
    padding: $spacing-small

    border-radius: $global-border-radius-big
    background-color: $color-blue-lighter
</style>
